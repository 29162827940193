(function(angular) {
  'use strict';

  angular.module('Swivel').controller('SettingController', SwivelController);
  SwivelController.$inject = ['Auth', 'Setting', 'Settings', 'Users', 'Salon', 'Salons','$localStorage'];

  function SwivelController(Auth, Setting, Settings, Users, Salon, Salons, $localStorage) {
    var self = this;
    self.input = {
      link_app_ios: null
    }
    self.inputChangePassword = {
      password: null,
      password_confirmation: null,
      old_password: null
    }
    // declare function
    self.saveAllSettings = saveAllSettings;
    self.saveSettingsBonus = saveSettingsBonus;
    self.saveSettingsRefer = saveSettingsRefer;
    self.changePassword = changePassword;
    self.getCityCurrent = getCityCurrent;
    self.hideAllMessage = hideAllMessage;
    self.getListNeighborHood = getListNeighborHood;
    self.deleteNeightborHood = deleteNeightborHood;
    self.updateNeightborHood = updateNeightborHood;
    self.ChangeFilterStatus = ChangeFilterStatus;
    self.CancelUpdate = CancelUpdate;
    self.enabelEditUpdate = enabelEditUpdate;
    self.editNeighborHood = editNeighborHood;
    self.SaveChange = SaveChange;
    self.turnOnAddNeighborHood = turnOnAddNeighborHood;
    self.getListAddChoose = getListAddChoose;
    self.getLinkApp = getLinkApp;
    self.getListCity = getListCity;
    self.updateTimeOpen = updateTimeOpen;
    self.updateTaxPercent = updateTaxPercent;
    self.showAddCity = showAddCity;
    self.addNewCity = addNewCity;
    self.getStatusCity = getStatusCity;
    self.eventClickButtonOnOff = eventClickButtonOnOff;
    self.getListSalon = getListSalon;
    self.convertToInt = convertToInt;
    self.generatePromoCode = generatePromoCode;
    self.pageSearchChange = pageSearchChange;
    self.searchPromoCode = searchPromoCode;
    self.formatDateTime = formatDateTime;
    self.eventGenerateClick = eventGenerateClick;
    self.eventClickDeletePromoCode = eventClickDeletePromoCode;
    self.getStatusName = getStatusName;
    self.getNumber = getNumber;
    self.getMe = getMe;
    self.getTaxPercent = getTaxPercent;
    self.updateTaxPercent = updateTaxPercent;
    self.checkValidTax = checkValidTax;
    self.getSalonPromoCodeInfo = getSalonPromoCodeInfo;
    self.returnStatusCheckBox = returnStatusCheckBox;
    self.checkEmptyNeighborHoodNameAdd = checkEmptyNeighborHoodNameAdd;
    self.deleteState = deleteState;
    self.enabelEditUpdateState = enabelEditUpdateState;
    self.editState = editState;
    self.saveChangeState = saveChangeState;
    self.resetEditUpdateState = resetEditUpdateState;
    self.saveCreateState = saveCreateState;
    self.cancelUpdateState = cancelUpdateState;
    self.enableCreateNewState = enableCreateNewState;
    self.checkEmptyStateName = checkEmptyStateName;
    // declare function .end

    // declare parameter model
    self.page = 1;
    self.pageSize = 10;
    self.quality = 1;
    self.value = null;
    self.taxCurrent = 0;
    self.modelAddressName = null;
    self.modelTax = 0.00;
    self.messageChangePassword = null;
    self.loadingUpdateCity = false;
    self.setingLoading = false;
    self.changePasswordLoading = false;
    self.listCity = [];
    self.nameModelCity = '';
    self.listNeightborHood = [];
    self.updateCityStatus = updateCityStatus;
    self.regrexFloat = '^[-]?[0-9]+[\.]?[0-9]+$';
    self.indexLoading = -1;
    self.regrexInt = '^[0-9]';
    self.citySeletedId = -1;
    self.selectedId = -1;
    self.isShowGroupButton = false;
    self.currentCitySelected = null;
    self.listNeighborHoodAdd = [];
    self.isShowSubmitSave = false;
    self.indexLoading = 0;
    self.taxCurrent = 0;
    self.isShowEdit = false;
    self.isStatus = isStatus;
    self.isEnableFilter = $localStorage.isEnableFilter;
    self.currentEditNeighborHoodName = null;
    self.currentEditNeighborHoodTax = 0;
    self.idCurrentEdit = null;
    self.isInputUpdateNeighborHoodNull = false;
    self.isAddNewNeighborHood = false;
    self.isShowButtonAddNew = false;
    self.citySeletedIdAdd = null;
    self.isShowAddCity = false;
    self.listSalon = [];
    self.listSalonId = [];
    self.listPromoCode = [];
    self.salonCurrent = null;
    self.salonCurrentId = null;
    self.query = '';
    self.currentMeProfile = null;
    self.limitNumberQuality = 100;
    self.page = $localStorage.currentPage > 1 ? $localStorage.currentPage : 1;
    self.totalObject = 0;
    self.paramPromoCode = {
      code: null,
      value: null
    };
    self.indexShowEdit = null;
    self.emptyOrInvalidTax = false;
    self.inValidPromoCode = false;
    self.inValidValue = false;
    self.MessageNameNotNull = 'NeighborHood is not empty!';
    self.StateMessageNameNotNull = 'State is not empty!';
    self.MessageUpdateSuccesfull = "Update was successfully";
    self.PlaceHolderInputNeighborHood = "Neighborhood name...";
    self.MessageEmpty = "Input is required!";
    self.ErrorExistOrMisstack = "Existed neighborhood name or has problem when saving";
    self.StateErrorExistOrMisstack = "Existed state name or has problem when saving";
    self.MessageCreateDistrictSuccesfull = "Create new neighborhood successfully!";
    self.StateMessageCreateSuccesfull = "Create new state successfully!";
    self.MessageEmptyOrInvalidTax = "Tax percent is empty or invalid!";
    self.MessageValuePromoCodeInvalid = "Promo code value is invalid!";
    self.MessgePromoCodeMustBeFullNumberLimit = "The promo code has to be minimum 5, and no more than 20 characters!";
    self.hourOperation = [];
    self.neighborhoodResetCancelEdit = null;
    self.open = {
      timeEarly: '0',
      timeLate: '0'
    };
    self.bonus_code_value = 0;
    self.promo_code_value_of_user = 0;
    self.loadingPage = false;
    // declare parameter model .end

    self.isShowEditState = false;
    self.idCurrentEditState = null;
    self.currentEditState = null;
    self.stateResetCancelEdit = null;
    self.currentEditStateName = '';
    self.indexShowEditState = null;
    self.isAddNewState = false;
    self.isInputUpdateStateNull = false;
    self.modelStateName = '';
    self.isEmptyStateName = false;
    self.hadErrorState = false;
    self.MessageErrorState = '';

    getAllSettings();

    function updateCityStatus(item, $index) {
      self.loadingUpdateCity = true;
      self.indexLoading = $index;
      var status = item.status == '2' ? '1': '2';
      var data = {
        status: status
      };
      Settings.updateStatusCity(item.id, data).then(function(response) {
        self.listCity[$index].status = status;
        self.loadingUpdateCity = false;
      }, function(error) {
           self.loadingUpdateCity = false;
      });
    }

    function checkEmptyNeighborHoodNameAdd(newName, oldName) {
      if(isEmpty(newName)) {
        self.isEmptyInputAddress = true;
      } else {
        self.isEmptyInputAddress = false;
      }
    }
    function  checkValidTax(newValue, oldValue) {
      var isemty = isEmpty(newValue);
      var isNumber = checkNumber(newValue);
      if(!isemty && isNumber) {
        self.emptyOrInvalidTax = false;
        return true;
      } else {
        self.emptyOrInvalidTax = true;
        return false;
      }
    }
    function formatDateAllBrowser(date) {
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = moment(new Date(parseDate));
      } else {
        var newDate = moment(date);
      }
      return new Date(newDate.year()+'/'+(newDate.month()+1)+'/'+newDate.date()+' '+newDate.hour()+':'+newDate.minute()+':'+newDate.second())
    }
    function formatDateTime(date) {
      var dateFormat = formatDateAllBrowser(date);
      var workDate = dateFormat.getFullYear()+'-'+(dateFormat.getMonth()+1)+'-'+dateFormat.getDate();
      var hourType = 'AM';
      var hour = dateFormat.getHours() < 10 ? '0'+String(dateFormat.getHours()) : (dateFormat.getHours() < 13 ?  String(dateFormat.getHours()) : '0' + parseInt(dateFormat.getHours()-12));
      var min = dateFormat.getMinutes() < 10 ? '0'+String(dateFormat.getMinutes()) : dateFormat.getMinutes();
      if (dateFormat.getHours() > 12) {
        hourType = 'PM';
      }
      var workTime = hour+':'+min+' '+hourType;
      return workTime;
    }
   function getNumber(min, max, step) {
      step = step || 1;
      var input = [];
      for (var i = min; i <= max; i += step) {
          input.push(i);
      }
      return input;
    };
    function convertToInt(num) {
      var number = parseInt(num);
      return number;
    }
    //ge list city add choose .end

    //check null string
    function checkNullString(text) {
      if(text === '' || text === undefined) {
        return true;
      } else {
        return false;
      }
    }
    //check null string .end
   function searchPromoCode(reload, keyword) {
      if(reload) {
        self.page = 1;
      }
      if(self.query && !/^\s*$/.test(self.query)) {
        self.loadingPage = true;
        Salons.searchPromoCode(self.salonCurrentId, keyword).then(function(response) {
          if (!response.error && response.data) {
            self.listPromoCode = [];
            self.totalObject = response.meta.totalObjects;
            angular.forEach(response.data, function(value){
              self.listPromoCode.push(value);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
      } else {
        getListPromoCode(self.salonCurrentId);
      }
    }

    //change select filter
    function ChangeFilterStatus() {
      self.loadingPage = true;
      Settings.changeStatusFilter(self.isEnableFilter).then(function(response) {
        getFilterStatus();
        self.loadingPage = false;
      });
      self.loadingPage = false;
    }
    //change select filter .end
    //delete neighborhood
    function deleteNeightborHood(item,index) {
      if (self.listNeightborHood[index] !== null) {
        if (confirm('Are you sure delete this item?')) {
          self.loadingPage  = true;
          Settings.deleteDistrict(item.attributes.id).then(function(response) {
            if (!response.error) {
              getListNeighborHood();
              self.loadingPage = false;
            }
          }, function(error) {
            alert("Cannot delete neighborhood!");
            self.loadingPage = false;
          });
        }
      }
    }
    //delete neighborhood .end

    //enable edit
    function enabelEditUpdate(neighborHood, $index) {
      self.isShowEdit = true;
      self.idCurrentEdit = $index;
      self.currentEditNeighborHood = neighborHood;
      self.neighborhoodResetCancelEdit = angular.copy(neighborHood);
      self.currentEditNeighborHoodName = neighborHood.attributes.name;
      self.currentEditNeighborHoodTax = neighborHood.attributes.tax;
      self.isShowGroupButton = true;
      self.indexShowEdit = $index;
      self.isAddNewNeighborHood = false;
    }
    //enable edit .end

    // Enable edit state
    function enabelEditUpdateState(state, $index) {
      self.resetEditUpdateState();
      self.isShowEditState = true;
      self.idCurrentEditState = $index;
      self.currentEditState = state;
      self.stateResetCancelEdit = angular.copy(state);
      self.currentEditStateName = state.name;
      self.indexShowEditState = $index;
      self.isAddNewState = false;
    }

    function enableCreateNewState() {
      self.resetEditUpdateState();
      self.isShowEditState = true;
      self.isAddNewState = true;
      self.isEmptyStateName = false;
    }

    function checkEmptyStateName(name) {
      if(isEmpty(name)) {
        self.isEmptyStateName = true;
      } else {
        self.isEmptyStateName = false;
      }
    }

    function resetEditUpdateState() {
      self.isShowEditState = false;
      self.idCurrentEditState = 0;
      self.currentEditState = null;
      self.stateResetCancelEdit = null;
      self.currentEditStateName = '';
      self.indexShowEditState = null;
      self.isAddNewState = false;
      self.modelStateName = '';
      self.hadErrorState = false;
      self.MessageErrorState = '';
    }

    function saveChangeState() {
      if(!isEmpty(self.currentEditState.name)) {
        // self.loadingPage = true;
        var params = {
          name: self.currentEditState.name
        }
        Settings.updateStatusCity(self.currentEditState.id, params).then(function(response) {
          if (!response.error && response.data) {
            resetEditUpdateState();
            alert(self.MessageUpdateSuccesfull);
          }
          // self.loadingPage = false;
        }, function(error) {
          var errors = error.errors;
          var errorMessage = (errors[0] && errors[0].detail) ? errors[0].detail : self.StateErrorExistOrMisstack;
          self.hadErrorState = true;
          self.MessageErrorState = errorMessage;
        });
      } else {
          alert(self.StateMessageNameNotNull);
      }
    }

    function cancelUpdateState() {
      resetEditUpdateState();
      getListCity();
    }

    function saveCreateState() {
      if(!isEmpty(self.modelStateName)) {
        self.loadingPage = true;
        var params = {
          name: self.modelStateName,
          status: 1
        }
        Settings.addCity(params).then(function(response) {
          if (!response.error && response.data) {
            resetEditUpdateState();
            getListCity();
            alert(self.StateMessageCreateSuccesfull);
          }
          self.loadingPage = false;
        }, function(error) {
          var errors = error.errors;
          var errorMessage = (errors[0] && errors[0].detail) ? errors[0].detail : self.StateErrorExistOrMisstack;
          self.hadErrorState = true;
          self.MessageErrorState = errorMessage;
          self.loadingPage = false;
        });
      } else {
          alert(self.StateMessageNameNotNull);
      }
    }

    // Delete state
    function deleteState(state, $index) {
      if (self.listCity[$index] !== null) {
        if (confirm('Are you sure delete this state?')) {
          // self.loadingPage  = true;
          Settings.deleteState(state.id).then(function(response) {
            if (!response.error) {
              self.listCity.splice($index, 1);
              resetEditUpdateState();
              // self.loadingPage = false;
            }
          }, function(error) {
            alert("Cannot delete this state!");
            // self.loadingPage = false;
          });
        }
      }
    }

    // edit neighborhood
    function editState(state, $index) {
      if(checkNullString(state.name)) {
        self.isInputUpdateStateNull = true;
      } else {
        self.isInputUpdateStateNull = false;
      }
    }

    //edit neighborhood
    function updateNeightborHood(itemNeighborHood) {

    }
    //edit neighborhood .end
    function getSalonPromoCodeInfo() {
      getListPromoCode(self.salonCurrentId);
    }
    //check status
    function isStatus(status, statusCheck) {
      return statusCheck == (status & statusCheck);
    }

    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function getStatusName(id) {
      var idStatus = null;
      if(id === 1) {
        idStatus = "Used";
      } else if(id == 2) {
        idStatus = "Unused";
      }
      return idStatus;
    }
    function eventClickDeletePromoCode(item) {
      if (confirm('Are you sure delete this item?')) {
        Salons.deletePromoCode(item.attributes.id).then(function(response) {
          if (!response.error) {
            alert("Delete promo code is succesfull!");
            getListPromoCode(self.salonCurrentId);
          }
        },function(error) {
          alert("Cannot delete promo code item!");
        });
      }
    }
    function getListPromoCode(id) {
      self.loadingPage = true;
      var params = {
        'page[number]': self.page,
        'page[size]': self.pageSize,
      };
      var count = 0;
      Salons.getPromocode(id, params).then(function(response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          self.listPromoCode = [];
          angular.forEach(response.data, function(value){
            self.listPromoCode.push(value);
          });
        }
        self.loadingPage = false;
      },function(error) {
        self.loadingPage = false;
      });
    }
    function eventGenerateClick() {
      generatePromoCode(function() {
        getListPromoCode(self.salonCurrentId);
      });
    }
    function checkValidNumberCharactor(stringChar, num) {
      if(stringChar.length === parseInt(num)) {
        return true;
      } else {
        return false;
      }
    }
    // generate promo code
    function generatePromoCode(cb) {
      if(!isEmpty(self.paramPromoCode.code) && !isEmpty(self.paramPromoCode.value) && checkNumber(self.paramPromoCode.value)) {
        self.loadingPage = true;
        self.inValidPromoCode = false;
        self.inValidValue = false;
        var parames = {
          'code': self.paramPromoCode.code,
          'value': self.paramPromoCode.value
        };
        Settings.createPromocode(parames).then(function(response) {
          if (!response.error && response) {
            self.paramPromoCode.code = null;
            self.paramPromoCode.value = null;
            self.inValidPromoCode = false;
            self.inValidValue = false;
            alert("Create promo code is succesfull!");
            if(cb !== null && typeof cb === 'function') {
              cb();
            }
          }
        }, function(error) {
          alert(error.errors[0].detail);
          self.loadingPage = false;
        });
      } else if(isEmpty(self.paramPromoCode.code)) {
        self.inValidPromoCode = true;
        self.inValidValue = false;
        alert(self.MessageEmpty);
      } else if(isEmpty(self.paramPromoCode.value)) {
        self.inValidValue = true;
        self.inValidPromoCode = false;
        alert(self.MessageEmpty);
      } else if(!checkNumber(self.paramPromoCode.value)) {
        self.inValidValue = true;
        self.inValidPromoCode = false;
        alert(self.MessageValuePromoCodeInvalid);
      }
    }
    // generate promo code .end
    //check me
    function getMe(callback) {
      Users.getUserProfile().then(function(response) {
        if (!response.error && response.data) {
          self.currentMeProfile = new User(response.data);
          self.listSalonId = self.currentMeProfile.getListSalonId();
          if(typeof callback == 'function') {
            callback();
          }
        }
      });
    }
    // check me .end
    function getIdFirstListDefault(list, cb) {
      self.salonCurrentId = list.getId();
      if(typeof cb == 'function') {
        cb();
      }
    }

    // init setting list promo code
    function initSettingListPromoCode(cb) {
      self.paramPromoCode.salon_id = self.salonCurrentId;
      if(typeof cb == 'function') {
        cb(self.salonCurrentId);
      }
    }
    // init setting list promo code .end

    // get list salon
    function getListSalon(cb) {
      self.listSalon = [];
      var params = {
        'page[number]': 1,
        'page[size]': 150
      };
      //for(var i = 0; i < self.listSalonId.length; i++) {
        Salons.getList(params).then(function(response) {
          if (!response.error && response.data) {
            Salons.drainList(response, params, []).then(function(allSalons) {
              var count =0;
              angular.forEach(allSalons, function(value){
                count++;
                var salonItem = new Salon(value, response.included);
                self.listSalon.push(salonItem);
                if(count == allSalons.length) {
                  if(typeof cb == 'function') {
                    cb(self.listSalon[0]);
                  }
                }
              });
            }, function(error) {
              count++;
              alert("System has error when getting list salon!");
            })
          }
        }, function(error) {
          count++;
          alert("System has error when getting list salon!");
        });
      //}
      // if(i == self.listSalonId.lenght) {
      //   //self.salonCurrent = self.listSalon[0];
      //     getSalonDefault()
      //   //self.salonCurrentId = self.salonCurrent.getId();
      // }
    }
    // get list salon .end
    //check status .end
    function getFilterStatus() {
      Settings.getFilterStatus().then(function(response) {
        switch(response.data.attributes.neighborhoods_filter) {
          case true : case '1' : case 1:
            self.isEnableFilter = true;
            $localStorage.isEnableFilter = true;
            break;
          case false : case '0' : case 0 : case null:
            self.isEnableFilter = false;
            $localStorage.isEnableFilter = false;
            break;
        }
      });
    }
    function pageSearchChange() {
      if(self.query && !/^\s*$/.test(self.query)) {
        searchPromoCode();
      } else {
        getListPromoCode(self.salonCurrentId);
      }
    }

    function returnStatusCheckBox(id) {
      var status = true;
      switch(id) {
        case '1': status = false;
          break;
        case '2': status = true;
          break;
      }
      return status;
    }
    function eventClickButtonOnOff(item) {
      self.loadingPage = true;
      var statusId = null;
      if(parseInt(item.status) === 1) {
        statusId = '2';
      } else {
        statusId = '1';
      }
        var param = {
          'id': item.id,
          'name': item.name,
          'status': statusId
        };
      Settings.updateCityStatus(param).then(function(response) {
        if (!response.error && response.data) {
          alert("Create new city is succesfull!");
          getListCity();
          return true;
        }
        self.loadingPage = false;
      }, function(error) {
        alert("Cannot update status of city!");
        self.loadingPage = false;
        return false;
      });
    }
    function getStatusCity(id) {
      var statusName = '';
      switch(id) {
        case '1': statusName = 'Disable';
          break;
        case '2': statusName = 'Enable';
          break;
      }
      return statusName;
    }
    function getTaxPercent() {
      Settings.getTaxPercent().then(function(response) {
        if (!response.error && response.data) {
          self.taxCurrent = response.data.attributes.tax;
        }
      }, function(error) {
      });
    }
    function checkNumber(number) {
      number = String(number);
      if(number.match(/^\d+$/)){
        return true;
      } else if(number.match(/^\d+\.\d+$/)){
        return true;
      } else {
        return false;
      }
    }
    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function updateTaxPercent() {
      var value = parseFloat(self.taxCurrent);
      if(checkNumber(self.taxCurrent) && !isEmpty(self.taxCurrent)) {
        self.loadingPage = true;
        var param = {
          'tax': self.taxCurrent
        };
        Settings.updateTaxPercent(param).then(function(response) {
          if (!response.error && response.data) {
            alert("Update tax succesfull!");
          }
          self.loadingPage = false;
        }, function(error) {
          alert("Cannot update tax!");
          self.loadingPage = false;
        });
      } else {
        alert('Tax value is invalid!');
      }
    }

    //add new city
    function addNewCity(name) {
      if(isEmpty(name)) {
        alert('City name is not empty!')
      } else {
        var param = {
          "name": name,
          "status": 2
        };
        self.loadingPage = true;
        Settings.addCity(param).then(function(response) {
          if (!response.error && response.data) {
            alert("Create new city is succesfull!");
            self.nameModelCity = '';
            getListCity();
          }
          self.loadingPage = false;
        }, function(error) {
          alert("Cannot create new city!");
          self.loadingPage = false;
        });
      }
    }
    //add new city .end
    //get city current
    function getCityCurrent(item) {
      if(item != null || item != undefined) {
        //self.currentCitySelected = item.;
      }
    }
    //get city current .end
    //get list city add choose
    function getListAddChoose() {
      self.listNeighborHoodAdd = [];
      self.isAddNewNeighborHood = false;
      self.isShowEdit = false;
      getListNeighborHood();
      Settings.getListCity().then(function(response) {
        angular.forEach(response.data, function(value, key) {
          if(value.id !== self.citySeletedId) {
            self.listNeighborHoodAdd.push(value.attributes);
          }
        });
      });
    }
    //ge list city add choose .end
    //turn on add neighborhood
    function turnOnAddNeighborHood() {
      if(self.citySeletedId > -1) {
        if(self.isShowEdit) {
          self.currentEditNeighborHood.attributes.name = self.neighborhoodResetCancelEdit.attributes.name;
          self.currentEditNeighborHood.attributes.tax = self.neighborhoodResetCancelEdit.attributes.tax;
        } else {
          self.modelAddressName = null;
          self.modelTax = 0.00;
        }
        self.isInputUpdateNeighborHoodNull = false;
        self.isAddNewNeighborHood = true;
        self.isShowGroupButton = true;
        self.isShowEdit = false;
        self.emptyOrInvalidTax = false;
        // getListAddChoose();
      }
    }
    //turn on add neighborhood .end
    //save edit neighborhood
    function SaveChange() {
      if(self.isShowEdit) {
        if(!isEmpty(self.currentEditNeighborHood.attributes.name) && checkValidTax(self.currentEditNeighborHood.attributes.tax)) {
          self.loadingPage = true;
          var params = {
            name: self.currentEditNeighborHood.attributes.name,
            tax: self.currentEditNeighborHood.attributes.tax
          }
          Settings.updateDistrict(self.currentEditNeighborHood.attributes.id, params).then(function(response) {
            if (!response.error && response.data) {
              self.isShowEdit = false;
              self.isAddNewNeighborHood = false;
              self.isEmptyInputAddress = false;
              getListCity();
              getListNeighborHood();
              alert(self.MessageUpdateSuccesfull);
            }
            self.loadingPage = false;
          }, function(error) {
            alert(self.ErrorExistOrMisstack);
            self.loadingPage = false;
          });
      } else if(isEmpty(self.currentEditNeighborHood.attributes.name)) {
          alert(self.MessageEmpty);
      } else if(!checkValidTax(self.currentEditNeighborHood.attributes.tax)) {
        alert(self.MessageEmptyOrInvalidTax);
      }
    } else if(self.isAddNewNeighborHood) {
      if(!isEmpty(self.modelAddressName) && checkValidTax(self.modelTax)) {
        self.loadingPage = true;
        var params = {
          city_id: self.citySeletedId,
          name: self.modelAddressName,
          tax: self.modelTax
        }
        Settings.createDistrict(self.citySelectedId, params).then(function(response) {
          if (!response.error && response.data) {
            self.isShowEdit = false;
            self.isAddNewNeighborHood = false;
            self.modelAddressName = null;
            self.modelTax = 0.00;
            getListNeighborHood();
            alert(self.MessageCreateDistrictSuccesfull);
          }
          self.loadingPage = false;
        }, function(error) {
          alert(self.ErrorExistOrMisstack);
          //self.isAddNewNeighborHood = false;
          self.loadingPage = false;
        });
      } else if(isEmpty(self.modelAddressName)) {
        alert(self.MessageEmpty);
      }  else if(!checkValidTax(self.modelTax)) {
        alert(self.MessageEmptyOrInvalidTax);
      }
    }
  }
    //save edit neighborhood .end

    //check null string
    function checkNullString(text) {
      if(text === '' || text === undefined) {
        return true;
      } else {
        return false;
      }
    }
    //check null string .end

    // edit neighborhood
    function editNeighborHood(neighborHood, $index) {
      if(checkNullString(neighborHood.attributes.name)) {
        self.isInputUpdateNeighborHoodNull = true;
      } else {
        self.isInputUpdateNeighborHoodNull = false;
      }
    }
    // edit neighborhood .end

    //cancel update
    function CancelUpdate() {
      if(self.idCurrentEdit != null) {
        if(self.isShowEdit) {
          self.currentEditNeighborHood.attributes.name = self.neighborhoodResetCancelEdit.attributes.name;
          self.currentEditNeighborHood.attributes.tax = self.neighborhoodResetCancelEdit.attributes.tax;
        } else {
          self.modelAddressName = null;
          self.modelTax = 0.00;
        }
      }
      self.isShowEdit = false;
      self.isAddNewNeighborHood = false;
      self.isShowGroupButton = false;
      self.emptyOrInvalidTax = false;
      self.isEmptyInputAddress = false;
    }
    //cancel update .end

    //change select filter
    function ChangeFilterStatus() {
      self.loadingPage = true;
      Settings.changeStatusFilter(self.isEnableFilter).then(function(response) {
        getFilterStatus();
        self.loadingPage = false;
      });
      self.loadingPage = false;
    }
    //change select filter .end

    // turn on add city form
    function showAddCity() {
      self.isShowAddCity = true;
    }
    // turn on add city form .end
    //edit neighborhood
    function updateNeightborHood(itemNeighborHood) {

    }
    //edit neighborhood .end

    //check status
    function isStatus(status, statusCheck) {
      return statusCheck == (status & statusCheck);
    }
    //check status .end
    function getAllSettings() {
      // createHourOperation();
      // getLinkApp();
      // getTaxPercent();
      self.loadingPage = true;
      getListSalon(function(firstSalon) {
        getIdFirstListDefault(firstSalon, function(){
          initSettingListPromoCode(function(salonCurrentId){
            getListPromoCode(self.salonCurrentId);
            getListCity();
            getFilterStatus();
          });
        });
      });
    }

    // getlink_app_ios
    function getLinkApp() {
       Settings.get().then(function(response) {
        if (!response.error && response.data) {
          var settings = new Setting(response.data);
          self.input.link_app_ios = settings.getLinkApp();
          self.open.timeEarly = settings.getTimeEarly();
          self.open.timeLate = settings.getTimeLate();
        }
      });
    }
    // get link_app_ios .end

    //get list city
    function getListCity() {
      var params = {
        'page[number]': 1,
        'page[size]': 200
      }
      Settings.getListCity(params).then(function(response) {
        self.listCity = [];
        angular.forEach(response.data, function(value, key) {
          self.listCity.push(value.attributes);
        });
      });
    }
    //get list city .end

    function getFilterStatus() {
      Settings.getFilterStatus().then(function(response) {
        switch(response.data.attributes.neighborhoods_filter) {
          case true : case '1' : case 1:
            self.isEnableFilter = true;
            $localStorage.isEnableFilter = true;
            break;
          case false : case '0' : case 0 : case null:
            self.isEnableFilter = false;
            $localStorage.isEnableFilter = false;
            break;
        }
        if(response.data.attributes.bonus_code_value){
          self.bonus_code_value = parseInt(response.data.attributes.bonus_code_value);
        }else{
          self.bonus_code_value = 0
        }
        if(response.data.attributes.promo_code_value_of_user){
          self.promo_code_value_of_user = parseInt(response.data.attributes.promo_code_value_of_user);
        }else{
          self.promo_code_value_of_user = 0
        }
      });
    }
    function getListNeighborHood() {
      self.isShowButtonAddNew = false;
      self.isShowEdit = false;
      self.isAddNewNeighborHood = false;
      if(parseInt(self.selectedId) > -1) {
        self.currentCitySelected = self.listCity[self.selectedId];
        self.citySeletedId = self.currentCitySelected.id;
      }
      // Settings.getListNeighborHood(self.citySeletedId).then(function(response) {
      //   if (!response.error && response.data) {
      //     self.listNeightborHood = response.data;
      //     angular.forEach(self.listNeightborHood, function(value, key) {
      //       angular.forEach(response.included, function(item){
      //         if (value.relationships.neighborhood.id == item.id) {
      //           value.relationships.neighborhood.name = item.attributes.name;
      //         }
      //       });
      //     });
      //   }
      // });
    var paramRequest = {
      'page[number]': 1,
      'page[size]': 200
    };
      Settings.getDistrict(self.citySeletedId, paramRequest).then(function(response) {
        if (!response.error && response.data) {
          self.listNeightborHood = response.data;
        }
      });
    }
    function saveAllSettings() {
      self.setingLoading = true;
      Settings.save(self.input).then(function(response) {
        if (!response.error) {

        }
        self.setingLoading = false;
      }, function(error) {
        self.setingLoading = false;
      });
    }

    function changePassword(changePasswordForm) {
      if (changePasswordForm.$valid) {
        self.changePasswordLoading = true;
        Users.changePassword(self.inputChangePassword).then(function(response) {
          alert("Change password successfully!");
          changePasswordForm.$setPristine();
          resetForm();
          self.changePasswordLoading = false;
        }, function(error) {
          self.changePasswordLoading = false;
          self.messageChangePassword = "The old password is incorrect."
        });
      } else {
        self.messageChangePassword = "Please fill the form!";
      }
    }

    function hideAllMessage() {
      self.messageChangePassword = "";
    }

    function resetForm() {
      self.inputChangePassword = {
        password: null,
        password_confirmation: null,
        old_password: null
      }
    }

    function createHourOperation() {
      var min = 0;
      var hours = 0;
      var hourType = 'AM';
      for (var i=0; i<=47; i++) {
        if (min == 30) {
          var timeValue = 0;
          var timeFormat = 0;
          if (hours > 12) {
            timeFormat = hours - 12;
          } else {
            timeFormat = hours;
          }
          if (hours >= 12) {
            hourType = 'PM';
          }
          if (hours == 0) {
            timeValue = 0.5;
          } else {
            timeValue = parseFloat(String(hours) + '.5')
          }
          self.hourOperation.push({
            weekday: null,
            begin_time: timeValue,
            end_time: timeValue,
            status: 1,
            label: timeFormat +':'+min+' '+hourType
          })
        }
        if (min == 60) {
          hours++;
          min = 0;
          var timeFormat = 0;
          if (hours > 12) {
            timeFormat = hours - 12;
          } else {
            timeFormat = hours;
          }
          if (hours >= 12) {
            hourType = 'PM';
          }
          self.hourOperation.push({
            weekday: null,
            begin_time: hours.toFixed(1),
            end_time: hours.toFixed(1),
            status: 1,
            label: timeFormat +':'+min+'0 '+hourType
          })
        }
        min=min+30;
      }
    }

    function updateTimeOpen(data) {
      Settings.updateTimeOpen(data).then(function(response) {
        if (!response.error && response.data) {
          alert('Default time opening has been updated');
        }
      });
    }
    function saveSettingsBonus() {
      var params = {
        "bonus_code_value": self.bonus_code_value
      }
      self.saveBonusLoading = true;
      Settings.save(params).then(function(response) {
        self.saveBonusLoading = false;
      }, function(error) {
        self.saveBonusLoading = false;
      });
    }
    function saveSettingsRefer() {
      var params = {
        "promo_code_value_of_user": self.promo_code_value_of_user
      }
      self.saveReferLoading = true;
      Settings.save(params).then(function(response) {
        self.saveReferLoading = false;
      }, function(error) {
        self.saveReferLoading = false;
      });
    }
  }


})(angular);
