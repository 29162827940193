(function(angular) {
  'use strict';

  angular.module('Swivel').factory('Auth', Auth);

  Auth.$inject = ['$localStorage'];
  function Auth($localStorage) {
    var Auth = function() {
      var self = this;
      self.isAuthenticated = isAuthenticated;
      self.setUser = setUser;
      self.getUser = getUser;
      self.revokeUser = revokeUser;
      self.setAccessToken = setAccessToken;
      self.contentType = contentType;
      self.getAccessToken = getAccessToken;
      self.getHeaders = getHeaders;
       self.getHeadersCustom = getHeadersCustom;
      self.setTokenObject = setTokenObject;
      self.getTokenObject = getTokenObject;
      self.revokeUserTokenExpire = revokeUserTokenExpire;
      self.isAdmin = isAdmin;
      self.isOwner = isOwner;
      self.checkRole = checkRole;
      self.setRole = setRole;
      self.getSalonId = getSalonId;
      self.currentRole = null;
      self.listRoleApi = {
        admin: 'super_admin',
        owner: 'salon_owner'        
      };
      self.listRole = {
        admin: 'super_admin',
        owner: 'salon_owner'
      };
      function setUser(userInfo) {
        $localStorage.userInfo = userInfo;
      }
      function contentType() {
        return {
          'Content-Type': 'application/json'
        }      
      }
      function getHeaders() {
        return {
          'Authorization': 'Bearer '+self.getAccessToken(),
          'Content-Type': 'application/json'
        }
      }
      function getHeadersCustom() {
        return {
          'Content-Type': undefined,
          'Accept': '*',
          'Authorization': 'Bearer '+self.getAccessToken(),
        }
      }
      function hasCode() {
        // var hasCode =  ($stateParams.code != null && $stateParams.code != '') ? true: false;
        // return hasCode;
      }
      function setAccessToken(token) {
        $localStorage.token = token;
      }

      function getAccessToken() {
        return $localStorage.token;
      }

      function getUser() {
        return $localStorage.userInfo;
      }

      function revokeUser() {
        delete $localStorage.userInfo;
        delete $localStorage.token;
        delete $localStorage.tokenObject;
        delete $localStorage.currentRole;
        delete $localStorage.isEnableFilter;
      }

      function isAuthenticated() {
        return angular.isDefined($localStorage.userInfo) && $localStorage.userInfo !== null;
      }

      function getTokenObject() {
        return $localStorage.tokenObject;
      }

      function setTokenObject(object) {
        var currentDate = new Date();
        object.startLogin = currentDate;
        object.timeExpires = new Date(currentDate.getTime()+object.expiresIn*60000);
        $localStorage.tokenObject = object;
      }

      function revokeUserTokenExpire() {
        var currentDate = new Date();
        if (angular.isDefined(self.getTokenObject()) && angular.isDefined(self.getTokenObject().timeExpires)) {
          if (currentDate >= new Date(self.getTokenObject().timeExpires)) {
            revokeUser();
            return true;
          }
        }
        return false;
      }
      function checkRole() {
        return $localStorage.currentRole;
      }
      function setRole(nameRole) {
        if(isAuthenticated && nameRole !== null) {
          switch(nameRole) {
            case self.listRoleApi.admin:
              setNameRole(self.listRole.admin);
              break;
            case self.listRoleApi.owner:
              setNameRole(self.listRole.owner);
              break;
            default:
              setNameRole(self.listRole.owner);
              break;
          }
        }
      }
      function setNameRole(name) {
        $localStorage.currentRole = name;
      }
      function getSalonId() {
        if(isOwner()) {
          console.log($localStorage.userInfo);
        }
      }
      function isAdmin() {
        if($localStorage.currentRole === self.listRole.admin) 
          return true;
        else 
          return false;
      }
      function isOwner() {
        if($localStorage.currentRole === self.listRole.owner) 
          return true;
        else 
          return false;
      }
    }
    return new (Auth);
  }

})(angular);
