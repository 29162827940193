angular.module('filters', [])
  .filter("textarealength", function () {
    return function (txt) {
      txt = txt.trim();
      var newLines = txt.match(/(\r\n|\n|\r)/g);
      var addition = 0;
      if (newLines != null) {
        addition = newLines.length;
      }
      return txt.length+addition;
    }
  })
  .filter("centiToFeet", function ($filter) {
    return function (centi) {
      var feet = centi*0.033;
      feet = $filter('number')(feet, 2)
      return parseInt(feet).toString()+"'"+feet.toString().split(".")[1];
    }
  })
  .filter("cmToFt", function () {
    return function (value) {
      if(angular.isDefined(value) && value){
        var str = '';
        str = Number(Math.round(0.032808399*value+'e'+1)+'e-'+1)+'';
        return str.replace(".", "'");
      }
    }
  })
  .filter('truncate', function () {
    return function (input, length, end) {
      if (isNaN(length)) return input;
      if (length <= 0) return '';
      if (typeof input == "undefined") {
        input = '';
      };
      input = String(input).replace(/<[^>]+>/gm, '');
      if (length > 1000) return input;
      if (input && input.length > length) {
        input = input.substring(0, length);
          var lastspace = input.lastIndexOf(' ');
          //get last space
          if (lastspace !== -1) {
            input = input.substr(0, lastspace);
          }
        if (end === undefined){end = "...";}
        return input + end;
      }

      return input;
    };
  })
  .filter('splitAmount',function(){
    return function(input,first){
      var output = String(input).split(".");
      if(first)
        return output[0];
      else{
        if(output.length>1)
          return ","+((output[1].length==1) ? output[1]+'0' : output[1]);
        else
          return "";
      }
    };
  })
  .filter('characters', function () {
    return function (input, chars, breakOnWord) {
      if (isNaN(chars)) return input;
      if (chars <= 0) return '';
      if (input && input.length > chars) {
        input = String(input).replace(/<[^>]+>/gm, '');
        input = input.substring(0, chars);

        if (!breakOnWord) {
          var lastspace = input.lastIndexOf(' ');
          //get last space
          if (lastspace !== -1) {
            input = input.substr(0, lastspace);
          }
        }else{
          while(input.charAt(input.length-1) === ' '){
            input = input.substr(0, input.length -1);
          }
        }
        return input + '…';
      }
      return input;
    };
  })
  .filter('splitcharacters', function() {
    return function (input, chars) {
      if (isNaN(chars)) return input;
      if (chars <= 0) return '';
      if (input && input.length > chars) {
        input = String(input).replace(/<[^>]+>/gm, '');
        var prefix = input.substring(0, chars/2);
        var postfix = input.substring(input.length-chars/2, input.length);
        return prefix + '…' + postfix;
      }
      return input;
    };
  })
  .filter('words', function () {
    return function (input, words) {
      if (isNaN(words)) return input;
      if (words <= 0) return '';
      if (input) {
        input = String(input).replace(/<[^>]+>/gm, '');
        var inputWords = input.split(/\s+/);
        if (inputWords.length > words) {
          input = inputWords.slice(0, words).join(' ') + '…';
        }
      }
      return input;
    };
  })
  .filter('reverse', function() {
    return function(items) {
      if (!angular.isDefined(items)) {
        items = [];
      };
      return items.slice().reverse();
    };
  })
  .filter("format_plural",function($sce,$filter){
    return function(data,text,textmany,only_text) {
      if (typeof data === "undefined" || !text || !textmany || typeof data === "object") {
        data = 0;
      };

      var text_return = (data == 1) ? text : textmany;

      if (only_text) {
        text_return = $filter('translate')(text_return);
        return $sce.trustAsHtml(text_return);
      }else{
        text_return = $filter('translate')(text_return);
        return $sce.trustAsHtml(data + " " + text_return);
      }
    }
  })
  .filter('nlToArray', function() {
    return function(text) {
      if (text == "") {
        return false;
      }else{
        text = String(text);
        return text.split('\n');
      };
    };
  })
  .filter('unique', function () {

    return function (items, filterOn) {

      if (filterOn === false) {
        return items;
      }

      if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
        var hashCheck = {}, newItems = [];

        var extractValueToCompare = function (item) {
        if (angular.isObject(item) && angular.isString(filterOn)) {
          return item[filterOn];
        } else {
          return item;
        }
        };

        angular.forEach(items, function (item) {
        var valueToCheck, isDuplicate = false;

        for (var i = 0; i < newItems.length; i++) {
          if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
          isDuplicate = true;
          break;
          }
        }
        if (!isDuplicate) {
          newItems.push(item);
        }

        });
        items = newItems;
      }
      return items;
    };
  })
  .filter('getUrl', function() {
    var LINKY_URL_REGEXP = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
    return function(text) {
      if (!text) return text;
      var match = text.match(LINKY_URL_REGEXP);
      if(match){
        return match[0];
      }else{
        return "";
      }
    };
  })
  .filter('imageMess', function() {
    return function(text) {
      if (!text) return text;
      var split = text.split(":::");
      if(split.length > 1){
        return "<img class='message-image' src='"+HOST_IMG+"/uploads/messages/"+split[1]+"' />"
      }else{
        return text;
      }
    };
  })
  .filter('escapeHtml', function() {
    return function(string) {
      if (!angular.isDefined(string)) return "";
      return String(string).replace(/&Prime;/g, '"').replace(/&prime;/g, "'").replace(/</g,"&lt;").replace(/>/g,"&gt;");
    };
  })
  .filter('hostname', function () {
    return function ( input ) {
      var output = "",
        matches;

      var urls = /\w+:\/\/([\w|\.]+)/;
      matches = urls.exec( input );

      if ( matches !== null ) output = matches[1];

      return output;
    };
  })
  .filter('groupBy', function () {
    var results={};
    return function (data, key) {
      if (!(data && key)) return;
      var result;
      if(!this.$id){
        result={};
      }else{
        var scopeId = this.$id;
        if(!results[scopeId]){
          results[scopeId]={};
          this.$on("$destroy", function() {
            delete results[scopeId];
          });
        }
        result = results[scopeId];
      }

      for(var groupKey in result)
        result[groupKey].splice(0,result[groupKey].length);

      for (var i=0; i<data.length; i++) {
        if (!result[data[i][key]])
          result[data[i][key]]=[];
        result[data[i][key]].push(data[i]);
      }

      var keys = Object.keys(result);
      for(var k=0; k<keys.length; k++){
        if(result[keys[k]].length===0)
        delete result[keys[k]];
      }
      return result;
    };
  })
  .filter("strip_tags", function() {
    return function(input, allowed){
      input = input || "";
      // input = input.replace(/&nbsp;/g," ");
      allowed = (((allowed || '') + '')
      .toLowerCase()
      .match(/<[a-z][a-z0-9]*>/g) || [])
      .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
      var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
      return input.replace(commentsAndPhpTags, '')
      .replace(tags, function($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
    }
  })
  .filter('wordslength', function () {
    return function (text) {
      if (text) {
        var s = text ? text.trim().split(/\s+/) : 0; // it splits the text on space/tab/enter
        return s ? s.length : '';
      }else{
        return 0
      }
    };
  })
  .filter('isNaN', function() {
    return function(input) {
      return isNaN(input);
    }
  })
  .filter('num', function() {
    return function(input) {
      return parseInt(input, 10);
    };
});;