(function(angular) {
  'use strict';

  angular.module('Swivel').controller('PaymentController', PaymentController);
  PaymentController.$inject = ['$scope','SalonLocation', 'SalonOwner', 'SalonOwners', 'User', 'Users', 'Salon', 'Salons','$localStorage', '$state', '$stateParams','Booking','Bookings', 'SalonStylist', 'SalonStylists', 'SalonService', 'SalonServices', 'Setting', 'Settings', '$timeout', '$filter'];

  function PaymentController($scope,SalonLocation, SalonOwner, SalonOwners, User, Users, Salon, Salons, $localStorage, $state, $stateParams, Booking, Bookings, SalonStylist, SalonStylists, SalonService, SalonServices, Setting, Settings, $timeout, $filter) {
    var self = this;

    self.getBookingDetail = getBookingDetail;
    self.checkNumber = checkNumber;
    self.getListService = getListService;
    self.eventClickPayment  = eventClickPayment;

    self.bookingCurrentId = null;
    self.bookingDetail = null;
    self.isFirstLoad = true;
    self.loadingPage = true;
    self.listStylist = [];
    self.listService = [];
    self.listServerInSalon = [];
    self.listServiceHadSave = [];
    self.listServiceIdModel = [];
    self.totalPrice = 0;
    self.promoCode = null;
    self.listServiceSelect = [];
    self.currenStyleList = null;
    self.promocodeValue = 0;
    self.promcodePercent = 0;
    self.promocodePriceCurrent = 0;
    self.listServiceAdd = [];

    self.listEmpty = [];
    self.taxPerCent = 4.50;
    self.tipPerCent = 10;
    self.regrexFloat = '^[-]?[0-9]+[\.]?[0-9]+$';
    self.regrexInt = '^[0-9]';
    self.valueTax = 0;
    self.valueTip = 0;
    self.owner = {
      username : '',
      salon_id : -1
    };
    self.bookingParamModel = null;
    self.isShowAddService = false;
    self.page = 1;
    self.pageSize = 150;
    self.totalPrice = 0;
    self.creditCardNumber = '';
    self.ErrorMessageCreateOwner = "Owner has existed or system has probleme when saving!";
    self.CreateOwnerSucces = "Update salon owner successful!";
    self.goBack = goBack;

    init();

    function init() {
      getBookingDetail(function() {
        getListService(self.bookingDetail.getServices());
        self.totalListService = caculateListService(self.listService);
        self.promocodeValue =  calculatePromoCode(self.totalListService, self.bookingParamModel.promoCodePercent);
        self.taxPerCent = isEmpty(self.bookingDetail.getTax()) ? 0 : parseFloat(self.bookingDetail.getTax()).toFixed(2);
        self.valueTax = calcuFee(self.listService, self.taxPerCent, self.promocodeValue);
        self.tipPerCent = isEmpty(self.bookingDetail.getTip()) ? 0 : parseFloat(self.bookingDetail.getTip()).toFixed(2);
        self.valueTip = calcuFeeForTip(self.listService, self.tipPerCent);

      });
    }
    function calculatePromoCode(totalServicePrice, promocodeValueGet) {
      var priceFromPercent = 0;
      if (isEmpty(promocodeValueGet)) {
        priceFromPercent = parseFloat(0).toFixed(2);
      } else {
        var dollarFromPercent = parseFloat(promocodeValueGet  * totalServicePrice / 100).toFixed(2);
        priceFromPercent = dollarFromPercent;
      }

      return priceFromPercent;
    }
    function caculateListService(listService) {
      var totalPrice = 0;
      if(listService.length > 0) {
        for(var i = 0; i< listService.length; i++) {
          var value = parseFloat(listService[i].price);
          if(value) {
            totalPrice+= parseFloat(value);
          }
        }

      }
      return totalPrice.toFixed(2);
    }
        //get list service
    function  getListService(listService) {
    self.listService = [];
    var count = 0;
    angular.forEach(listService, function(value, key) {
      self.listService.push(value);
      count++;
      if(count === listService.length) {

        var tipValueGet = self.bookingDetail.getTip();
        if(isEmpty(tipValueGet)) {
          self.tipPerCent = 10;
        } else {
          var listMoneyService = caculateAmount(self.listService, 0, 0);
          var tipValueGet = self.bookingDetail.getTip();
          var taxValueGet = self.bookingDetail.getTax();
          var statusCheckout = self.bookingDetail.getStatusName(self.bookingDetail.getStatus());
          if(statusCheckout == 'Done') {
            var listMoneyService = caculateAmount(self.listService, 0, 0);
            self.valueTax = taxValueGet;
            self.valueTip = tipValueGet;
            self.tipPerCent = ((parseFloat(taxValueGet)/parseFloat(listMoneyService))*100).toFixed(2);
            self.tipPerCent = ((parseFloat(tipValueGet)/parseFloat(listMoneyService))*100).toFixed(2);
          } else if(statusCheckout == 'In Progress') {
            self.tipPerCent = parseFloat(10).toFixed(2);
            self.valueTip = calcuFeeForTip(self.listService, self.tipPerCent);
            Salons.getListById(self.bookingDetail.getSalonId()).then(function(response1) {
              if (!response1.error && response1.data) {
                var paramRequest = {
                  'page[number]': 1,
                  'page[size]': 200
                }
                SalonLocation.getCities(paramRequest).then(function(response2) {
                if (!response2.error && response2.data) {
                  angular.forEach(response2.data, function(value2, key) {
                    if(value2.attributes.id == response1.data.attributes.city_id) {
                      var taxPerCent = isEmpty(value2.attributes.tax) ? 0 : value2.attributes.tax;
                      self.taxPerCent = parseFloat(taxPerCent).toFixed(2);
                      self.valueTax = calcuFee(self.listService, self.taxPerCent);
                    }
                  });
                }
              });
              }
            });
          }
        }
      }
    });
    self.loadingPage = false;
    }
    //get list service .end
    function eventClickPayment(idUser) {
      self.loadingPage = true;
      checkExistCardNumber(idUser, function() {
        paymentBooking();
      });
    }
    function paymentBooking() {
      Bookings.paymentBooking(self.bookingDetail.getId(), null, 'POST').then(function(response) {
        self.loadingPage = true;
        if (!response.error && response.data) {
          alert('Payment booking successfull!');
          self.loadingPage = false;
        }
      }, function(error) {
        alert('Cannot payment booking!');
        self.loadingPage = false;
      });
    }
    function checkNumber(number) {
      number = String(number);
      if(number.match(/^\d+$/)){
        return true;
      } else if(number.match(/^\d+\.\d+$/)){
        return true;
      } else {
        return false;
      }
    }
    // check exist card number
    function checkExistCardNumber(idUser, callBack) {
      Users.getUserCreditCard(idUser).then(function(response) {
        if (!response.error && response.data) {
          if(typeof callBack === 'function') {
            callBack();          }
        } else {

        }
      }, function(error) {

      });
    }
    // check exist card number .end
    function caculateAmount(listService, tax, tip) {
      if(isEmpty(tax)) {
        tax = 0;
      }
      if(isEmpty(tip)) {
        tip = 0;
      }
      var totalPrice = 0;
      for(var i = 0; i< listService.length; i++) {
        var value = parseFloat(listService[i].price);
        if(value) {
          totalPrice+= parseFloat(value);
        }
      }
      if(checkNumber(tax)) {
        totalPrice += parseFloat(tax);
      }
      if(checkNumber(tip)) {
        totalPrice += parseFloat(tip);
      }
      totalPrice -= parseFloat(self.bookingParamModel.promoCodePrice);
      return totalPrice.toFixed(2);
    }
    // get booking detail
    function getBookingDetail(callBack) {
      self.bookingCurrentId = $stateParams.bookingID;
      self.loadingPage = true;
      Bookings.getBookingsDetail(self.bookingCurrentId).then(function(response) {
        if (!response.error && response.data) {
          self.bookingDetail = new Booking(response.data, response.included);
          Users.getUserCreditCard(self.bookingDetail.getUserId()).then(function(response) {
            if (!response.error && response.data && response.data.attributes) {
              self.creditCardNumber = response.data.attributes.last4;
            }
          }, function(error) {

          });
          self.bookingParamModel = {
            'salon_id': self.bookingDetail.getSalonId(),
            'notes': self.bookingDetail.getNotes(),
            'texture': self.bookingDetail.getTexture(),
            'length': self.bookingDetail.getLenght(),
            'serviceIds': self.listServiceIdModel,
            'promocode': self.bookingDetail.getPromoCode(),
            'promoCodePercent': isEmpty(getDefaultValue(self.bookingDetail.getPromocodeValue())) ? 0 : parseFloat(getDefaultValue(self.bookingDetail.getPromocodeValue())).toFixed(2),
            'stylist_id': self.bookingDetail.getStylistId(),
            'work_at': self.bookingDetail.getDateWorkAt(),
            'amount': getDefaultValue(self.bookingDetail.getTotoPrice())
          };
          if(typeof callBack == "function") {
            return callBack();
          }
          self.loadingPage = false;
        }
        self.loadingPage = false;
      });
    }
  // get booking detail .end
    function calcuFee(listService, feePerCent, promocodeValue) {
      if(!checkNumber(feePerCent) || isEmpty(feePerCent)) return 0;
      else {
        var totalServiceMoney = 0;
        promocodeValue = isEmpty(promocodeValue) ? 0: parseFloat(promocodeValue);
        for(var i = 0; i < listService.length; i++) {
          if(checkNumber(listService[i].price)) {
            var value = parseFloat(listService[i].price);
            totalServiceMoney+= value;
          }
        }
        totalServiceMoney-= promocodeValue;
        var feePer = parseFloat(feePerCent);
        var feeMoney = ((totalServiceMoney * feePer)/100).toFixed(2);
        return feeMoney;
      }
    }

    // Calcu fee for Tip = totalservice * %tip
    function calcuFeeForTip(listService, feePerCent) {
      if(!checkNumber(feePerCent) || isEmpty(feePerCent)) return 0;
      else {
        var totalServiceMoney = 0;

        for(var i = 0; i < listService.length; i++) {
          if(checkNumber(listService[i].price)) {
            var value = parseFloat(listService[i].price);
            totalServiceMoney+= value;
          }
        }

        var feePer = parseFloat(feePerCent);
        var feeMoney = ((totalServiceMoney * feePer)/100).toFixed(2);
        return feeMoney;
      }
    }

    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function getDefaultValue(number) {
      if(isEmpty(number)) {
        return parseFloat(0).toFixed(2);
      } else {
        return parseFloat(number).toFixed(2);
      }
    }
    function checkNumber(number) {
      number = parseFloat(number);
      var reg = new RegExp(self.regrexFloat);
      var regInt = new RegExp(self.regrexInt);
      if(reg.test(number) || regInt.test(number)) {
        return true;
      } else {
        return false;
      }
    }
    function goBack() {
      $state.goReturn() ||
      $state.go('dashboard.past-booking');
    }
    // update booking .end
  }


})(angular);
