(function(angular) {
  'use strict';

  angular.module('Swivel').service('SalonOwners', SalonOwners);

  SalonOwners.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];
  function SalonOwners($q, $http, serverUrl, Auth, Users) {
    var SalonOwners = function() {
      var self = this;
      self.create = create;
      self.getList = getList;
      self.updateSalonOwner = updateSalonOwner;
      self.deleteSalonOwner = deleteSalonOwner;
      self.updateSalonOwnerStatus = updateSalonOwnerStatus;
      self.getSalonOwnerInfo = getSalonOwnerInfo;
      self.getSalonOwner = getSalonOwner;
      self.checkOwnerExist = checkOwnerExist;
      self.search = search;

      // check owner exist
      function checkOwnerExist(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users?email=' + params ,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;       
      }
      // check owner exist .end
      function create(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteSalonOwner(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners/'+salonID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateSalonOwner(salonOwnerID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners/'+salonOwnerID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateSalonOwnerStatus(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners/'+salonID+'/status',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getList(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getSalonOwner(id) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners/' + id,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getSalonOwnerInfo(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners/'+salonID,
          method: 'GET'
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function search(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonOwners',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (SalonOwners);
  }


})(angular);
