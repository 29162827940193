(function(angular) {
  'use strict';

  angular.module('Swivel').controller('ListBookingController', ListBookingController);

  ListBookingController.$inject = ['Booking', 'Salons', 'Bookings', '$timeout', '$localStorage', '$state', '$stateParams'];
  function ListBookingController(Booking, Salons, Bookings, $timeout, $localStorage, $state, $stateParams) {
    var self = this;
    self.listBookings = [];
    self.loadingPage = false;
    self.deleteBooking = deleteBooking;
    self.page = $localStorage.currentPage > 1 ? $localStorage.currentPage : 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.searchString = '';
    self.pageSearchChange = pageSearchChange;
    self.formatDateTime = formatDateTime;
    self.formatDateFormat = formatDateFormat;
    self.getSalonBooking = getSalonBooking;
    self.searchBooking = searchBooking;
    self.goToCheckout = goToCheckout;
    self.goToReceipt = goToReceipt;

    init();

    function init() {
      getSalonBooking($stateParams.salonID);
    }

    function goToCheckout(bookingID) {
      var salonID = $stateParams.salonID;
      $state.setReturn({salonID});
      $state.go('dashboard.edit-booking', {bookingID});
    }

    function goToReceipt(bookingID) {
      var salonID = $stateParams.salonID;
      $state.setReturn({salonID});
      $state.go('dashboard.payment', {bookingID});
    }

    function getDay(timeWorkAt) {
      if((timeWorkAt)) {
        var check = moment(timeWorkAt, 'YYYY/MM/DD');
        var day = check.format('dddd');
        return day;
      }
    }
    function getDate(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[0].substring(8, 10));
    }
    function getHour(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[0].substring(11, 14));
    }
    function getMinute(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[1].substring(0, 2));
    }
    function getSecond(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[2].substring(0, 2));
    }
    function formatDateFormat(date, format = "-", pattern = 'mmddyyyy') {
      var dateFormat = formatDateAllBrowser(date);
      if (pattern === 'mmddyyyy') {
        var workDate = (dateFormat.getMonth()+1)+format+dateFormat.getDate()+format+dateFormat.getFullYear();
      } else {
        var workDate = dateFormat.getFullYear()+format+(dateFormat.getMonth()+1)+format+dateFormat.getDate();
      }
      return workDate;
    }
    function formatDateAllBrowser(date) {
      var dateWorkat = getDate(date);
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').replace('+00:00', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = moment(new Date(parseDate));
      } else {
        var newDate = moment(date);
      }
      var dateCurrentWorkat = newDate.date();
      if(dateCurrentWorkat > dateWorkat) {
        dateCurrentWorkat-= 1;
      }
      var hours = getHour(date);
      var minute = getMinute(date);
      var second = getSecond(date);
      var newDateTime = new Date(newDate.year()+'/'+(newDate.month()+1)+'/'+dateCurrentWorkat+' '+hours+':'+minute+':'+second);
      return newDateTime;
    }

    function convertHourByTimeZone(hourTime) {

      var currentTimeSalon = new Date();
      var currentTimezone = Math.abs(currentTimeSalon.getTimezoneOffset());
      var timeZone = currentTimezone/60;

      var hourResult;

      var timeZoneText = moment().format("Z");

      if (timeZoneText.includes("-")) {
        hourResult =   hourTime + timeZone;
      } else {
        hourResult =   hourTime - timeZone;
      }

      return hourResult;
    }
    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function formatDateAllBrowser(date, includeTimeZone) {
      var userTimezoneOffset = new Date().getTimezoneOffset()*60000;
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').replace('+00:00', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = new Date(parseDate);
      } else {
        var newDate = date;
      }

      // TODO:
      // Remove || isEmpty(includeTimeZone) because it should include on case else
      if(includeTimeZone) {
        var dateFormat = new Date(newDate.getTime() + userTimezoneOffset);
      } else {
        var dateFormat = new Date(newDate.getTime());
      }
      // if(isDST(dateFormat)) {
      //   dateFormat.setHours(dateFormat.getHours() - 1);
      // }
      return dateFormat;
    }
    function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
      var jan = new Date(t.getFullYear(),0,1);
      var jul = new Date(t.getFullYear(),6,1);
      return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
    }
    function formatDateTime(date) {
      var dateFormat = formatDateAllBrowser(date);
      var workDate = dateFormat.getFullYear()+'-'+(dateFormat.getMonth()+1)+'-'+dateFormat.getDate();
      var hourType = 'AM';

      var hour = dateFormat.getHours();
      //hour = convertHourByTimeZone(hour);
      if (dateFormat.getHours() >= 12) {
        hourType = 'PM';
      }
      hour = hour < 10 ? '0'+ String(hour) : (hour <13 ?  String(hour) : '0' + parseInt(hour - 12));

      var min = dateFormat.getMinutes() < 10 ? '0'+String(dateFormat.getMinutes()) : dateFormat.getMinutes();
      var workTime = hour+':'+min+' '+hourType;
      return workTime;
    }
    function deleteBooking(item, index) {
      if (self.listBookings[index]) {
        if (confirm('Are you sure delete this item?')) {
          Bookings.deleteBooking(item.getUserId(), item.getId()).then(function(response) {
            if (!response.error) {
              self.listBookings.splice(index, 1);
              if(self.listBookings.length == 0) {
                self.page > 1 ? self.page-- : 1;
              }
              pageSearchChange()
            }
          })
        }
      }
    }

    function searchBooking(searchString) {
      self.page = 1;
      getSalonBooking($stateParams.salonID, searchString);
    }

    function getSalonBooking(salonID, keyword) {
        var params = {
          'page[number]': self.page,
          'page[size]': self.pageSize,
          'sort': '-work_at',
          'past': 'false',
          'user.username': angular.isDefined(self.searchString) && !/^\s*$/.test(self.searchString) ? '%' + self.searchString + '%' : ''
        }
        self.loadingPage = true;
        Salons.getBookings(salonID, params).then(function(response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listBookings = [];
            angular.forEach(response.data, function(value, key) {
                var booking = new Booking(value, response.included);
                self.listBookings.push(booking);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
    }

    function pageSearchChange() {
      getSalonBooking($stateParams.salonID);
    }
  }

})(angular);
