(function(angular) {
  'use strict';

  angular.module('Swivel').factory('SalonStyle', SalonStyle);

  SalonStyle.$inject = ['BaseObject'];
  function SalonStyle(BaseObject) {
    var SalonStyle = function(object) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getName = getName;
      self.getId = getId;
      self.getDescription = getDescription;

      function getAttributes() {
        return self.get('attributes');
      }

      function getName() {
        return self.getAttributes().name;
      }

      function getId() {
        return self.getAttributes().id;
      }

      function getDescription() {
        return self.getAttributes().description;
      }
    }

    return (SalonStyle)
  }
  
})(angular);
