(function(angular) {
  'use strict';

  angular.module('Swivel').factory('SalonStylist', SalonStylist);

  SalonStylist.$inject = ['BaseObject'];
  function SalonStylist(BaseObject) {
    var SalonStylist = function(object, included) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getName = getName;
      self.getStatus = getStatus;
      self.getCreatedAt = getCreatedAt;
      self.getId = getId;
      self.getRelationships = getRelationships;
      self.getTimeworks = getTimeworks;
      self.listTimeworkIds = [];
      self.included = included;
      self.getTimeworksByDate = getTimeworksByDate;
      self.getDateDefault = getDateDefault;
      self.getTimeDefault = getTimeDefault;
      self.getTimeworksFilterByDayTime = getTimeworksFilterByDayTime;
      self.getAvailableTimeworks = getAvailableTimeworks;
      self.getExternalScheduleLink = getExternalScheduleLink;
      getListId();

      function getAttributes() {
        return self.get('attributes');
      }

      function getId() {
        return self.getAttributes().id;
      }

      function getName() {
        return self.getAttributes().name;
      }

      function getStatus() {
        return self.getAttributes().status;
      }

      function getCreatedAt() {
        return self.getAttributes().createdAt;
      }

      function getRelationships() {
				return self.get('relationships');
      }
      
      function getExternalScheduleLink() {
        return self.getAttributes().externalScheduleLink;
      }

      function getListId() {
        if (self.getRelationships()['timeworks']) {
					angular.forEach(self.getRelationships()['timeworks'], function(value, key) {
						self.listTimeworkIds.push(value.id);
					})
				}
      }
      function getTimeworks() {
        if (included) {
          var timeworks = [];
          angular.forEach(included, function(value, key) {
						if (value.type == 'StylistWorktime' && self.listTimeworkIds.indexOf(value.attributes.id) != -1) {
							timeworks.push(value.attributes.work_at);
						}
					})
					return timeworks;
        }
      }
      function getTimeByDate(date) {
        if (included) {
          var timeworks = [];
          angular.forEach(included.data, function(value, key) {
						if (value.type == 'StylistWorktime' && getDate(value.attributes.work_at) == date) {
							timeworks.push(value.attributes.work_at);
						}
					})
					return timeworks;
        }
      }
      function formatTime(time) {
        time = parseFloat(time);
        var hourCal = Math.floor(time);
        var minuteCal =( time - hourCal)*60;
        var hour = String(hourCal);
        var min = minuteCal < 10 ? '0'+String(minuteCal) : minuteCal;
        var workTime = hour+':'+min;
        return workTime;
      }

      function getTimeworksFilterByDayTime(currentStylist, currentDate, timeCurrent, listTime) {

        var listTimeBlock = getTimeByDate(currentDate);
        for(var i = 0; i < listTimeBlock.length; i++) {
          for(var j = 0; j < listTime.length; j++) {
            var timeBlockItem = getTime(listTimeBlock[i]);
            var timeStylistWork = listTime[j];
            // if(timeBlockItem === timeStylistWork) {
            if(timeBlockItem === timeStylistWork && timeBlockItem != timeCurrent) {

              listTime.splice(j, 1);
              //return;
            }
          }
        }
        return listTime;
      }

      // get timeworks available
      function getAvailableTimeworks(currentDate, listTime) {

        var listTimeBlock = getTimeByDate(currentDate);
        for(var i = 0; i < listTimeBlock.length; i++) {
          for(var j = 0; j < listTime.length; j++) {
            var timeBlockItem = getTime(listTimeBlock[i]);
            var timeStylistWork = listTime[j];
            if(timeBlockItem === timeStylistWork ) {
              listTime.splice(j, 1);
            }
          }
        }

        return listTime;
      }

      function getDate(timeWorkAt) {
        return moment(timeWorkAt).utc().format('YYYY-MM-DD')
      }
      function isEmpty(text) {
        if(text === null || text === undefined || text === '') {
          return true;
        } else
          return false;
      }
      function getTime(timeWorkAt) {
        if(!isEmpty(timeWorkAt)) {
          return  timeWorkAt.substring(11, 16);
        }
      }
      function getDateDefault(date) {
        if (included) {
          var dateDefault = getDate(included.data[0].attributes.work_at);
          if(isEmpty(date)) {
            return dateDefault;
          }
          angular.forEach(included.data, function(value, key) {
              if (value.type == 'StylistWorktime' && getDate(self.getAttributes().work_at) == date) {
                dateDefault = getDate(value.attributes.work_at);
              }
          });
          return dateDefault
        }
      }
      function getTimeDefault(date) {
        if (included) {
          for(var i = 0; i < included.data.length; i++) {
            var dateItem = getTime(included.data[i].attributes.work_at)
            if (included.data[i].type == 'StylistWorktime' && date == dateItem) {
              return dateItem;
            }
          }
        }
      }
      function  getTimeworksByDate(date) {
        if (included) {
          var timeworksList = [];
          for(var i = 0 ; i < included.data.length; i++) {
            var itemTime = included.data[i];
            if (itemTime.type == 'StylistWorktime' && (date == getDate(itemTime.attributes.work_at))) {
              timeworksList.push(getTime(itemTime.attributes.work_at));
            }
          }
          return timeworksList;
        }
      }
    }
    return (SalonStylist);
  }


})(angular);
