(function(angular) {
  'use strict';

  angular.module('Swivel').factory('Stripe', Stripe);

  Stripe.$inject = ['BaseObject'];
  function Stripe(BaseObject) {
    var Stripe = function(object, included) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getId = getId;
      // self.getFirstName = getFirstName;
      // self.getLastName = getLastName;
      self.getEmail = getEmail;
      // self.getCountry = getCountry;
      // self.getGender = getGender;
      // self.getState = getState;
      // self.getPhone = getPhone;
      self.getListBankAcount = getListBankAcount;
      // self.getPostalNumber = getPostalNumber;
      // self.getStreet = getStreet;
      // self.getCity = getCity;
      // self.getDateOfBirth = getDateOfBirth;
      // self.getAddress = getAddress;
      self.getCurrency = getCurrency;
      self.address = null;

      self.isBlockedOnExternalAccount = isBlockedOnExternalAccount;
      self.isBlockedOnStripeSide = isBlockedOnStripeSide;
      self.isDoneOnboarding = isDoneOnboarding;

      init();
      function init() {
        // getAddress();
      }
      // function getAddress() {
      //   self.address = self.getAttributes()['stripe_object'].legal_entity.address;
      // }
      function getAttributes() {
        return self.get('attributes');
      }
      function getId() {
        return self.getAttributes().id;
      }

      function isBlockedOnExternalAccount() {
          var reqs = self.getAttributes().stripe_object.requirements;
          var allReqs = reqs.currently_due.concat(reqs.past_due);

          if (allReqs.length >= 1 && allReqs.every(r => r == 'external_account')) {
              return true;
          } else {
              return false;
          }
      }

      function isBlockedOnStripeSide() {
          var reqs = self.getAttributes().stripe_object.requirements;
          var isMissingReqs = reqs.currently_due.length + reqs.past_due.length != 0;

          return isMissingReqs;
      }

      function isDoneOnboarding() {
          return !self.isBlockedOnStripeSide || self.isBlockedOnExternalAccount;
      }

      function getListBankAcount() {
        var listBankAccount = [];
        if(self.getAttributes().stripe_object.external_accounts.data.length > 0) {
          listBankAccount = self.getAttributes().stripe_object.external_accounts.data;
        }
        return listBankAccount;
      }

      // function getFirstName() {
      //   return self.getAttributes().stripe_object.legal_entity.first_name;
      // }
      // function getLastName() {
      //     return self.getAttributes().stripe_object.legal_entity.last_name;
      // }
      function getEmail() {
        return self.getAttributes().stripe_object.email;
      }
      // function getCountry() {
      //   return self.address.country;
      // }
      // function getGender() {
      //    return self.getAttributes().stripe_object.legal_entity.gender;
      // }
      // function getState() {
      //   return self.address.state;
      // }
      // function getPhone() {
      //   return self.getAttributes().stripe_object.legal_entity.phone_number;
      // }
    //   function getPersonalId() {
    //     return self.getAttributes().stripe_object.email;
    //   }
      // function getPostalNumber() {
      //   return self.address.postal_code;
      // }
      // function getStreet() {
      //    return self.address.line1;
      // }
      // function getCity() {
      //    return self.address.city;
      // }
      // function getDateOfBirth() {
      //   return self.getAttributes().stripe_object.legal_entity.dob;
      // }
      function getCurrency() {
        var defaultCurrency = 'USD';
        var listCurrency = [];
        if(self.getAttributes().stripe_object.currencies_supported) {
          listCurrency = self.getAttributes().stripe_object.currencies_supported;;
        } else {
          listCurrency.push(defaultCurrency);
        }
        return listCurrency;
      }
    }

    return (Stripe);
  }
})(angular);
