(function (angular) {
  'use strict';

  angular.module('Swivel').service('Reviews', Reviews);

  Reviews.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];

  function Reviews($q, $http, serverUrl, Auth, Users) {
    var Reviews = function () {
      var self = this;
      self.create = create;
      self.getList = getList;
      self.updateReview = updateReview;
      self.deleteReview = deleteReview;
      self.updateReviewStatus = updateReviewStatus;
      self.searchReview = searchReview;

      function create(params, salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/reviews',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        };
        $http(requestParams).success(function (response) {
          deferred.resolve(response);
        }).error(function (error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteReview(salonID, reviewID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/reviews/' + reviewID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function (response) {
          deferred.resolve(response);
        }).error(function (error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateReview(salonID, reviewID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/reviews/' + reviewID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function (response) {
          deferred.resolve(response);
        }).error(function (error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateReviewStatus(salonID, reviewID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/reviews/' + reviewID + '/status',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function (response) {
          deferred.resolve(response);
        }).error(function (error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getList(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'reviews',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function (response) {
          Users.revokeUser(status);
          deferred.resolve(response);
        }).error(function (error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function searchReview(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'search/reviews',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function (response) {
          deferred.resolve(response);
        }).error(function (error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new(Reviews);
  }


})(angular);