(function(angular) {
  'use strict';

  angular.module('Swivel').service('SalonLocation', SalonLocation);

  SalonLocation.$inject = ['$q', '$http', 'serverUrl'];
  function SalonLocation($q, $http, serverUrl) {
    var SalonLocation = function() {
      var self = this;
      self.getCities = getCities;

      function getCities(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities',
					method: 'GET',
					params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (SalonLocation);
  }


})(angular);
