(function(angular) {
  'use strict';

  angular.module('Swivel').service('Bookings', Bookings);

  Bookings.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];
  function Bookings($q, $http, serverUrl, Auth, Users) {
    var Bookings = function() {
      var self = this;
      self.deleteBooking = deleteBooking;
      self.getBookingsDetail = getBookingsDetail;
      self.updateBooking = updateBooking;
      self.paymentBooking = paymentBooking;
      self.updatePaymentBooking = updatePaymentBooking;
      self.createBookingTry = createBookingTry;
      self.createBooking = createBooking;
      self.getUserCard = getUserCard;

      function updatePaymentBooking(userId, params, method) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + userId + '/cards',
          method: method,
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function paymentBooking(bookingId, params, method) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'bookings/' + bookingId + '/checkout',
          method: method,
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function updateBooking(userID, bookingID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'bookings/' + bookingID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function createBookingTry(salonId, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonId + '/bookings/manual/try',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getUserCard(userId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + userId + '/cards',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function createBooking(salonId, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonId + '/bookings/manual',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteBooking(userID, bookingID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + userID + '/bookings/' + bookingID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getBookingsDetail(bookingId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'bookings/' + bookingId,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (Bookings);
  }


})(angular);
