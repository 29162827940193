(function(angular) {
  'use strict';

  angular.module('Swivel').controller('ListPastBookingController', ListPastBookingController);

  ListPastBookingController.$inject = ['Auth', 'Users', 'User', 'Booking', 'Salons', 'Salon', 'Bookings', '$timeout', '$localStorage', '$state', '$stateParams'];
  function ListPastBookingController(Auth, Users, User, Booking, Salons, Salon, Bookings, $timeout, $localStorage, $state, $stateParams) {
    var self = this;
    self.listBookings = [];
    self.listSalon = [];
    self.loadingPage = false;
    self.deleteBooking = deleteBooking;
    self.page = $localStorage.currentPage > 1 ? $localStorage.currentPage : 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.pageSizeSalonOwner = 200;
    self.salonCurrentId = null;
    self.searchString = '';
    self.pageSearchChange = pageSearchChange;
    self.formatDateTime = formatDateTime;
    self.getSalonBooking = getSalonBooking;
    self.searchBooking = searchBooking;
    self.eventClickGetListBooking = eventClickGetListBooking;


    init();

    function init() {
      var pageSize = self.pageSize;
        var params = {
          'page[number]': self.page,
          'page[size]': Auth.isAdmin() ? self.pageSize : self.pageSizeSalonOwner
        };
        self.loadingPage = true;
        self.listSalon = [];
        if(Auth.isAdmin()) {
          getListSalon(function(salonFist) {
            self.salonCurrentId = salonFist.getId();
            getSalonBooking(self.salonCurrentId);
          });
        } else if(Auth.isOwner()) {
          Users.getUserProfile().then(function(response) {
            var user = new User(response.data, response.included);
            var userId = user.getId();
            getListSalonByOwner(userId, function(salonFist) {
              self.salonCurrentId = salonFist.getId();
              getSalonBooking(self.salonCurrentId);
            });
          });
        }
    }

    function eventClickGetListBooking(salonId) {
      self.salonCurrentId = salonId;
      self.totalObject = 0;
      self.pageSize = 10;
      self.page = 1;
      getSalonBooking(self.salonCurrentId);
    }
    // get list salon
    function getListSalon(cb) {
      self.listSalon = [];
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      Salons.getList(params).then(function(response) {
        if (!response.error && response.data) {
          Salons.drainList(response, params, []).then(function(allSalons) {
            var count =0;
            angular.forEach(allSalons, function(value){
              count++;
              var salonItem = new Salon(value, response.included);
              self.listSalon.push(salonItem);
              if(count == allSalons.length) {
                if(typeof cb == 'function') {
                  cb(self.listSalon[0]);
                }
              }
            });
          }, function(error) {
            count++;
          })
        }
      }, function(error) {
        count++;
        //alert("System has error when getting list salon!");
      });
    }
    function getListSalonByOwner(id, cb) {
      self.listSalon = [];
      var params = {
        'page[number]': 1,
        'page[size]': 150,
      }
      Salons.getListSalonOwner(id, params).then(function(response) {
        if (!response.error && response.data) {
          var count =0;
          angular.forEach(response.data, function(value){
            count++;
            var salonItem = new Salon(value, response.included);
            self.listSalon.push(salonItem);
            if(count == response.data.length) {
              if(typeof cb == 'function') {
                cb(self.listSalon[0]);
              }
            }
          });
        }
      }, function(error) {
        count++;
        //alert("System has error when getting list salon!");
      });
    }
    function getDay(timeWorkAt) {
      if((timeWorkAt)) {
        var check = moment(timeWorkAt, 'YYYY/MM/DD');
        var day = check.format('dddd');
        return day;
      }
    }
    function getDate(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[0].substring(8, 10));
    }
    function getHour(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[0].substring(11, 14));
    }
    function getMinute(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[1].substring(0, 2));
    }
    function getSecond(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return parseInt(array[2].substring(0, 2));
    }
    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function formatDateAllBrowser(date, includeTimeZone) {
      var userTimezoneOffset = new Date().getTimezoneOffset()*60000;
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = new Date(parseDate);
      } else {
        var newDate = date;
      }
      if(includeTimeZone || isEmpty(includeTimeZone)) {
        var dateFormat = new Date(newDate.getTime() + userTimezoneOffset);
      } else {
        var dateFormat = new Date(newDate.getTime());
      }
      // if(isDST(dateFormat)) {
      //   dateFormat.setHours(dateFormat.getHours() - 1);
      // }
      return dateFormat;
    }
    function convertHourByTimeZone(hourTime, timeZone) {
      var hourResult;

      var timeZoneText = moment().format("Z");

      if (timeZoneText.includes("-")) {
        hourResult =   hourTime + timeZone;
      } else {
        hourResult =   hourTime - timeZone;
      }

      return hourResult;
    }
    function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
      var jan = new Date(t.getFullYear(),0,1);
      var jul = new Date(t.getFullYear(),6,1);
      return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
    }
    function formatDateTime(date) {
      var dateFormat = formatDateAllBrowser(date);
      var workDate = dateFormat.getFullYear()+'-'+(dateFormat.getMonth()+1)+'-'+dateFormat.getDate();
      var hourType = 'AM';

      var hour = dateFormat.getHours();
      //hour = convertHourByTimeZone(hour);
      if (dateFormat.getHours() >= 12) {
        hourType = 'PM';
      }
      hour = hour < 10 ? '0'+ String(hour) : (hour < 13 ?  String(hour) : '0' + parseInt(hour - 12));

      var min = dateFormat.getMinutes() < 10 ? '0'+String(dateFormat.getMinutes()) : dateFormat.getMinutes();
      var workTime = hour+':'+min+' '+hourType;
      return workTime;
    }
    function deleteBooking(item, index) {
      if (self.listBookings[index]) {
        if (confirm('Are you sure delete this item?')) {
          Bookings.deleteBooking(item.getUserId(), item.getId()).then(function(response) {
            if (!response.error) {
              self.listBookings.splice(index, 1);
              if(self.listBookings.length == 0) {
                self.page > 1 ? self.page-- : 1;
              }
              pageSearchChange()
            }
          })
        }
      }
    }

    function searchBooking(searchString) {
      self.page = 1;
      getSalonBooking(self.salonCurrentId, searchString);
    }

    function getSalonBooking(salonID, keyword) {
        var params = {
          'page[number]': self.page,
          'page[size]': self.pageSize,
          'sort': '-work_at',
          'past': 'true',
          'user.username': angular.isDefined(self.searchString) && !/^\s*$/.test(self.searchString) ? '%' + self.searchString + '%' : ''
        }
        self.loadingPage = true;
        Salons.getBookings(salonID, params).then(function(response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listBookings = [];
            angular.forEach(response.data, function(value, key) {
              var booking = new Booking(value, response.included);
              self.listBookings.push(booking);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
    }

    function pageSearchChange() {
      getSalonBooking(self.salonCurrentId);
    }
  }

})(angular);
