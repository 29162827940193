(function(angular) {
  'use strict';

  angular.module('Swivel').controller('EditBookingController', EditBookingController);
  EditBookingController.$inject = ['stripeKey','$scope','SalonLocation', 'SalonOwner', 'SalonOwners', 'User', 'Users', 'Salon', 'Salons','$localStorage', '$state', '$stateParams','Booking','Bookings', 'SalonStylist', 'SalonStylists', 'SalonService', 'SalonServices', 'Setting', 'Settings', '$timeout', '$filter'];

  function EditBookingController(stripeKey, $scope, SalonLocation, SalonOwner, SalonOwners, User, Users, Salon, Salons, $localStorage, $state, $stateParams, Booking, Bookings, SalonStylist, SalonStylists, SalonService, SalonServices, Setting, Settings, $timeout, $filter) {
    var self = this;
    self.goToSalonOwnerPage = goToSalonOwnerPage;
    self.validateSalonName = validateSalonName;
    self.isShowMessageError = false;
    self.updateSalonOwner = updateSalonOwner;
    self.getListStylist = getListStylist;
    self.getListService = getListService;
    self.getBookingDetail = getBookingDetail;
    self.initDatetimePicker = initDatetimePicker;
    self.openFormAddService = openFormAddService;
    self.hideFormAddService = hideFormAddService;
    self.eventChangeInput = eventChangeInput;
    self.addServiceToList = addServiceToList;
    self.checkOutBooking = checkOutBooking;
    self.deleteService = deleteService;
    self.updateBooking = updateBooking;
    self.eventChooseStylist = eventChooseStylist;
    self.dateChanged = dateChanged;
    self.checkInputEmpty = checkInputEmpty;
    self.checkInputEmptyOrInvalidNumber = checkInputEmptyOrInvalidNumber;
    self.checkDateSelectBooking = checkDateSelectBooking;
    self.getTimeworksFilterByDate = getTimeworksFilterByDate;
    self.getPromoCodeByName = getPromoCodeByName;
    self.addService = addService;
    self.cancelAddService = cancelAddService;
    self.cancelEdit = cancelEdit;
    self.checkTypeNumber = checkTypeNumber;
    self.submitEdit = submitEdit;
    self.getPromocode = getPromocode;
    self.getTaxFromSetting = getTaxFromSetting;
    self.formatTime = formatTime;
    self.getDate = getDate;
    self.getTime = getTime;
    self.findStylist = findStylist;
    self.checkValidatePhone = checkValidatePhone;
    self.isValidPromoCode = true;
    self.bookingCurrentId = null;
    self.bookingDetail = null;
    self.isFirstLoad = true;
    self.loadingPage = true;
    self.salonCurrent = [];
    self.salonCurrent = null;
    self.listStylist = [];
    self.listService = [];
    self.currentDateTimeActive = {
      startDate: null,
      endDate: null
    };
    self.promoCodePriceCurrent = 0.00;
    self.listServerInSalon = [];
    self.listServiceHadSave = [];
    self.salonCurrentTime = [];
    self.listServiceIdModel = [];
    self.promoCode = null;
    self.listServiceSelect = [];
    self.currenStyleList = null;
    self.isChangePromCode = false;
    self.promocodePercent = 0;
    self.promocodeValue = 0;
    self.listServiceAdd = [];
    self.dateCurrent = null;
    self.dayCurrent = null;
    self.timeCurrent = null;
    self.listEmpty = [];
    self.timeZoneCurrent = null;
    self.isErrorInputPrice = false;
    self.isEmptyInput = false;
    self.isAvailableToCheckDateTime = false;
    self.isAvailableToShowBookingDateTime = true;
    self.indexRowIsEmpty = -1;
    self.taxPerCent = 0;
    self.tipPerCent = 10;
    self.totalPriceService = 0.00;
    self.regrexFloat = '^[-]?[0-9]+[\.]?[0-9]+$';
    self.regrexInt = '^[0-9]';
    self.reg_phone = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    self.valueTax = 0;
    self.valueTip = 0;
    self.owner = {
      username : '',
      salon_id : -1
    };
    self.bookingParamModel = null;
    self.isShowAddService = false;
    self.page = 1;
    self.pageSize = 150;
    self.totalPrice = 0;
    self.creditCardNumber = '';
    self.ErrorMessageCreateOwner = "Owner has existed or system has probleme when saving!";
    self.CreateOwnerSucces = "Update salon owner successful!";
    self.isShowCardForm = false;
    self.showCardForm = showCardForm;
    self.hideCardForm = hideCardForm;
    self.initCard = initCard;
    self.saveCard = saveCard;
    self.cardInput = null;
    self.stripe = Stripe(stripeKey);
    self.cardElement = null;
    self.isShowMessageCardError = false;
    self.cardInput = {};

    init();

    function init() {
      initDatetimePicker();
      getBookingDetail(function() {
        datePicker();
        addService();
      });
      // initCard();
    }
    function checkOutBooking() {
      updateBooking(function() {
        paymentBooking();
      });
    }

    function checkValidatePhone(phone) {
      if(self.reg_phone.test(phone)) {
        return true;
      } else {
        return false;
      }
    }

    function showCardForm() {
      if (self.updateCreditCardLoading) {
        return;
      }
      self.isShowCardForm = true;
      $timeout(function () {
        initCard();
      }, 500);
    }

    function hideCardForm() {
      self.isShowCardForm = false;
    }

    function checkDateSelectBooking() {
      var datepicker =  angular.element('.datetimepicker-cus')[0];
      if(isEmpty(datepicker.val)) {
        angular.element(datepicker).val(self.dateCurrent);
      }
    }
    function updatePrice(listService, tax, tip) {
      var totalService = caculateListService(self.listService);
      self.valueTax = calcuFee(listService, tax, self.promocodeValue);
      self.valueTip = calcuFeeForTip(listService, tip);
      self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
      // Set promo-code price from real service price before plus tax and tip below
      self.promocodeValue = calculatePromoCode(totalService, self.promocodePercent);
    }
    $scope.$watch('EditBookingCtrl.taxPerCent', function(newValue, oldValue) {
      updatePrice(self.listService, newValue, self.tipPerCent);
    });
    $scope.$watch('EditBookingCtrl.tipPerCent', function(newValue, oldValue) {
      updatePrice(self.listService, self.taxPerCent, newValue);
    });
    $scope.$watch('EditBookingCtrl.listService', function(newValue, oldValue) {
      updatePrice(newValue, self.taxPerCent, self.tipPerCent);
    });
    function checkInputEmptyOrInvalidNumber(text) {
      if(isEmpty(text) || isNaN(text)) {
        return true;
      } else {
        return false;
      }
    }
    function getTimeworksFilterByDate() {

    }
    // get promo code price by name
    function getPromoCodeByName(newName, oldName) {
      if(isEmpty(newName)) {
        self.isEmptyPromoCode = true;
        self.promocodeValue = parseFloat(0);
        self.isValidPromoCode = true;
        self.isChangePromCode = false;
        self.promocodePercent =  parseFloat(0);
        self.promoCodePriceCurrent = 0;
        self.promocodeValue = angular.copy(self.promoCodePriceCurrent);
        self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
      } else {
        self.isEmptyPromoCode = false;
        self.isChangePromCode = true;
        Settings.getPromoCodeByName(newName).then(function(response) {
          if (!response.error && response.data) {
            self.promocodePercent =  parseFloat(response.data.attributes.value);
            self.promocodeValue = calcuFee(self.listService, self.promocodePercent, 0);
            self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
            self.isValidPromoCode = true;
            self.isChangePromCode = false;
          }
        }, function(error) {
          self.promoCodePriceCurrent = parseFloat(0);
          self.promocodePercent =  parseFloat(0);
          self.promocodeValue = parseFloat(0);
          self.isChangePromCode = false;
          self.isValidPromoCode = false;
          // if(self.isEmptyPromoCode) {
          //   self.isValidPromoCode = true;
          // } else {
          //   self.isValidPromoCode = false;
          // }

          self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
        });
      }
    }
    // get promo code price by name .end

    function paymentBooking() {
      self.loadingPage = true;
      Bookings.paymentBooking(self.bookingDetail.getId(), null, 'POST').then(function(response) {
        if (!response.error && response.data) {
          alert('Payment booking successfull!');
          self.loadingPage = false;
          $state.goReturn() ||
          $state.go('dashboard.bookings', {salonID: self.bookingDetail.getSalonId()});
        }
      }, function(error) {

        if (error != null && error.errors.length > 0 && error.errors[0].detail === 'Card information or token is not exists.') {
          alert('The user has removed card infomation.');

        } else {
          alert('Cannot payment booking!');
        }

        self.loadingPage = false;
      });
    }
    // check exist card number
    function checkExistCardNumber(idUser,callBack) {
      Users.getUserCreditCard(idUser).then(function(response) {
        if (!response.error && response.data) {
          if(typeof callBack === 'function') {
            callBack();          }
        } else {

        }
      }, function(error) {

      });
    }
    function checkTypeNumber(text) {
      if(isEmpty(text) || !checkNumber(text)) {
        self.isErrorInputPrice = true;
        updatePrice(self.listService, self.taxPerCent, self.tipPerCent);
        return true;
      } else {
        updatePrice(self.listService, self.taxPerCent, self.tipPerCent);
        return false;
      }
    }
    function datePicker() {
      // var dateObject = new Date();
      $timeout(function() {
        $('.datetimepicker-cus').datepicker({
          format: 'yyyy-mm-dd',
          autoclose: true,
          // allow all day to pick
          // startDate: dateObject
        }).change(dateChanged)
      }, 200);
    }
    function addServiceToList() {
      self.isFirstLoad = false;
      addService();
    }
    function dateChanged() {
        var stylistId = self.bookingParamModel.stylist_id;
        var param = {
          'work_at': ">=" + self.dateCurrent,
          'page[number]': 1,
          'page[size]': 200
        };
        self.bookingParamModel.stylist_id = self.currenStyleList.getId();
        var isStyleHaveBooked = self.currenStyleList.getId() == self.bookingDetail.getStylistId() ? true : false;
        var isDateBooking = self.dateCurrent == getDate(self.bookingDetail.getDateWorkAt()) ? true : false;
        self.dayCurrent = getDay(self.dateCurrent);
        var timeCurrent = getTime(self.bookingDetail.getDateWorkAt());
        // allow all time
        // self.salonCurrentTime = self.salonCurrent.getTimeworksFilterByDate(self.dayCurrent );
        self.salonCurrentTime = [
          '00:00', '00:30', '01:00', '01:30',
          '02:00', '02:30', '03:00', '03:30',
          '04:00', '04:30', '05:00', '05:30',
          '06:00', '06:30', '07:00', '07:30',
          '08:00', '08:30', '09:00', '09:30',
          '10:00', '10:30', '11:00', '11:30',
          '12:00', '12:30', '13:00', '13:30',
          '14:00', '14:30', '15:00', '15:30',
          '16:00', '16:30', '17:00', '17:30',
          '18:00', '18:30', '19:00', '19:30',
          '20:00', '20:30', '21:00', '21:30',
          '22:00', '22:30', '23:00', '23:30',
        ];
        var currentStylist = self.currenStyleList;
        //getSalonCurrentTime(self.bookingDetail.getSalonId(), self.dayCurrent, function() {
          //$scope.$apply(function (){
           SalonStylists.getStylistTimeworks(self.bookingDetail.getSalonId(), stylistId, param).then(function(response) {
              if (!response.error && response.data) {
                //self.currentStylist.included = response;
                var stylist = new SalonStylist(currentStylist.data, response);
                self.currentStylist = stylist;

                // allow all time
                // var listTime;
                // if (self.isAvailableToShowBookingDateTime || isDateBooking) {
                //   listTime = self.currentStylist.getTimeworksFilterByDayTime(currentStylist, self.dateCurrent, timeCurrent, self.salonCurrentTime);
                // } else {
                //   listTime = self.currentStylist.getTimeworksFilterByDayTime(currentStylist, self.dateCurrent, "", self.salonCurrentTime);
                // }
                //
                // if(listTime.length < 1) {
                //   if (self.isAvailableToCheckDateTime) {
                //     alert("This date are blocked. Please choose another date!");
                //   }
                // }
                if(isStyleHaveBooked) {
                  self.timeCurrent = getTime(self.bookingParamModel.work_at);
                } else {
                  // self.timeCurrent = listTime[0];
                  self.timeCurrent = self.salonCurrentTime[0];
                }
              }

              self.isAvailableToCheckDateTime = true; //Set enable anyway at the end of datetime function
              self.isAvailableToShowBookingDateTime = false // Disable show time anyway right after set time at the first time initial the page
            }, function(error) {
              self.isAvailableToCheckDateTime = true; //Set enable anyway at the end of datetime function
            });
            // getListStylist(self.bookingDetail.getSalonId(), self.dateCurrent, true, function() {
            //   self.currenStyleList = findStylist(self.bookingDetail.getStylistId());
            // });
          //}, 200);
        //});
    }
    function eventChangeInput(text) {
      checkInputEmpty(text);
    }
    function submitEdit() {
      self.isFirstLoad = false;
    }
    function findItemInArray(array, itemId) {
      for(var i = 0; i < array.length; i++) {
        var item = array[i];
        if(parseInt(item) === parseInt(itemId)) return i;
      }
    }
    function checkNumber(number) {
      number = String(number);
      if(number.match(/^\d+$/)){
        return true;
      } else if(number.match(/^\d+\.\d+$/)){
        return true;
      } else {
        return false;
      }
    }
    function caculateListService(listService) {
      var totalPrice = 0;
      if(listService.length > 0) {
        for(var i = 0; i< listService.length; i++) {
          var value = parseFloat(listService[i].price);
          if(value) {
            totalPrice+= parseFloat(value);
          }
        }

      }
      return totalPrice;
    }
    function caculateAmount(listService, tax, tip) {
      if(isEmpty(tax)) {
        tax = 0;
      }
      if(isEmpty(tip)) {
        tip = 0;
      }
      var totalPrice = 0;
      for(var i = 0; i< listService.length; i++) {
        var value = parseFloat(listService[i].price);
        if(value) {
          totalPrice+= parseFloat(value);
        }
      }

      if(checkNumber(tax)) {
        totalPrice += parseFloat(tax);
      }
      if(checkNumber(tip)) {
        totalPrice += parseFloat(tip);
      }
      if(self.promocodeValue) {
        totalPrice -= parseFloat(self.promocodeValue);
      }
      return totalPrice;
    }
    function deleteService(item, $index) {
      self.isFirstLoad = false;
      //self.listService.pop(item);
      self.listService.splice($index,1);
      var itemResult = findService(self.listServerInSalon, item.id);
      if(item !== null) {
        itemResult.checked = false;
        self.listServiceSelect.push(itemResult);
      }
      var indexOfServiceAdd = findItemInArray(self.listServiceAdd, item.id);
      self.listServiceAdd.splice(indexOfServiceAdd, 1);
      self.valueTax = calcuFee(self.listService, self.taxPerCent, self.promocodeValue);
      self.valueTip = calcuFeeForTip(self.listService, self.tipPerCent);
      self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
      var listMoneyService = caculateAmount(self.listService, 0, 0);
      self.totalPriceService = listMoneyService;
    }

    //event choose stylist
    function  eventChooseStylist() {
      var idStylist = self.bookingParamModel.stylist_id;
      rechooseStylist(idStylist, function(){
        reInitialDateTimePicker();
      });
    }
    // event choose stylist .end

    function rechooseStylist(idStylist, callBack) {
      self.currentStylist = findStylist(idStylist);
      if(callBack !== null && typeof(callBack) == 'function') {
        callBack();
      }
    }
    // check empty price
    function checkInputEmpty(text) {
      if(text === '' || text === null || text === undefined) {
        self.isEmptyInput = true;
        return true;
      } else {
        self.isEmptyInput = false;
        return false;
      }
    }
    // check empty price .end
    // find stylist
    function findStylist(stylistId) {
      for(var i = 0 ; i < self.listStylist.length; i++) {
         if(self.listStylist[i].getId() === stylistId) {
          self.currenStyleList =  self.listStylist[i];
          self.bookingParamModel.stylist_id = self.currenStyleList.getId();
          var isStyleHaveBooked = self.currenStyleList.getId() == self.bookingDetail.getStylistId() ? true : false;
          var isDateBooking = self.dateCurrent == getDate(self.bookingDetail.getDateWorkAt()) ? true : false;
          if(isStyleHaveBooked && isDateBooking) {
            self.timeCurrent = getTime(self.bookingParamModel.work_at);
          } else {
            self.timeCurrent = self.currenStyleList.getTimeworksByDate(self.dateCurrent)[0];
          }
          return self.listStylist[i];
        }
      }
    }
    // find stylist .end
    // add list service id had choose
    function  addListServiceModel(listData, listDes) {
      var listDes = [];
      angular.forEach(listData, function(value, key) {
        listDes.push(value.id);
      });
      return listDes;
    }
    // add list service id had choose .end

    // concat two arraylist had choose
    function concatArray(listSoure, listDes) {
      listDes = listDes.concat(listSoure);
      return listDes;
    }
    // concat two arraylist had choose .end
    function getTaxFromSetting() {
      Settings.getTaxPercent().then(function(response) {
        if (!response.error && response.data) {
          self.taxPerCent = response.data.attributes.tax;
        }
      }, function(error) {

      });
    }
    function cancelAddService() {
      self.isShowAddService = false;
      self.listServiceIdModel = [];
      self.listServiceIdModel = addListServiceModel(self.listService, self.listServiceIdModel);
      self.listServiceIdModel = concatArray(self.listServiceAdd, self.listServiceIdModel);
    }
    function getIndexItem(list,id) {
      for(var i = 0; i < list.length; i++) {
        if(list[i].getId() === id) {
          return i;
        }
      }
    }
    function addService() {
      if(self.listServiceAdd.length > 0) {
        var isTrue = true;
        var i = 0;
        while(isTrue) {
          var itemService = findService(self.listServerInSalon, self.listServiceAdd[i]);
          if(itemService !== null && !checkExist(self.listService, self.listServiceAdd[i])) {
            self.listService.push(itemService.data.attributes);
            var indexItem = getIndexItem(self.listServiceSelect, self.listServiceAdd[i]);
            self.listServiceSelect.splice(indexItem,1);
          }
          i++;
          if(i === self.listServiceAdd.length) {
            isTrue = false;
          }
        }
      }
      self.valueTax = calcuFee(self.listService, self.taxPerCent, self.promocodeValue);
      self.valueTip = calcuFeeForTip(self.listService, self.tipPerCent);
      self.totalPrice = caculateAmount(self.listService, self.valueTax, self.valueTip);
      var listMoneyService = caculateAmount(self.listService, 0, 0);
      self.totalPriceService = listMoneyService;
      self.listServiceIdModel = [];
      self.listServiceIdModel = addListServiceModel(self.listService, self.listServiceIdModel);
      self.isShowAddService = false;
    }
    function  findService(listService, id) {
      for(var i = 0 ; i < listService.length; i++) {
        var item = listService[i];
        if(item.getId() === id) {
          return item;
        }
      }
      return null;
    }
    function checkExist(listService, id) {
      for(var i = 0 ; i < listService.length; i++) {
        var item = listService[i];
        if(item.id === id) {
          return true;
        }
      }
      return false;
    }
    // remove the same item two array
    function filterSameItemArray(array1, array2) {
      var listTotal = [];
      angular.forEach(array1, function(value1, key1) {
        angular.forEach(array2, function(value2, key2) {
          if(value1.id === value2.data.id) {
            array2.splice(key2, 1);
            return;
          }
        });
      });
      return array2;
    }
    // remove the same item two array .end

    //get list service
    function  getListService(id, params) {
    self.listServiceSelect = [];
    self.listServerInSalon = [];

    //console.log(self.bookingDetail.getListService());
      SalonServices.getList(id, params).then(function(response) {
        if(!response.error && response.data) {
          angular.forEach(response.data, function(value, key) {
            var salonSerivice = new SalonService(value, response.included);
            self.listServerInSalon.push(salonSerivice);
          });
          self.listServiceSelect = angular.copy(self.listServerInSalon);
          self.listServiceSelect = filterSameItemArray(self.listService, self.listServiceSelect);
        }
      });
    }
    //get list service .end

    // open form add service
    function openFormAddService() {
      self.isShowAddService = true;
    }
    // open form add service .end

    // hide form add service
    function hideFormAddService() {
      self.isShowAddService = false;
    }
    // hide form add service .end
    // init datetime picker
    function initDatetimePicker() {
      //$('#datepicker').datetimepicker();
    }
    // init datetime picker .end
    function  getTimeZone(timeWorkAt) {
      var array = timeWorkAt.split(":");
      return array[2].substring(2, 7);
    }
    function getFirstDayOfWeek(timeWorkAt) {

    }
    function  getLastDateOfWeek(argument) {

    }
    function getDate(timeWorkAt) {
      return moment(timeWorkAt).utc().format('YYYY-MM-DD')
    }
    function getDay(timeWorkAt) {
      if(!isEmpty(timeWorkAt)) {
        var check = moment(timeWorkAt, 'YYYY/MM/DD');
        var day = check.format('dddd');
        return day;
      }
    }
    function formatTime(time) {
      var hourType = 'AM';
      var hourCal = parseInt(time.substring(0, 2));
      var minuteString = time.substring(3, 5);
      var minuteCal = parseInt(minuteString);
      if (hourCal> 11) {
        hourType = 'PM';
      }
      var hour = hourCal < 10 ? '0'+String(hourCal) : (hourCal < 13 ?  String(hourCal) : '0' + parseInt(hourCal - 12));
      hour = hour.slice(-2);
      var min = minuteCal < 10 ? '0'+String(minuteCal) : minuteCal;
      var workTime = hour+':'+min+' '+hourType;
      return workTime;
    }
    function getTime(timeWorkAt) {
      if(!isEmpty(timeWorkAt)) {
        return  timeWorkAt.substring(11, 16);
      }
    }
    function getFullTime(timeWorkAt) {
      if(!isEmpty(timeWorkAt)) {
        return  timeWorkAt.substring(11, 19);
      }
    }
    function getDateDisable(timeworks) {
      var disableDay = [];
      var listDateDisable = [];
      angular.forEach(timeworks, function(value, key) {
        if (value.status == 1) {
          switch (value.weekday) {
            case 'Monday':
              disableDay.push(1);
            break;
            case 'Tuesday':
              disableDay.push(2);
            break;
            case 'Wednesday':
              disableDay.push(3);
            break;
            case 'Thursday':
              disableDay.push(4);
            break;
            case 'Friday':
              disableDay.push(5);
            break;
            case 'Saturday':
              disableDay.push(6);
            break;
            case 'Sunday':
              disableDay.push(0);
            break;
          }
        }
      })

      if (disableDay.length > 0) {
        var startDate = formatDateAllBrowser(self.currentDateTimeActive.startDate);
        var endDate = formatDateAllBrowser(self.currentDateTimeActive.endDate);

        // for (var i=startDate.getDate();i<=endDate.getDate();i++) {
        //   var dateDisable = new Date(startDate.getFullYear()+'-'+(startDate.getMonth()+1)+'-'+i);
        //   if (disableDay.indexOf(dateDisable.getDay()) != -1) {
        //     listDateDisable.push(dateDisable.getFullYear()+'-'+(dateDisable.getMonth()+1)+'-'+i);
        //   }
        // }
        var year = startDate.getFullYear();
        for (var i=1;i<=12;i++) {
          var month = i == 12 ? i-1 : i;
          var firstDay = new Date(year, month, 1);
          var lastDay = new Date(year, month, 0);
          for (var j=firstDay.getDate();j<=lastDay.getDate();j++) {
            var dateDisable = formatDateAllBrowser(year+'-'+i+'-'+j);
            if (disableDay.indexOf(dateDisable.getDay()) != -1) {
              listDateDisable.push(dateDisable.getFullYear()+'-'+(dateDisable.getMonth()+1)+'-'+j);
            }
          }
        }
      }
      return listDateDisable;
    }
    function formatDateAllBrowser(date) {
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = moment(new Date(parseDate));
      } else {
        var newDate = moment(date);
      }
      return new Date(newDate.year()+'/'+(newDate.month()+1)+'/'+newDate.date()+' '+ (parseInt(newDate.hour())) +':'+newDate.minute()+':'+newDate.second())
    }
    // get list style
    function getListStylist(idSalon, timeWorkAt, isformatedTime, cb) {
      self.listStylist = [];
      var time = null;
      if(isformatedTime) {
        time = timeWorkAt;
      } else {
        time = getDate(timeWorkAt);
      }
      SalonStylists.getList(idSalon, {'status': 2}).then(function(response) {
        if (!response.error && response.data.length) {
          var i = 0;
          angular.forEach(response.data, function(value, key) {
            var params = {
              'page[number]': self.page,
              'page[size]': self.pageSize,
              'work_at': '>=' + time
            }
            SalonStylists.getStylistTimeworks(idSalon, value.attributes.id, params).then(function(responseData) {
              if(!responseData.error) {
                var salonStylist = new SalonStylist(value, responseData);
                self.listStylist.push(salonStylist);
              }

              if(i++ == response.data.length - 1 && typeof cb == 'function') {
                cb();
              }

            });

          });
        }
      });
    }
    // get list style .end

    //update salon owner
    function updateSalonOwner() {
      self.loadingPage = true;
      if(self.bookingCurrentId != null) {
        if(self.owner.username !== "" && self.owner.username !== undefined) {
          self.isShowMessageError = false;
          SalonOwners.updateSalonOwner(self.bookingCurrentId, self.owner).then(function(response) {
            if (!response.error && response.data) {
              goToSalonOwnerPage();
            }
          },function(error) {
            alert(self.ErrorMessageCreateOwner);
            self.isShowMessageError = false;
          });
        }  else {
          self.isShowMessageError = true;
        }
      }
    }
    //update salon owner .end

    // validate model salon name
    function validateSalonName() {
      if(self.username === '' || self.username === undefined) {
        self.isShowMessageError = true;
      }
    }
    // validate model salon name .end
    function getPromocode(id) {
      Salons.getPromocode(id).then(function(response) {
        if (!response.error && response.data) {
          return response.data;
        }
      });
    }
    function reInitialDateTimePicker() {
        getSalonCurrentTime(self.bookingDetail.getSalonId(), self.currenStyleList, self.dayCurrent, self.timeCurrent, function() {
          var currentDateActive = new Date();
          var firstDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth(), 1);
          var lastDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth() + 1, 0);
          self.currentDateTimeActive.startDate = firstDay.getFullYear()+'-'+(firstDay.getMonth()+1)+'-'+firstDay.getDate();
          self.currentDateTimeActive.endDate = lastDay.getFullYear()+'-'+(lastDay.getMonth()+1)+'-'+lastDay.getDate();
          // allow all day to pick
          // angular.element('.datetimepicker-cus').datepicker('setDatesDisabled', getDateDisable(self.salonCurrent.getTimeworks()));
        });
    }
    // get booking detail
    function getBookingDetail(callBack) {
      self.bookingCurrentId = $stateParams.bookingID;
      self.loadingPage = true;
      Bookings.getBookingsDetail(self.bookingCurrentId).then(function(response) {
        if (!response.error && response.data) {
          self.bookingDetail = new Booking(response.data, response.included);
          Users.getUserCreditCard(self.bookingDetail.getUserId()).then(function(response) {
            if (!response.error && response.data) {

              self.creditCardNumber = response.data.id ? response.data.attributes.last4 : null;
            }
          }, function(error) {

          });
          var params = {
            'page[number]': self.page,
            'page[size]': self.pageSize,
            'status': 2
          }
          getListService(self.bookingDetail.getSalonId(), params);
          var listServiceDataOne = self.bookingDetail.getServices();
          self.listServiceHadSave = angular.copy(listServiceDataOne);
          self.listService = angular.copy(listServiceDataOne);
          self.listServiceIdModel = addListServiceModel(self.listService, self.listServiceIdModel);
          getListStylist(self.bookingDetail.getSalonId(), self.bookingDetail.getDateWorkAt(), false, function() {
              self.currenStyleList = findStylist(self.bookingDetail.getStylistId());
              var dateWorkat = getDate(self.bookingDetail.getDateWorkAt());
              self.dateCurrent = dateWorkat;
              self.dayCurrent = dateWorkat;
              self.timeCurrent = getTime(self.bookingDetail.getDateWorkAt());
              reInitialDateTimePicker();
          });
          self.timeZoneCurrent = getTimeZone(self.bookingDetail.getDateWorkAt());
          getDate(self.bookingDetail.getDateWorkAt());
          self.isFirstLoad = true;
          self.totalPrice = parseFloat(self.bookingDetail.getTotoPrice());
          self.promoCode = self.bookingDetail.getPromoCode();
          var statusCheckout = self.bookingDetail.getStatusName(self.bookingDetail.getStatus());
          var listMoneyService = caculateAmount(self.listService, 0, 0);
          self.totalPriceService = listMoneyService;
          self.promocodePercent = parseFloat(self.bookingDetail.getPromocodeValue());
          self.promocodeValue = calculatePromoCode(self.totalPriceService, self.promocodePercent);
          self.promoCodePriceCurrent = angular.copy(self.promocodeValue);
          self.tipPerCent= parseFloat(self.bookingDetail.getTip());
          self.taxPerCent= parseFloat(self.bookingDetail.getTax());
          self.valueTax = calcuFee(self.listService, self.taxPerCent, self.promocodeValue);
          self.valueTip = calcuFeeForTip(self.listService, self.tipPerCent);
          self.bookingParamModel = {
            'salon_id': self.bookingDetail.getSalonId(),
            'notes': self.bookingDetail.getNotes(),
            'texture': self.bookingDetail.getTexture(),
            'length': self.bookingDetail.getLenght(),
            'serviceIds': self.listServiceIdModel,
            'stylist_id': self.bookingDetail.getStylistId(),
            'work_at': self.bookingDetail.getDateWorkAt(),
          };
          self.loadingPage = false;
        }
        self.loadingPage = false;
        if(typeof callBack == "function") {
          return callBack(true);
        }
      });
    }
    function getCurrentTimeSalon(timeZoneId) {
      return moment().tz(timeZoneId).format('YYYY/MM/DD HH:mm:ss');
    }
    function calculatePromoCode(totalServicePrice, promocodeValueGet) {
      var priceFromPercent = 0;
      if (isEmpty(promocodeValueGet)) {
        priceFromPercent = parseFloat(0);
      } else {
        var dollarFromPercent = parseFloat(promocodeValueGet  * totalServicePrice / 100);
        priceFromPercent = dollarFromPercent;
      }

      return priceFromPercent;
    }

    function getSalonCurrentTime(salonId, currenStyleList, dayCurrent, timeCurrent, cb) {
       self.salonCurrentTime = [];
        Salons.getListById(salonId).then(function(response) {
        if (!response.error && response.data) {
          self.salonCurrent = new Salon(response.data, response.included);

          // allow all time
          // var listTimeCurrent = self.salonCurrent.getTimeworksFilterByDate(dayCurrent);
          var listTimeCurrent = [
            '00:00', '00:30', '01:00', '01:30',
            '02:00', '02:30', '03:00', '03:30',
            '04:00', '04:30', '05:00', '05:30',
            '06:00', '06:30', '07:00', '07:30',
            '08:00', '08:30', '09:00', '09:30',
            '10:00', '10:30', '11:00', '11:30',
            '12:00', '12:30', '13:00', '13:30',
            '14:00', '14:30', '15:00', '15:30',
            '16:00', '16:30', '17:00', '17:30',
            '18:00', '18:30', '19:00', '19:30',
            '20:00', '20:30', '21:00', '21:30',
            '22:00', '22:30', '23:00', '23:30',
          ];
          // allow all time
          // self.salonCurrentTime = self.currenStyleList.getTimeworksFilterByDayTime(currenStyleList, self.dateCurrent, self.timeCurrent, listTimeCurrent);
          self.salonCurrentTime = listTimeCurrent;
          if(cb !== null && typeof(cb) == 'function') {
            cb();
          }
        }
      });
    }
    function getTaxPercentDefault(cityId) {
      var paramRequest = {
        'page[number]': 1,
        'page[size]': 200
      }
      SalonLocation.getCities(paramRequest).then(function(response) {
        if (!response.error && response.data) {
          angular.forEach(response.data, function(value, key) {
            if (value.attributes.id == cityId) {
              return value.attributes.tax;
            }
          });
        }
      });
    }

    function calcuFee(listService, feePerCent, promocodeValue) {
      if(!checkNumber(feePerCent) || isEmpty(feePerCent)) return 0;
      else {
        var totalServiceMoney = 0;
        promocodeValue = isEmpty(promocodeValue) ? 0: parseFloat(promocodeValue);
        for(var i = 0; i < listService.length; i++) {
          if(checkNumber(listService[i].price)) {
            var value = parseFloat(listService[i].price);
            totalServiceMoney+= value;
          }
        }
        totalServiceMoney-= promocodeValue;
        var feePer = parseFloat(feePerCent);
        var feeMoney = ((totalServiceMoney * feePer)/100);
        return feeMoney;
      }
    }

    // Calcu fee for Tip = totalservice * %tip
    function calcuFeeForTip(listService, feePerCent) {
      if(!checkNumber(feePerCent) || isEmpty(feePerCent)) return 0;
      else {
        var totalServiceMoney = 0;

        for(var i = 0; i < listService.length; i++) {
          if(checkNumber(listService[i].price)) {
            var value = parseFloat(listService[i].price);
            totalServiceMoney+= value;
          }
        }

        var feePer = parseFloat(feePerCent);
        var feeMoney = ((totalServiceMoney * feePer)/100);
        return feeMoney;
      }
    }
  // get booking detail .end

    function getListSalon() {
      self.listSalon = [];
      Salons.getList().then(function(response) {
        if (!response.error && response.data) {
          Salons.drainList(response, params, []).then(function(allSalons) {
            angular.forEach(allSalons, function(value, key) {
              var salon = new Salon(value);
              self.listSalon.push(salon);
            });
          })
        }
      });
    }
    function goToSalonOwnerPage() {
      $localStorage.currentPage = self.page;
      $localStorage.searchKeyword = self.query;
      $state.go('dashboard.owners');
    }
    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function checkListValueContentEmpty(listService) {
      var count = 0;
      for(var i = 0; i <  listService.length; i++) {
        var isEmptyBool = isEmpty(listService[i].price) || !checkNumber(listService[i].price);
        if(isEmptyBool) return true;
        count++;
      }
      count++;
      if(count === listService.length) return false;
    }
    // update booking

    function updateBooking(callBack) {
      if (isEmpty(self.tipPerCent)) {
        self.tipPerCent = 0;
      }
      if (isEmpty(self.taxPerCent)) {
        self.taxPerCent = 0;
      }
      if(!checkListValueContentEmpty(self.listService) && !isEmpty(self.taxPerCent) && !isEmpty(self.tipPerCent)) {

        self.isAvailableToCheckDateTime = false;
        self.tipPerCent = parseFloat(self.tipPerCent); // API required float number.
        self.isEmptyInput = false;
        if(isEmpty(self.timeCurrent)) {
          alert('Please select time working of stylist!');
          return;
        } else if(self.isEmptyInput) {
          alert('Value of input is required !');
          return;
        } else if(self.listService.length === 0) {
          alert('Please select services!');
          return;
        } else {
          self.ValidPromoCode = true;
          if(self.bookingParamModel) {
            self.loadingPage = true;
            self.bookingParamModel.work_at = self.dateCurrent +"T" + self.timeCurrent +':00' + self.timeZoneCurrent;
            self.listServiceIdModel = [];
            if(checkNumber(self.tipPerCent)){
              self.bookingParamModel.tip = self.tipPerCent;
            }
            if(!isEmpty(self.promoCode) && self.isValidPromoCode) {
              self.bookingParamModel.promocode = self.promoCode;
            } else {
              self.bookingParamModel.promocode = '';
            }

            self.listServiceIdModel = addListServiceModel(self.listService, self.listServiceIdModel);
            var i = 0;
            var arrayService = [];
            angular.forEach(self.listService, function(value){
              var itemService = {
                'id': value.id,
                'price': value.price
              }
              arrayService.push(itemService);
            });

            self.bookingParamModel.serviceIds = arrayService;
            self.bookingParamModel.tax = self.taxPerCent;

            // if(!isEmpty(self.promoCode)) {
            //   self.bookingParamModel.promocode = self.promoCode;
            // }
            Bookings.updateBooking(self.bookingDetail.getUserId(), self.bookingCurrentId, self.bookingParamModel).then(function(response) {
              if (!response.error && response.data) {
                self.isValidPromoCode = true;
                self.isEmptyPromoCode = false;
                getBookingDetail(function() {
                datePicker();
                reInitialDateTimePicker();
                  if(callBack !== null && typeof callBack === 'function') {
                    callBack();
                  } else {
                    self.loadingPage = false;
                  }
                });
                return;
              }
              //self.loadingPage = false;
            }, function(error) {
              addService();
              datePicker();
              alert(error.errors[0] ? error.errors[0].detail : (error.errors.detail || 'Please try again later or contact support. Thanks.'));
              reInitialDateTimePicker();
              self.loadingPage = false;
              return;
            });
          }
        }
      } else {
        alert('Cannot update booking because a few of input is invalid or empty!');
      }
    }
    // update booking .end

    function saveCard() {
      self.updateCreditCardLoading = true;
      var isPhone = checkValidatePhone(self.cardInput.phone);
      if (!isPhone || !self.cardInput.name || !self.cardInput.address_line1 || !self.cardInput.address_city || !self.cardInput.address_state || !self.cardInput.phone) {
          self.isShowMessageCardError = true;
      } else {
          self.stripe.createToken(self.cardElement, self.cardInput).then(function (response) {
          if (response['token']) {
            self.isShowCardForm = false;
            var params = {
              'token_stripe': response['token'].id,
              'phone': self.cardInput.phone,
            }

            Users.updateUserCreditCard(self.bookingDetail.getUserId(), params).then(function(response) {

                if (!response.error && response.data) {
                  self.creditCardNumber = response.data.id ? response.data.attributes.last4 : null;
                  self.loadingPage = false;
                  self.cardInput = {};
                  self.updateCreditCardLoading = false;
                  alert('Update card is success.');
                }
              }, function(error) {
                  self.loadingPage = false;
                  self.updateCreditCardLoading = false;
                  alert('Update card is fail. ' + (error.errors.detail || ''));
              });
          }
        });
      }
    }

    function initCard() {
      var elements = self.stripe.elements();
      var style = {
        base: {
          color: '#303238',
          fontSize: '14px',
          lineHeight: '34px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#ccc',
          },
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };
      var cardElement = elements.create('card', {style: style});
      cardElement.mount('#card-element');
      self.cardElement = cardElement;
    }

    function cancelEdit () {
      $state.goReturn() ||
      $state.go('dashboard.bookings', {salonID: self.bookingDetail.getSalonId()});
    }
  }


})(angular);
