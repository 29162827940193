(function(angular) {
	'use strict';

	angular.module('Swivel').factory('Salon', Salon);

	Salon.$inject = ['BaseObject'];
	function Salon(BaseObject) {
		var Salon = function(object, included) {
			var self = angular.extend(this, new BaseObject(object));
			self.getAttributes = getAttributes;
			self.getName = getName;
			self.getAddress = getAddress;
			self.getAddressDetail = getAddressDetail;
			self.getAveragePrice = getAveragePrice;
			self.getInstagramLink = getInstagramLink;
			self.getStatusActionEnableInstagram = getStatusActionEnableInstagram;
			self.getAverageWaitTime = getAverageWaitTime;
			self.getInfo = getInfo;
			self.getSalonRating = getSalonRating;
			self.getSortDescription = getSortDescription;
			self.getInfo = getInfo
			self.getCity = getCity;
			self.getDistrict = getDistrict;
			self.getSalonRating = getSalonRating;
			self.getHairRating = getHairRating;
			self.getStatus = getStatus;
			self.getLocationLatitue = getLocationLatitue;
			self.getLocationLongtitue = getLocationLongtitue;
			self.getTimeworks = getTimeworks;
      self.getTimeworksFilterByDate = getTimeworksFilterByDate;
			self.getStyles = getStyles;
			self.getHairTypes = getHairTypes;
			self.getFirstPhoto = getFirstPhoto;
			self.getPhotos = getPhotos;
			self.getId = getId;
			self.getLocation = getLocation;
			self.getCreatedAt = getCreatedAt;
			self.getRelationships = getRelationships;
			self.getServices = getServices;
			self.listStyleId = [];
			self.listHairTypeId = [];
			self.listServiceId = [];
			self.getCityId = null;
			self.isOpenEarly = isOpenEarly;
			self.isOpenLate = isOpenLate;

			self.getSalonType = getSalonType;
			self.getLicenseType = getLicenseType;
			self.getCancellationPolicy = getCancellationPolicy;
			self.getPhone = getPhone;
			self.getBookingReference = getBookingReference;
			getListId();

			var tempAttributes = self.getAttributes();
			
			if (tempAttributes.photos && tempAttributes.photos.length) {
				// ensure photos are secure
				tempAttributes.photos = tempAttributes.photos.map(function(p) {
					return p.startsWith('https') ? p : p.replace('http', 'https');
				})

				self.set('attributes', tempAttributes);
			}

			function getAttributes() {
				return self.get('attributes');
			}
			function getInstagramLink() {
				return self.getAttributes().instagram_url;
			}
			function getStatusActionEnableInstagram() {
        return self.getAttributes().instagram_enabled;
			}
			function getCreatedAt() {
				return self.getAttributes().createdAt;
			}

			function getId() {
				return self.getAttributes().id;
			}

			function getName() {
				return self.getAttributes().name;
			}

			function getFirstPhoto() {
				return self.getAttributes().photos[0];
			}

			function getPhotos() {
				return self.getAttributes().photos;
			}

			function getAddress() {
				return self.getAttributes().address;
			}

			function getAddressDetail() {
				return self.getAttributes().address_detail;
			}

			function getAveragePrice() {
				return self.getAttributes().average_price;
			}

			function getAverageWaitTime() {
				return self.getAttributes().average_wait_time;
			}

			function getInfo() {
				return self.getAttributes().info;
			}

			function getCity() {
				return self.getAttributes().city_id
			}
			function getDistrict() {
				return self.getAttributes().district_id;
			}
			function getSortDescription() {
				return self.getAttributes().short_description;
			}
			function getSalonRating() {
				return self.getAttributes().salon_rating;
			}

			function getHairRating() {
				return self.getAttributes().hair_rating;
			}

			function getStatus() {
				return self.getAttributes().status;
			}

			function getLocationLatitue() {
				return self.getAttributes().location_lat;
			}

			function getLocationLongtitue() {
				return self.getAttributes().location_long;
			}

			function getTimeworks() {
				return self.getAttributes().timeworks;
			}

			function getSalonType() {
				return self.getAttributes().salon_type;
			}

			function getLicenseType() {
				return self.getAttributes().license_type;
			}

			function getCancellationPolicy() {
				return self.getAttributes().cancellation_policy;
			}

			function getPhone() {
				return self.getAttributes().phone;
			}

			function isOpenEarly() {
				return self.getAttributes().openEarly;
			}

			function isOpenLate() {
				return self.getAttributes().openLate;
			}
			function getLocation() {
				if (included) {
					var city = null;
					angular.forEach(included, function(value, key) {
							if (value.type == 'City' && self.getCityId == value.attributes.id) {
								city = value.attributes.name;
							}
					});
					return city;
				}
			}

			function getRelationships() {
				return self.get('relationships');
			}
      function formatTime(time) {
        time = parseFloat(time);
        var hourCal = Math.floor(time);
        var minuteCal =( time - hourCal)*60;
        var hour = hourCal < 10 ? '0'+String(hourCal) : hourCal;
        var min = minuteCal < 10 ? '0'+String(minuteCal) : minuteCal;
        var workTime = hour+':'+min;
        return workTime;
      }
      function getTimeworksFilterByDate(dayFilter) {
        var timeworks = [];
        angular.forEach(self.getAttributes().timeworks, function(value, key) {
          if (value.weekday == dayFilter) {
            var beginTime = parseFloat(value.begin_time);
            var endTime = parseFloat(value.end_time);
            if(beginTime === 0 && endTime === 0) {
              return;
            }
            var totalTime = parseFloat((endTime - beginTime) * 2);
            var timeIncrease = 0.5;
            while(beginTime < endTime) {
			  var time = formatTime(beginTime);
              timeworks.push(time);
              beginTime+= timeIncrease;
            }
            return timeworks;
          }
        })
        return timeworks;
      }
			function getListId() {
				if (self.getRelationships()['styles']) {
					angular.forEach(self.getRelationships()['styles'], function(value, key) {
						self.listStyleId.push(value.id);
					})
				}

				if (self.getRelationships()['hairtypes']) {
					var listIDs = [];
					angular.forEach(self.getRelationships()['hairtypes'], function(value, key) {
						listIDs.push(value.id);
					})
					angular.forEach(included, function(value, key) {
						if (value.type == 'Hairtype' && listIDs.indexOf(value.attributes.id) != -1) {
							self.listHairTypeId.push(value.attributes.hair_type_id);
						}
					})
				}

				if (self.getRelationships()['services']) {
					angular.forEach(self.getRelationships()['services'], function(value, key) {
						self.listServiceId.push(value.id);
					})
				}

				if (self.getRelationships()['city']) {
						self.getCityId = self.getRelationships()['city'].id;
				}
			}

			function getStyles() {
				if (included) {
					var styles = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Style' && self.listStyleId.indexOf(value.attributes.id) != -1) {
							styles.push(value.attributes.name);
						}
					})
					return styles;
				}
			}

			function getHairTypes() {
				if (included) {
					var hairtypes = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Hairtype' && self.listHairTypeId.indexOf(value.attributes.id) != -1) {
							hairtypes.push(value.attributes.name);
						}
					})
					return hairtypes;
				}
			}

			function getBookingReference() {
				if (included) {
					var bookingRefer = null;
					angular.forEach(included, function(value, key) {
						if (value.type == 'SalonBookingReference') {
							bookingRefer = value.attributes;
						}
					})
					return bookingRefer;
				}
			}

			function getServices() {
				if (included) {
					var services = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Services' && self.listServiceId.indexOf(value.attributes.id) != -1) {
							services.push(value.attributes.name);
						}
					})
					return services;
				}
			}
		}

		return (Salon);
	}
})(angular);
