(function(angular) {
  'use strict';

  angular.module('Swivel').factory('User', User);

  User.$inject = ['BaseObject'];
  function User(BaseObject) {
    var User = function(object, included) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getId = getId;
      self.getAvatar = getAvatar;
      self.getUsername = getUsername;
      self.getFullName = getFullName;
      self.getEmail = getEmail;
      self.getRoles = getRoles;
      self.getListSalonId = getListSalonId;
      self.getHairStyle = getHairStyle;
      self.getRoleName = getRoleName;
      self.included = included;
      self.roleName = {
        salonOwner : "Salon owner",
        admin : "Administrator",
        user : "User"
      };

      var tempAttributes = self.getAttributes();
			
			if (tempAttributes.avatar) {
        // ensure avatar photo is secure
        var a = tempAttributes.avatar;
				tempAttributes.avatar = a.startsWith('https') ? a : a.replace('http', 'https');

				self.set('attributes', tempAttributes);
			}

      function getFullName() {
        return self.getAttributes().fullname;
      }
      function getListSalonId() {
        return self.getAttributes().salons;
      }

      function getAttributes() {
        return self.get('attributes');
      }
      function getId() {
        return self.getAttributes().id;
      }

      function getAvatar() {
        return self.getAttributes().avatar || '/images/no-image.jpg';
      }

      function getUsername() {
        return self.getAttributes().username;
      }

      function getEmail() {
        return self.getAttributes().email;
      }
      function getRelationships() {
        return self.get('relationships');
      }
      function getRoles() {
        return self.getAttributes().roles;
      }

      function checkRoleAdmin() {
        if (getRoles()[0] === 'super_admin') {
          return true;
        } else {
          return false;
        }
      }

      function getRoleName() {
        var listSalonId = getListSalonId();
        if (listSalonId.length > 0) {
          return self.roleName.salonOwner;
        } else {
          if (checkRoleAdmin()) {
            return self.roleName.admin;
          } else {
            return self.roleName.user;
          }

        }

      }

      function getHairStyle() {
        var nameHairStyle = null;
        if (included.length > 0) {
          angular.forEach(self.included, function(value, key) {
              if(value.attributes !== null && value.attributes !== undefined && self.data.relationships !== undefined) {
                if (value.type == 'Hairtype' && value.attributes.id === self.getAttributes().hair_type_id && self.data.relationships.hairtype!== undefined) {
                  nameHairStyle = value.attributes.name;
                }
              }
          });
        }
        return nameHairStyle;
      }
    }

    return (User);
  }
})(angular);
