(function (angular) {
  'use strict';

  angular.module('Swivel').controller('CreateSalonController', CreateSalonController);

  CreateSalonController.$inject = ['SalonLocation', 'SalonCity', 'HairType', 'HairTypes', '$q', '$timeout', 'SalonStyles', 'SalonStyle', 'Upload', 'serverUrl', '$localStorage', 'Salons', '$state', '$scope', 'SalonService', 'SalonServices', 'Settings', 'Setting', '$filter', 'SalonStylists', 'SalonStylist', '$http', '$rootScope'];

  function CreateSalonController(SalonLocation, SalonCity, HairType, HairTypes, $q, $timeout, SalonStyles, SalonStyle, Upload, serverUrl, $localStorage, Salons, $state, $scope, SalonService, SalonServices, Settings, Setting, $filter, SalonStylists, SalonStylist, $http, $rootScope) {
    var self = this;
    self.listCity = [];
    self.listHairType = [];
    self.listStyle = [];
    self.hourOperation = [];
    self.listService = [];
    self.hourStylist = [];
    self.listDateBooking = [];
    self.listBooking = [];
    self.listStylist = [];
    self.listTimeworks = [];
    self.district = null;
    self.city = null;
    self.salonID = null;
    self.serviceID = null;
    self.isInvalidAddress = false;
    self.stylistID = null;
    self.datepicker = null;
    self.serviceIndex = null;
    self.stylistIndex = null;
    self.tmpInput = null;
    self.timeBlockEdit = null;
    self.validateService = null;
    self.uploading = false;
    self.canBeCreate = false;
    self.hairTypeLoading = false;
    self.styleLoading = false;
    self.loadingCreate = false;
    self.isShowCreateService = false;
    self.loadingCreateService = false;
    self.loadingCreateStylist = false;
    self.isShowEditService = false;
    self.isShowCreateStylist = false;
    self.isShowEditStylist = false;
    self.isShowMessageError = false;
    self.isShowMessageErrorService = false;
    self.isShowMessageErrorStylist = false;
    self.isCheckAll = false;
    self.timeZoneId = null;
    self.modelAddressName = null;
    self.taxPercent = 0;
    self.regrexFloat = '^[-]?[0-9]+[\.]?[0-9]+$';
    self.regrexInt = '^[0-9]';
    self.isEmptyInputAddress = false;
    self.isInvalidInputTax = false;
    self.address = null;
    self.district = null;
    self.city = null;
    self.isInvalidAddress = false;
    self.editStyle = editStyle;
    self.saveStyle = saveStyle;
    self.editHairType = editHairType;
    self.saveHairType = saveHairType;
    self.deleteStyle = deleteStyle;
    self.deleteHairType = deleteHairType;
    self.checkValidateAddress = checkValidateAddress;
    self.checkValidateAddress = checkValidateAddress;
    self.eventOpenaddDistrict = eventOpenaddDistrict;
    self.formatTimeWorkWithTimeZone = formatTimeWorkWithTimeZone;
    self.loadListDistrict = loadListDistrict;
    self.eventOpenaddCity = eventOpenaddCity;
    self.eventClickAddNewLocal = eventClickAddNewLocal;
    self.loadDistrict = false;
    self.loadingCreateAddress = false;
    self.typeAddAddress = '';
    self.saveStyleLoading = false;
    self.saveHairTypeLoading = false;
    self.address = null;
    self.editStyleName = null;
    self.editHairTypeName = null;
    self.isCreateTimeOnLocal = true;
    self.currentDateTimeActive = {
      startDate: null,
      endDate: null
    };
    self.input = {
      photos: [],
      description: null,
      sortDescription: null,
      averagePrice: '$',
      salonName: null,
      city: null,
      district: null,
      hairtypes: [],
      styles: [],
      stylists: [],
      instagram_enabled: false,
      instagram_url: '',
      geo: {
        address: null,
        latitude: null,
        longitude: null
      },
      address_detail: null,
      mondayBeginTime: '0',
      mondayEndTime: '0',
      tuesdayBeginTime: '0',
      tuesdayEndTime: '0',
      wednesdayBeginTime: '0',
      wednesdayEndTime: '0',
      thursdayBeginTime: '0',
      thursdayEndTime: '0',
      fridayBeginTime: '0',
      fridayEndTime: '0',
      saturdayBeginTime: '0',
      saturdayEndTime: '0',
      sundayBeginTime: '0',
      sundayEndTime: '0',
      listTimeWorks: [],
      service: {
        name: null,
        price: null,
        description: null
      },
      stylistName: null,
      hairTypeName: null,
      styleName: null,
      open: {
        isEarly: false,
        isLate: false
      },
      type_booking_refer: "1",
      booking_refer_url: ''
    }
    self.uploadFile = uploadFile;
    self.createSalon = createSalon;
    self.createNewStyle = createNewStyle;
    self.createNewHairType = createNewHairType;
    self.removePhoto = removePhoto;
    self.showCreateService = showCreateService;
    self.createNewService = createNewService;
    self.deleteService = deleteService;
    self.showEditService = showEditService;
    self.editService = editService;
    self.createTimeBooking = createTimeBooking;
    self.formatDate = formatDate;
    self.createNewStylist = createNewStylist;
    self.showCreateStylist = showCreateStylist;
    self.editTimework = editTimework;
    self.deleteStylist = deleteStylist;
    self.editStylist = editStylist;
    self.saveEditStylist = saveEditStylist;
    self.unCheckListTimeWork = unCheckListTimeWork;
    self.updateStylistStatus = updateStylistStatus;
    self.updateServiceStatus = updateServiceStatus;
    self.updateSalon = updateSalon;
    self.formatTimeWork = formatTimeWork;
    self.cancelEditService = cancelEditService;
    self.cancelEditStylist = cancelEditStylist;
    self.checkAllTimeBooking = checkAllTimeBooking;
    self.validateTimeOperation = validateTimeOperation;
    self.changeLocation = changeLocation;
    self.allocateLocation = allocateLocation;
    self.isShowReferMessageError = false;
    self.referMessageError = '';
    self.validReferUrl = validReferUrl;
    self.resetBookingReferType = resetBookingReferType;
    init();

    self.sortableStylistsOptions = {
      update: function (e, ui) {
        self.loadingStylistsSort = true;
        self.listStylistBeforeSort = Object.assign([], self.listStylist);
      },
      stop: function (e, ui) {
        if (!self.loadingStylistsSort) {
          return;
        }
        self.loadingStylistsSort = false;
        var ids = self.listStylist.map(function (i) {
          return i.getId();
        });
        self.loadingStylists = true;
        SalonStylists.arrange(self.salonID, {
          ids
        }).then(function (res) {
          self.loadingStylists = false;
        }, function (error) {
          try {
            alert(error.errors[0].detail);
          } catch (err) {
            alert('Something is wrong. Unable to arrange item.');
          }
          self.listStylist = Object.assign([], self.listStylistBeforeSort);
          self.loadingStylists = false;
        });
      }
    };


    self.sortableServicesOptions = {
      update: function (e, ui) {
        self.loadingServicesSort = true;
        self.listServiceBeforeSort = Object.assign([], self.listService);
      },
      stop: function (e, ui) {
        if (!self.loadingServicesSort) {
          return;
        }
        self.loadingServicesSort = false;
        var ids = self.listService.map(function (i) {
          return i.getId();
        });
        self.loadingServices = true;
        SalonServices.arrange(self.salonID, {
          ids
        }).then(function (res) {
          self.loadingServices = false;
        }, function (error) {
          try {
            alert(error.errors[0].detail);
          } catch (err) {
            alert('Something is wrong. Unable to arrange item.');
          }
          self.listService = Object.assign([], self.listServiceBeforeSort);
          self.loadingServices = false;
        });
      }
    };

    function validReferUrl(url) {
      if (self.input.type_booking_refer == '2') {
        if(isEmpty(url)) {
          self.isShowReferMessageError = true;
          self.referMessageError = 'Booking link is required.';
        } else {
          var regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
          console.log('regex.test(url)');
          console.log(regex.test(url));
          if (!regex.test(url)) {
            self.isShowReferMessageError = true;
            self.referMessageError = 'Please enter valid booking link.';
          } else {
            self.isShowReferMessageError = false;
            self.referMessageError = '';
          }
        }
      }
    }
    
    function resetBookingReferType() {
      if (self.input.type_booking_refer == '1') {
        self.input.booking_refer_url = '';
        self.isShowReferMessageError = false;
        self.referMessageError = '';
      }
    }

    function loadListDistrict(idCity) {
      getListDistrict(idCity);
    }

    function closePopup() {
      angular.element('#modal-address').modal('hide');
      self.modelAddressName = null;
    }

    function createNewCity(nameAddress) {
      self.loadingCreateAddress = true;
      var params = {
        name: nameAddress,
        status: 2
      };
      Settings.addCity(params).then(function (response) {
        if (!response.error && response.data) {
          alert("Create city succesfull!");
          self.taxPercent = 0;
          self.input.city = response.data.attributes.id;
          closePopup();
          getListCity();
          getListDistrict(response.data.id);
        }
        self.loadingCreateAddress = false;
      }, function (error) {
        alert("Cannot create city");
        self.loadingCreateAddress = false;
      });
    }

    function createNewDistrict(nameAddress, taxPercent) {
      self.loadingCreateAddress = true;
      var params = {
        city_id: self.input.city.getId(),
        name: nameAddress,
        tax: isEmpty(taxPercent) ? 0 : taxPercent
      };
      Settings.createDistrict(self.input.city.getId(), params).then(function (response) {
        if (!response.error && response.data) {
          alert("Create district succesfull!");
          closePopup();
          getListDistrict(self.input.city.getId());
        }
        self.loadingCreateAddress = false;
      }, function (error) {
        alert("Cannot create city");
        self.loadingCreateAddress = false;
      });
    }

    function eventClickAddNewLocal() {
      if (self.typeAddAddress === 'CT') {
        if (isEmpty(self.modelAddressName)) {
          self.isEmptyInputAddress = true;
          return;
        } else {
          self.isEmptyInputAddress = false;
          self.isInvalidInputTax = false;
          createNewCity(self.modelAddressName);
        }
      } else if (self.typeAddAddress === 'DT') {
        if (isEmpty(self.modelAddressName)) {
          self.isEmptyInputAddress = true;
          return;
        } else if (!checkNumber(self.taxPercent)) {
          self.isInvalidInputTax = true;
          return;
        } else {
          self.isEmptyInputAddress = false;
          self.isInvalidInputTax = false;
          createNewDistrict(self.modelAddressName, self.taxPercent);
        }
      }
    }
    // add district
    function eventOpenaddDistrict() {
      self.typeAddAddress = 'DT';
    }
    // add district .end

    //add city
    function eventOpenaddCity() {
      self.typeAddAddress = 'CT';
    }

    function checkNumber(number) {
      number = String(number);
      if (number.match(/^\d+$/)) {
        return true;
      } else if (number.match(/^\d+\.\d+$/)) {
        return true;
      } else {
        return false;
      }
    }

    function getListCity() {
      self.listCity = [];
      var paramRequest = {
        'page[number]': 1,
        'page[size]': 200
      }
      SalonLocation.getCities(paramRequest).then(function (response) {
        if (!response.error && response.data) {
          angular.forEach(response.data, function (value, key) {
            // if (value.attributes.status == 2) {
            var city = new SalonCity(value);
            self.listCity.push(city);
            if (value.attributes.id == self.input.city) {
              self.input.city = city;
            }
            // }
          })
        }
      });
    }

    function getListDistrict(cityId) {
      self.listDistrict = [];
      self.input.district = '-1';
      var params = {
        'page[number]': self.page,
        'page[size]': 200
      }
      Settings.getDistrict(cityId, params).then(function (response) {
        if (!response.error && response.data) {
          var count = 0;
          angular.forEach(response.data, function (value) {
            self.listDistrict.push(value);
            if (count === 0) {
              self.input.district = value.attributes.id;
            }
            count++;
            if (count === response.data.length) {
              self.loadDistrict = false;
            }
          });
        } else {
          self.loadDistrict = false;
          self.input.district = '-1';
        }
      }, function (error) {
        self.input.district = '-1';
        self.loadDistrict = false;
      });
    }

    function getTimeZoneId(latitude, longitude, callback) {
      let api_key = 'AIzaSyAlI_smBaQInqdE48uZck9qNYV-lxz9Qr4';
      var url = "https://maps.googleapis.com/maps/api/timezone/json?location=" + latitude + "," + longitude + "&timestamp=" + (Math.round((new Date().getTime()) / 1000)).toString() + "&key=" + api_key;
      /*
      $http.get(url).then(function (response) {
        callback(response.data.timeZoneId);
      }); */
      $http.get(url).then(function (response) {
        if (response.status == 'OVER_QUERY_LIMIT' || response.data.status == 'OVER_QUERY_LIMIT' || response.status == 'REQUEST_DENIED' || response.data.status == 'REQUEST_DENIED') {
          var item = tzlookup(latitude, longitude);
          callback(item);
        } else {
          callback(response.data.timeZoneId);
        }
      });
    }

    function getCurrentTimeSalon(timeZoneId) {
      return moment().tz(timeZoneId).format('YYYY/MM/DD HH:mm:ss');
    }

    function getIndexcheckTimeZone() {
      var timeZoneText = moment().format("Z");
      if (timeZoneText.includes("-")) {
        var typeNumber = -1;
      } else {
        var typeNumber = 1;
      }
      return typeNumber;
    }

    function formatDateAllBrowser(date, includeTimeZone) {
      var userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/") + ' ' + splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = new Date(parseDate);
      } else {
        var newDate = date;
        console.log(moment().tz(self.timeZoneId).format(parseDate));
      }
      if (includeTimeZone || isEmpty(includeTimeZone)) {
        var indexTimeZone = getIndexcheckTimeZone();
        var dateFormat = new Date(newDate.getTime() + userTimezoneOffset);
      } else {
        var dateFormat = new Date(newDate.getTime());
      }
      return dateFormat;
    }

    function isEmpty(text) {
      if (text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }

    function checkValidateAddress() {
      setTimeout(function () { }, 100);
      var address = self.address;
      if (isEmpty(address) || address.address_components === null) {
        self.isInvalidAddress = true;
        return false;
      } else {
        var count = 0;
        angular.forEach(address.address_components, function (value) {
          count++;
          for (var i = 0; i < value.types.length; i++) {
            var itemAdress = value.types[i];
            if (itemAdress === 'administrative_area_level_2') {
              self.district = value.long_name;
            }
            if (itemAdress === 'administrative_area_level_1') {
              self.city = value.long_name;
            }
          }
          if (count === address.address_components.length) {
            if (!isEmpty(self.district) && !isEmpty(self.city)) {
              self.isInvalidAddress = false;
              // console.log(self.district);
              // console.log(self.city);
              return false;
            } else {
              self.isInvalidAddress = true;
              return false;
            }
          }
        });
      }
    }

    function getIdByString(listArray, stringContent) {
      var cityDefault = listArray[0];
      angular.forEach(listArray, function (value) {
        var name = value.getName();
        if (name.includes(stringContent)) {
          cityDefault = value;
        }
      }, this);
      return cityDefault;
    }

    function init(callback) {
      var paramRequest = {
        'page[number]': 1,
        'page[size]': 200
      }
      var options = {
        country: "US",
        types: ["geocode"]
      };
      angular.element('.salonSearchAddress').geocomplete(options).bind('geocode:result', function (event, result) {
        var strAddress = result.formatted_address.split(',');
        self.input.geo.address = result.formatted_address.replace(',' + strAddress[strAddress.length - 1], '');
        self.input.geo.latitude = result.geometry.location.lat();
        self.input.geo.longitude = result.geometry.location.lng();
        self.address = result;
        // console.log(self.address);
        if (typeof callback === 'function') {
          callback();
        }
      });

      SalonLocation.getCities(paramRequest).then(function (response) {
        if (!response.error && response.data) {
          var count = 0;
          angular.forEach(response.data, function (value, key) {
            // if (value.attributes.status == 2) {
            var city = new SalonCity(value);
            self.listCity.push(city);
            count++;
            if (count === response.data.length) {
              self.input.city = getIdByString(self.listCity, "New York");
              getListDistrict(self.input.city.getId());
            }
            // }
          })
        }
      });

      HairTypes.getList(paramRequest).then(function (response) {
        if (!response.error && response.data) {
          angular.forEach(response.data, function (value, key) {
            var hairtype = new HairType(value);
            hairtype.set('isEditing', false);
            self.listHairType.push(hairtype);
          })
          $timeout(function () {
            angular.element(".nano-hair-type").nanoScroller();
          })
        }
      });

      SalonStyles.getList(paramRequest).then(function (response) {
        if (!response.error && response.data) {
          angular.forEach(response.data, function (value, key) {
            var style = new SalonStyle(value);
            style.set('isEditing', false);
            self.listStyle.push(style);
          })
          $timeout(function () {
            angular.element(".nano-style").nanoScroller();
          })
        }
      });

      createHourOperation();

      angular.element('#datepicker').datepicker().on('changeDate', function (e) {
        if (e.date) {
          self.isCheckAll = false;
          angular.element('.table-condensed').find('.day').removeClass('highlight-today');
          self.input.stylists = [];
          self.hourStylist = [];
          self.timeBlockEdit = null;
          createStylistTimeWorks(e.date);

          if (self.listBooking.length > 0) {
            var timeEdit = e.date.getFullYear() + '-' + (e.date.getMonth() + 1) + '-' + e.date.getDate();
            angular.forEach(self.listBooking, function (value, key) {
              if (value.date == timeEdit) {
                angular.forEach(value.data, function (value, key) {
                  var time = value.replace(' AM', '').replace(' PM', '') + ':00';
                  if (self.input.stylists.indexOf(value) == -1) {
                    self.input.stylists.push(value);
                    createStylistTimeWorks(time);
                  } else {
                    createStylistTimeWorks(time);
                  }
                })
                if (self.input.stylists.length > 0) {
                  $scope.$broadcast('timeEdit', timeEdit);
                }
                addCheckAllTimeBlock(self.hourStylist, self.input.stylists.length);
              }
            });
          }

        }
      })

      angular.element('#datepicker').datepicker().on('changeMonth', function (e) {
        if (e.date) {
          var currentDateActive = e.date;
          var firstDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth(), 1);
          var lastDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth() + 1, 0);

          self.currentDateTimeActive.startDate = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
          self.currentDateTimeActive.endDate = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();
        }
      })

      angular.element('#datepicker').datepicker().on('changeYear', function (e) {
        if (e.date) {
          var currentDateActive = e.date;
          var firstDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth(), 1);
          var lastDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth() + 1, 0);

          self.currentDateTimeActive.startDate = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
          self.currentDateTimeActive.endDate = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();
        }
      })
    }

    function editStyle(item, index) {
      angular.forEach(self.listStyle, function (value, key) {
        self.listStyle[key].set('isEditing', false);
      })
      self.editStyleName = item.getName();
      if (!item.get('isEditing')) {
        item.set('isEditing', true);
      } else {
        item.set('isEditing', false);
      }
      self.listStyle[index] = item;
    }

    function saveStyle(item, index) {
      if (self.listStyle[index]) {
        if (self.editStyleName != item.getName()) {
          self.saveStyleLoading = true;
          SalonStyles.updateStyle(item.getId(), {
            name: self.editStyleName
          }).then(function (response) {
            if (!response.error) {
              item.set('isEditing', false);
              item.getData().attributes.name = self.editStyleName;
              self.listStyle[index] = item;
            }
            self.saveStyleLoading = false;
          }, function (error) {
            self.saveStyleLoading = false;
          })
        } else {
          item.set('isEditing', false);
          self.listStyle[index] = item;
        }
      }
    }

    function deleteStyle(item, index) {
      if (self.listStyle[index]) {
        if (confirm('Are you sure delete this item?')) {
          SalonStyles.deleteStyle(item.getId()).then(function (response) {
            if (!response.error) {
              self.listStyle.splice(index, 1);
            }
          })
        }
      }
    }

    function editHairType(item, index) {
      angular.forEach(self.listHairType, function (value, key) {
        self.listHairType[key].set('isEditing', false);
      })
      self.editHairTypeName = item.getName();
      if (!item.get('isEditing')) {
        item.set('isEditing', true);
      } else {
        item.set('isEditing', false);
      }
      self.listHairType[index] = item;
    }

    function saveHairType(item, index) {
      if (self.listHairType[index]) {
        if (self.editHairTypeName != item.getName()) {
          self.saveHairTypeLoading = true;
          HairTypes.updateHairType(item.getId(), {
            name: self.editHairTypeName
          }).then(function (response) {
            if (!response.error) {
              item.set('isEditing', false);
              item.getData().attributes.name = self.editHairTypeName;
              self.listHairType[index] = item;
            }
            self.saveHairTypeLoading = false;
          }, function (error) {
            self.saveHairTypeLoading = false;
          })
        } else {
          item.set('isEditing', false);
          self.listHairType[index] = item;
        }
      }
    }

    function deleteHairType(item, index) {
      if (self.listHairType[index]) {
        if (confirm('Are you sure delete this item?')) {
          HairTypes.deleteHairType(item.getId()).then(function (response) {
            if (!response.error) {
              self.listHairType.splice(index, 1);
            }
          })
        }
      }
    }

    function changeLocation() {
      self.isShowMessageError = false;
      self.input.geo.latitude = null;
      self.input.geo.longitude = null;
    }

    function allocateLocation() {
      return $q(function (resolve, reject) {
        if (self.input.geo.address && !(self.input.geo.latitude && self.input.geo.longitude)) {
          $.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + self.input.geo.address, function (data) {
            if (data.status == 'OK' && data.results) {
              self.input.geo.latitude = data.results[0].geometry.location.lat;
              self.input.geo.longitude = data.results[0].geometry.location.lng;
            }
            resolve();
          });
        } else {
          reject();
        }
      });
    }

    function getDateDisable(timeworks) {
      var disableDay = [];
      var listDateDisable = [];
      angular.forEach(timeworks, function (value, key) {
        if (value.status == 1) {
          switch (value.weekday) {
            case 'Monday':
              disableDay.push(1);
              break;
            case 'Tuesday':
              disableDay.push(2);
              break;
            case 'Wednesday':
              disableDay.push(3);
              break;
            case 'Thursday':
              disableDay.push(4);
              break;
            case 'Friday':
              disableDay.push(5);
              break;
            case 'Saturday':
              disableDay.push(6);
              break;
            case 'Sunday':
              disableDay.push(0);
              break;
          }
        }
      })

      if (disableDay.length > 0) {
        var startDate = formatDateAllBrowser(self.currentDateTimeActive.startDate, false);
        var endDate = formatDateAllBrowser(self.currentDateTimeActive.endDate, false);

        // for (var i=startDate.getDate();i<=endDate.getDate();i++) {
        //   var dateDisable = new Date(startDate.getFullYear()+'-'+(startDate.getMonth()+1)+'-'+i);
        //   if (disableDay.indexOf(dateDisable.getDay()) != -1) {
        //     listDateDisable.push(dateDisable.getFullYear()+'-'+(dateDisable.getMonth()+1)+'-'+i);
        //   }
        // }
        var year = startDate.getFullYear();
        for (var i = 1; i <= 12; i++) {
          var month = i == 12 ? i - 1 : i;
          var firstDay = new Date(year, month, 1);
          var lastDay = new Date(year, month, 0);
          for (var j = firstDay.getDate(); j <= lastDay.getDate(); j++) {
            var dateDisable = formatDateAllBrowser(year + '-' + i + '-' + j);
            if (disableDay.indexOf(dateDisable.getDay()) != -1) {
              listDateDisable.push(dateDisable.getFullYear() + '-' + (dateDisable.getMonth() + 1) + '-' + j);
            }
          }
        }
      }
      return listDateDisable;
    }

    function validateTimeOperation(day) {
      switch (day) {
        case 'Mon':
          if (self.input.mondayEndTime != '0' && parseFloat(self.input.mondayBeginTime) >= parseFloat(self.input.mondayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.mondayEndTime = '0';
          }
          if (self.input.mondayBeginTime == '0') {
            self.input.mondayEndTime = '0';
          }
          break;
        case 'Tue':
          if (self.input.tuesdayEndTime != '0' && parseFloat(self.input.tuesdayBeginTime) >= parseFloat(self.input.tuesdayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.tuesdayEndTime = '0';
          }
          if (self.input.tuesdayBeginTime == '0') {
            self.input.tuesdayEndTime = '0';
          }
          break;
        case 'Wed':
          if (self.input.wednesdayEndTime != '0' && parseFloat(self.input.wednesdayBeginTime) >= parseFloat(self.input.wednesdayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.wednesdayEndTime = '0';
          }
          if (self.input.wednesdayBeginTime == '0') {
            self.input.wednesdayEndTime = '0';
          }
          break;
        case 'Thu':
          if (self.input.thursdayEndTime != '0' && parseFloat(self.input.thursdayBeginTime) >= parseFloat(self.input.thursdayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.thursdayEndTime = '0';
          }
          if (self.input.thursdayBeginTime == '0') {
            self.input.thursdayEndTime = '0';
          }
          break;
        case 'Fri':
          if (self.input.fridayEndTime != '0' && parseFloat(self.input.fridayBeginTime) >= parseFloat(self.input.fridayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.fridayEndTime = '0';
          }
          if (self.input.fridayBeginTime == '0') {
            self.input.fridayEndTime = '0';
          }
          break;
        case 'Sat':
          if (self.input.saturdayEndTime != '0' && parseFloat(self.input.saturdayBeginTime) >= parseFloat(self.input.saturdayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.saturdayEndTime = '0';
          }
          if (self.input.saturdayBeginTime == '0') {
            self.input.saturdayEndTime = '0';
          }
          break;
        case 'Sun':
          if (self.input.sundayEndTime != '0' && parseFloat(self.input.sundayBeginTime) >= parseFloat(self.input.sundayEndTime)) {
            alert('Hour open can not be less than hour close');
            self.input.sundayEndTime = '0';
          }
          if (self.input.sundayBeginTime == '0') {
            self.input.sundayEndTime = '0';
          }
          break;
      }
    }

    function checkAllTimeBooking() {
      self.input.stylists = [];
      if (self.isCheckAll) {
        var tmpList = self.hourStylist;
        angular.forEach(self.hourStylist, function (value, key) {
          if (self.input.stylists.indexOf(value.value) == -1 && value.isShow) {
            self.input.stylists.push(value.value);
            tmpList[key].isBooking = true;
          }
        })
        self.hourStylist = angular.copy(tmpList);
      } else {
        angular.forEach(self.hourStylist, function (value, key) {
          if (value.isShow) {
            self.hourStylist[key].isBooking = false;
          }
        })
        self.input.stylists = [];
      }
    }

    function createNewStyle() {
      if (self.input.styleName) {
        self.styleLoading = true;
        SalonStyles.create({
          name: self.input.styleName
        }).then(function (response) {
          if (!response.error && response.data) {
            var style = new SalonStyle(response.data);
            self.input.styleName = null;
            if (self.listStyle.length > 0) {
              self.listStyle.unshift(style);
            } else {
              self.listStyle.push(style);
            }
          }
          self.styleLoading = false;
        }, function (error) {
          self.styleLoading = false;
        });
      }
    }

    function createNewHairType() {
      if (self.input.hairTypeName) {
        self.hairTypeLoading = true;
        HairTypes.create({
          name: self.input.hairTypeName
        }).then(function (response) {
          if (!response.error && response.data) {
            var hairtype = new HairType(response.data);
            self.input.hairTypeName = null;
            if (self.listHairType.length > 0) {
              self.listHairType.unshift(hairtype);
            } else {
              self.listHairType.push(hairtype);
            }
          }
          self.hairTypeLoading = false;
        }, function (error) {
          self.hairTypeLoading = false;
        });
      }
    }

    function createNewStylist() {
      if (self.input.stylistName) {
        self.isCreateTimeOnLocal = false;
        var listWorkTime = [];
        var listTimeLabel = [];
        if (self.listBooking.length > 0) {
          angular.forEach(self.listBooking, function (value, key) {
            angular.forEach(value.data, function (v, k) {
              var time = v.replace(' AM', '').replace(' PM', '') + ':00';
              listWorkTime.push({
                work_at: time,
                status: 2
              });
              listTimeLabel.push({
                time: v.replace(value.date, '').trim(),
                date: value.date
              })
            })
          })
        }
        self.loadingCreateStylist = true;
        SalonStylists.create(self.salonID, {
          name: self.input.stylistName
        }).then(function (response) {
          if (!response.error && response.data) {
            var stylistID = response.data.attributes.id;
            var stylist = new SalonStylist(response.data);
            self.input.stylistName = null;
            if (listWorkTime.length > 0) {
              SalonStylists.addTimeworkForStylist(self.salonID, stylistID, {
                timeworks: listWorkTime
              }).then(function (response) {
                if (!response.error && response.data) {
                  stylist.set('timeworks', listTimeLabel.reverse());
                  if (self.listStylist.length > 0) {
                    self.listStylist.push(stylist);
                  } else {
                    self.listStylist.push(stylist);
                  }
                  self.listDateBooking = [];
                  self.listBooking = [];
                  self.input.stylists = [];
                  reinitDatePicker()
                  angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
                }
                self.loadingCreateStylist = false;
              }, function (error) {
                self.loadingCreateStylist = false;
              })
            } else {
              if (self.listStylist.length > 0) {
                self.listStylist.push(stylist);
              } else {
                self.listStylist.push(stylist);
              }
              self.loadingCreateStylist = false;
            }
          }
        }, function (error) {
          self.loadingCreateStylist = false;
        });
      } else {
        self.isShowMessageErrorStylist = true;
      }
    }

    function deleteStylist(item, index) {
      if (self.listStylist[index]) {
        if (confirm('Are you sure delete this item?')) {
          SalonStylists.deleteStylist(self.salonID, item.getId()).then(function (response) {
            if (!response.error) {
              self.listStylist.splice(index, 1);
              self.listDateBooking = [];
              self.listBooking = [];
              self.input.stylists = [];
              self.timeBlockEdit = null;
              self.input.stylistName = null;
              self.isShowEditStylist = false;
              reinitDatePicker();
              angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
            }
          });
        }
      }
    }

    function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
      var jan = new Date(t.getFullYear(), 0, 1);
      var jul = new Date(t.getFullYear(), 6, 1);
      return Math.min(jan.getTimezoneOffset(), jul.getTimezoneOffset()) == t.getTimezoneOffset();
    }

    function editStylist(item, index) {
      if (self.listStylist[index]) {
        var listDate = [];
        self.isCreateTimeOnLocal = false;
        self.isShowEditStylist = true;
        var currentTimeSalon = new Date(getCurrentTimeSalon(self.timeZoneId));
        var currentTimezone = Math.abs(currentTimeSalon.getTimezoneOffset());
        var timeZone = currentTimezone / 60;
        reinitDatePicker();
        var currentTimeSalon = new Date(getCurrentTimeSalon(self.timeZoneId));
        var paramRequest = {
          'page[number]': 1,
          'page[size]': 200,
          'work_at': '>=' + currentTimeSalon.getFullYear() + '-' + (currentTimeSalon.getMonth() + 1) + '-' + currentTimeSalon.getDate()
        }
        self.listDateBooking = [];
        self.listBooking = [];
        self.listTimeworks = [];
        SalonStylists.getStylistTimeworks(self.salonID, item.getId(), paramRequest).then(function (response) {
          if (!response.error && response.data.length > 0) {
            angular.forEach(response.data, function (value, key) {
              var dateFormat = formatDateAllBrowser(value.attributes.work_at, true);
              // if(isDST(dateFormat)) {
              //   dateFormat.setHours(dateFormat.getHours() - 1);
              // }
              var workDate = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
              var hourType = 'AM';
              var hourCurrentDefault = dateFormat.getHours();
              var hour = hourCurrentDefault < 10 ? '0' + String(hourCurrentDefault) : hourCurrentDefault;
              if (hourCurrentDefault >= 12) {
                hourType = 'PM';
              }
              var min = dateFormat.getMinutes() < 10 ? '0' + String(dateFormat.getMinutes()) : dateFormat.getMinutes();
              self.listTimeworks.push(value);
              var workTime = workDate + ' ' + hour + ':' + min + ' ' + hourType;
              if (listDate.indexOf(workDate) == -1) {
                listDate.push(workDate);
              }
              if (self.input.stylists.indexOf(workTime) == -1) {
                self.input.stylists.push(workTime);
              }
            })
            addHighlightDate(listDate);
            createTimeBooking();
            $timeout(function () {
              angular.element(".time-block-content").nanoScroller();
            })
          }
        });
        angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
        self.input.stylistName = item.getName();
        self.stylistID = item.getId();
        self.stylistIndex = index;
        self.isShowCreateStylist = true;
      }
    }

    function saveEditStylist() {
      if (self.input.stylistName) {
        self.isCreateTimeOnLocal = false;
        var listWorkTime = [];
        var listTimeLabel = [];
        var listWorkTimeFormat = [];
        if (self.listBooking.length > 0) {
          angular.forEach(self.listBooking, function (value, key) {
            angular.forEach(value.data, function (v, k) {
              var time = v.replace(' AM', '').replace(' PM', '') + ':00';
              listWorkTime.push({
                work_at: time,
                status: 2
              });
              listTimeLabel.push({
                time: v.replace(value.date, '').trim(),
                date: value.date
              })
            })
          })
        }

        self.loadingCreateStylist = true;
        SalonStylists.updateStylist(self.salonID, self.stylistID, {
          name: self.input.stylistName
        }).then(function (response) {
          if (!response.error && response.data) {
            var stylist = new SalonStylist(response.data);
            self.input.stylistName = null;
            if (listWorkTime.length > 0) {
              SalonStylists.addTimeworkForStylist(self.salonID, self.stylistID, {
                timeworks: listWorkTime
              }).then(function (response) {
                if (!response.error && response.data) {
                  stylist.set('timeworks', listTimeLabel.reverse());
                  var deleteTimeworkIDs = [];
                  var currentTimeworks = [];
                  angular.forEach(listWorkTime, function (value, key) {
                    currentTimeworks.push(value.work_at);
                  });
                  var i = 0;
                  var numOfElementTimework = self.listTimeworks.length;
                  if (numOfElementTimework > 0) {
                    angular.forEach(self.listTimeworks, function (value, key) {
                      var fulltime = formatDateAllBrowser(value.attributes.work_at, true);
                      // if(isDST(fulltime)) {
                      //   fulltime.setHours(fulltime.getHours() - 1);
                      // }
                      var hours = fulltime.getHours() < 10 ? '0' + String(fulltime.getHours()) : fulltime.getHours();
                      var min = fulltime.getMinutes() < 10 ? '0' + String(fulltime.getMinutes()) : fulltime.getMinutes();
                      var sec = fulltime.getSeconds() < 10 ? '0' + String(fulltime.getSeconds()) : fulltime.getSeconds();
                      var time = fulltime.getFullYear() + '-' + (fulltime.getMonth() + 1) + '-' + fulltime.getDate() + ' ' + hours + ':' + min + ':' + sec;
                      i += 1;
                      if (currentTimeworks.indexOf(time) == -1) {
                        deleteTimeworkIDs.push(parseInt(value.attributes.id));
                      }
                      if (i === numOfElementTimework) {
                        if (deleteTimeworkIDs.length > 0) {
                          var listTimeDeleting = {
                            'timeworkIds': deleteTimeworkIDs
                          };
                          SalonStylists.deleteTimeworkForStylist(self.salonID, self.stylistID, listTimeDeleting).then(function (response) {
                            if (i === numOfElementTimework) {
                              self.loadingCreateStylist = false;
                            }
                          }, function (error) {
                            if (i === numOfElementTimework) {
                              self.loadingCreateStylist = false;
                            }
                          });
                        } else {
                          self.loadingCreateStylist = false;
                        }
                      }
                    });
                  } else {
                    self.loadingCreateStylist = false;
                  }
                  // if (deleteTimeworkIDs.length > 0) {
                  //   SalonStylists.deleteTimeworkForStylist(self.salonID, self.stylistID, {timeworkIds: deleteTimeworkIDs});
                  // }
                  self.listStylist[self.stylistIndex] = stylist;
                  self.listDateBooking = [];
                  self.listBooking = [];
                  self.input.stylists = [];
                  self.listTimeworks = [];
                  self.timeBlockEdit = null;
                  self.isShowEditStylist = false;
                  reinitDatePicker();
                  angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
                }
                self.loadingCreateStylist = false;
              }, function (error) {
                self.loadingCreateStylist = false;
              })
            } else {
              var deleteTimeworkIDs = [];
              var dateCurrent = getDateFormate($rootScope.currentDateSelected);
              angular.forEach(self.listTimeworks, function (value) {
                var dateFormat = formatDateAllBrowser(value.attributes.work_at, false);
                var dateCheck = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
                if (dateCheck === dateCurrent) {
                  deleteTimeworkIDs.push(parseInt(value.attributes.id));
                }
              });
              if (deleteTimeworkIDs.length > 0) {
                SalonStylists.deleteTimeworkForStylist(self.salonID, self.stylistID, {
                  timeworkIds: deleteTimeworkIDs
                });
              }
              reinitDatePicker();
              angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
              self.isShowEditStylist = false;
              self.listStylist[self.stylistIndex] = stylist;
              self.loadingCreateStylist = false;
            }
          }
        }, function (error) {
          self.loadingCreateStylist = false;
        })
      } else {
        self.isShowMessageErrorStylist = true;
      }
    }

    function getDateFormate(dateTime) {
      if (!isEmpty(dateTime)) {
        var dateFormat = formatDateAllBrowser(dateTime.replace(' AM', '').replace(' PM', ''), false);
        var date = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
        return date;
      } else {
        return null;
      }
    }

    function convertHourByTimeZone(hourTime, timeZone) {
      var hourResult;

      var timeZoneText = moment().format("Z");

      if (timeZoneText.includes("-")) {
        hourResult = hourTime + timeZone;
      } else {
        hourResult = hourTime - timeZone;
      }

      return hourResult;
    }

    function formatTimeWorkWithTimeZone(time) {
      var currentTimeSalon = new Date(getCurrentTimeSalon(self.timeZoneId));
      var currentTimezone = Math.abs(currentTimeSalon.getTimezoneOffset());
      var timeZone = currentTimezone / 60;
      var timeArray = time.split(' ')[1];
      var explodeTime = timeArray.split(':');
      var hourType = 'AM';
      var hourTime = parseInt(explodeTime[0]);
      // if(isCreateOnLocal) {
      //   hourTime =   parseInt(explodeTime[0]) - timeZone;
      // }

      //hourTime = convertHourByTimeZone(parseInt(explodeTime[0]), timeZone);

      if (hourTime >= 12) {
        hourType = 'PM';
      }
      var hour = hourTime < 13 ? hourTime : (hourTime - 12);
      hour = hour < 10 ? '0' + String(hour) : hour;
      var min = parseInt(explodeTime[1]) < 10 ? '0' + parseInt(explodeTime[1]) : explodeTime[1];
      return hour + ':' + min + ' ' + hourType;
    }

    function editTimework(item) {
      self.hourStylist = [];
      self.input.stylists = [];
      self.isCheckAll = false;
      $scope.$broadcast('timeEdit', item.date);
      angular.forEach(item.data, function (value, key) {
        var time = value.replace(' AM', '').replace(' PM', '') + ':00';
        if (self.input.stylists.indexOf(value) == -1) {
          self.input.stylists.push(value);
          createStylistTimeWorks(time);
        } else {
          createStylistTimeWorks(time);
        }
      })
      addCheckAllTimeBlock(self.hourStylist, self.input.stylists.length);
      addActiveDate(item.date);
    }

    $scope.$on('timeEdit', function (event, data) {
      self.timeBlockEdit = data;
    })

    function createTimeBooking() {
      self.isCreateTimeOnLocal = true;
      if (self.input.stylists.length > 0) {
        var timeEdit = [];
        angular.forEach(self.input.stylists, function (value, key) {
          var dateFormat = formatDateAllBrowser(value.replace(' AM', '').replace(' PM', ''), false);
          var date = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
          if (!self.listDateBooking[date]) {
            self.listDateBooking[date] = [];
          }
          if (self.timeBlockEdit) {
            if (self.timeBlockEdit == date) {
              if (self.listDateBooking[self.timeBlockEdit].indexOf(value) == -1) {
                timeEdit.push(value);
                self.listDateBooking[self.timeBlockEdit].push(value);
              } else {
                timeEdit.push(value);
              }
            }
          } else {
            if (self.listDateBooking[date].indexOf(value) == -1) {
              self.listDateBooking[date].push(value)
            }
          }
        });
        if (timeEdit.length > 0 && self.timeBlockEdit) {
          self.listDateBooking[self.timeBlockEdit] = timeEdit;
        }
        self.listBooking = transformHashToArray(self.listDateBooking);
      } else {
        var dateFormat = formatDateAllBrowser($rootScope.currentDateSelected.replace(' AM', '').replace(' PM', ''), false);
        var date = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
        angular.forEach(self.listBooking, function (value, index) {
          angular.forEach(value.data, function (v1) {
            var bookingArray = self.listBooking[index];
            if (v1 === $rootScope.currentDateSelected && !isEmpty(bookingArray)) {
              if (bookingArray.date === date) {
                self.listBooking.splice(index, 1);
              }
            }
          });
        });
        if (self.listDateBooking[date].length > 0) {
          delete self.listDateBooking[date];
        }
        //$rootScope.currentDateSelected = '';
      }
      addTimeBookingActive();
      angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
      $timeout(function () {
        angular.element(".time-block-content").nanoScroller();
      })
      self.input.stylists = [];
      self.hourStylist = [];
      self.timeBlockEdit = null;
      self.isCheckAll = false;
    }

    function addTimeBookingActive() {
      var listTimeActive = [];
      if (self.listBooking.length > 0) {
        angular.forEach(self.listBooking, function (value, key) {
          listTimeActive.push(value.date);
        })
        addHighlightDate(listTimeActive);
      }
    }

    function addHighlightDate(listDate) {
      angular.element('#datepicker').datepicker('remove');
      angular.element('#datepicker').datepicker({
        format: 'yyyy-mm-dd',
        startDate: new Date(getCurrentTimeSalon(self.timeZoneId)),
        maxViewMode: 'days',
        beforeShowDay: function (date) {
          var dateFormat = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          if (listDate.indexOf(dateFormat) != -1) {
            return {
              classes: 'highlighted'
            };
          }
        }
      });
    }

    function addActiveDate(date) {
      angular.element('#datepicker').datepicker('setDate', formatDateAllBrowser(date));
      // angular.forEach(angular.element('.table-condensed .day'), function(value, key) {
      //   var element = angular.element(value);
      //   var dateFormat = new Date(date);
      //   element.removeClass('active');
      //   if (element.text() == dateFormat.getDate() && !element.hasClass('old') && !element.hasClass('new')) {
      //     element.addClass('active');
      //   }
      // })
    }

    function reinitDatePicker() {
      angular.element('#datepicker').datepicker('remove');
      angular.element('#datepicker').datepicker({
        format: 'yyyy-mm-dd',
        startDate: new Date(getCurrentTimeSalon(self.timeZoneId)),
        maxViewMode: 'days',
        beforeShowDay: function (date) {
          var dateFormat = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          var currentDate = (new Date(getCurrentTimeSalon(self.timeZoneId))).getFullYear() + '-' + ((new Date(getCurrentTimeSalon(self.timeZoneId))).getMonth() + 1) + '-' + (new Date(getCurrentTimeSalon(self.timeZoneId))).getDate();
          if (dateFormat == currentDate) {
            var dateDisable = getDateDisable(self.input.listTimeWorks);
            if (dateDisable.length > 0 && dateDisable.indexOf(currentDate) == -1) {
              createStylistTimeWorks(currentDate);
              return {
                classes: 'highlight-today'
              };
            }
            if (dateDisable.length == 0) {
              createStylistTimeWorks(currentDate);
              return {
                classes: 'highlight-today'
              };
            }
          }
        }
      });
      self.hourStylist = [];
    }

    function formatTimeWork(time, date) {
      var format = time.replace(date, '').trim();
      var explodeTime = format.split(':');
      var timeFormat = parseInt(explodeTime[0]) > 12 ? (parseInt(explodeTime[0]) - 12) : parseInt(explodeTime[0]);
      return timeFormat + ':' + explodeTime[1];
    }

    function formatDate(date) {
      return $filter('date')(formatDateAllBrowser(date), 'EEEE-dd MMM, yyyy');
    }

    function transformHashToArray(hash) {
      var out = [];
      for (var date in hash) {
        out.push({
          date: date,
          data: hash[date]
        });
      }
      return out;
    }

    function addCheckAllTimeBlock(timeOperation, timeLengthSelected) {
      var i = 0;
      if (timeOperation.length > 0) {
        angular.forEach(timeOperation, function (value, key) {
          if (value.isShow) {
            i++;
          }
        })
        if (timeLengthSelected == i) {
          self.isCheckAll = true;
        } else {
          self.isCheckAll = false;
        }
      }
    }

    function unCheckListTimeWork() {
      self.input.stylists = [];
      self.hourStylist = [];
      self.isCheckAll = false;
      angular.element('.table-condensed').find('.day').each(function (key, value) {
        angular.element(value).removeClass('active')
      })
    }

    function updateStylistStatus(item, index) {
      if (self.listStylist[index]) {
        var status = item.getStatus() == 1 ? 2 : 1;
        SalonStylists.updateStylistStatus(self.salonID, item.getId(), {
          status: status
        }).then(function (response) {
          if (!response.error) {
            self.listStylist[index].getAttributes().status = status;
          }
        });
      }
    }

    function cancelEditStylist() {
      self.listDateBooking = [];
      self.listBooking = [];
      self.input.stylists = [];
      self.timeBlockEdit = null;
      self.input.stylistName = null;
      self.isShowEditStylist = false;
      showCreateStylist();
      reinitDatePicker();
      angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
    }

    function isShowTimeRangeWork(dateFormat, hours, min) {
      var dayOfWeek = dateFormat.getDay();
      var timeValue = 0;
      if (min == 30) {
        if (hours == 0) {
          timeValue = 0.5;
        } else {
          timeValue = parseFloat(String(hours) + '.5');
        }
      } else {
        timeValue = hours;
      }
      switch (dayOfWeek) {
        case 0:
          if (self.input.sundayBeginTime != '0' && self.input.sundayEndTime != '0') {
            if (parseFloat(self.input.sundayBeginTime) <= timeValue && parseFloat(self.input.sundayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        case 1:
          if (self.input.mondayBeginTime != '0' && self.input.mondayEndTime != '0') {
            if (parseFloat(self.input.mondayBeginTime) <= timeValue && parseFloat(self.input.mondayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
        case 2:
          if (self.input.tuesdayBeginTime != '0' && self.input.tuesdayEndTime != '0') {
            if (parseFloat(self.input.tuesdayBeginTime) <= timeValue && parseFloat(self.input.tuesdayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
        case 3:
          if (self.input.wednesdayBeginTime != '0' && self.input.wednesdayBeginTime != '0') {
            if (parseFloat(self.input.wednesdayBeginTime) <= timeValue && parseFloat(self.input.wednesdayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
        case 4:
          if (self.input.thursdayBeginTime != '0' && self.input.thursdayEndTime != '0') {
            if (parseFloat(self.input.thursdayBeginTime) <= timeValue && parseFloat(self.input.thursdayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
        case 5:
          if (self.input.fridayBeginTime != '0' && self.input.fridayEndTime != '0') {
            if (parseFloat(self.input.fridayBeginTime) <= timeValue && parseFloat(self.input.fridayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
        case 6:
          if (self.input.saturdayBeginTime != '0' && self.input.saturdayEndTime != '0') {
            if (parseFloat(self.input.saturdayBeginTime) <= timeValue && parseFloat(self.input.saturdayEndTime) > timeValue) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
          break;
      }
    }

    function createStylistTimeWorks(date) {
      var min = 0;
      var hours = 0;
      var hourType = 'AM';
      var dateFormat = formatDateAllBrowser(date, false);
      var currentDate = dateFormat.getFullYear() + '-' + (dateFormat.getMonth() + 1) + '-' + dateFormat.getDate();
      for (var i = 0; i <= 47; i++) {
        if (hours >= 12) {
          hourType = 'PM';
        }
        if (min == 30) {
          var time = ((hours < 10) ? '0' + String(hours) : hours) + ':' + min + ':00';
          var label = ((hours < 10) ? '0' + String(hours) : hours) + ':' + min;
          var hourFormat = hours > 12 ? hours - 12 : hours;
          var timeFormat = ((hourFormat < 10) ? '0' + String(hourFormat) : hourFormat) + ':' + min;
          var currentTimeSalon = new Date(getCurrentTimeSalon(self.timeZoneId));
          var currentTimezone = Math.abs(currentTimeSalon.getTimezoneOffset());
          var timeZone = currentTimezone / 60;
          var hourTime = dateFormat.getHours();

          //hourTime =  convertHourByTimeZone(parseInt(hourTime), timeZone);

          if (self.hourStylist.length == 47) {
            if (!self.hourStylist[i - 1].isShow) {
              self.hourStylist[i - 1].isShow = isShowTimeRangeWork(dateFormat, hours, min);
            }
            if (!self.hourStylist[i - 1].isBooking) {
              self.hourStylist[i - 1].isBooking = (hourTime == hours && dateFormat.getMinutes() == 30) ? true : false;
            }
          } else {
            self.hourStylist.push({
              value: currentDate + ' ' + label + ' ' + hourType,
              label: timeFormat,
              time: label + ' ' + hourType,
              date: currentDate,
              hourType: hourType,
              isBooking: (hourTime == hours && dateFormat.getMinutes() == 30) ? true : false,
              isDisabled: false,
              isShow: isShowTimeRangeWork(dateFormat, hours, min)
            })
          }
        }
        if (min == 60) {
          hours++;
          min = 0;
          if (hours >= 12) {
            hourType = 'PM';
          }
          var time = ((hours < 10) ? '0' + String(hours) : hours) + ':' + min + '0' + ':00';
          var label = ((hours < 10) ? '0' + String(hours) : hours) + ':' + min + '0';
          var hourFormat = hours > 12 ? hours - 12 : hours;
          var timeFormat = ((hourFormat < 10) ? '0' + String(hourFormat) : hourFormat) + ':' + min + '0';
          if (self.hourStylist.length == 47) {
            if (!self.hourStylist[i - 1].isShow) {
              self.hourStylist[i - 1].isShow = isShowTimeRangeWork(dateFormat, hours, min);
            }
            if (!self.hourStylist[i - 1].isBooking) {
              self.hourStylist[i - 1].isBooking = (hourTime == hours && dateFormat.getMinutes() == 0) ? true : false;
            }
          } else {
            self.hourStylist.push({
              value: currentDate + ' ' + label + ' ' + hourType,
              label: timeFormat,
              time: label + ' ' + hourType,
              date: currentDate,
              hourType: hourType,
              isBooking: (hourTime == hours && dateFormat.getMinutes() == 0) ? true : false,
              isDisabled: false,
              isShow: isShowTimeRangeWork(dateFormat, hours, min)
            })
          }
        }
        min = min + 30;
      }
      $timeout(function () {
        angular.element(".nano-time-works").nanoScroller();
      })
    }

    function createHourOperation() {
      var min = 0;
      var hours = 0;
      var hourType = 'AM';
      for (var i = 0; i <= 47; i++) {
        if (min == 30) {
          var timeValue = 0;
          var timeFormat = 0;
          if (hours > 12) {
            timeFormat = hours - 12;
          } else {
            timeFormat = hours;
          }
          if (hours >= 12) {
            hourType = 'PM';
          }
          if (hours == 0) {
            timeValue = 0.5;
          } else {
            timeValue = parseFloat(String(hours) + '.5')
          }
          self.hourOperation.push({
            weekday: null,
            begin_time: timeValue,
            end_time: timeValue,
            status: 1,
            label: timeFormat + ':' + min + ' ' + hourType
          })
        }
        if (min == 60) {
          hours++;
          min = 0;
          var timeFormat = 0;
          if (hours > 12) {
            timeFormat = hours - 12;
          } else {
            timeFormat = hours;
          }
          if (hours >= 12) {
            hourType = 'PM';
          }
          self.hourOperation.push({
            weekday: null,
            begin_time: hours.toFixed(1),
            end_time: hours.toFixed(1),
            status: 1,
            label: timeFormat + ':' + min + '0 ' + hourType
          })
        }
        min = min + 30;
      }
    }

    function removePhoto(index) {
      self.input.photos.splice(index, 1);
    }

    function checkNumber(number) {
      number = String(number);
      if (number.match(/^\d+$/)) {
        return true;
      } else if (number.match(/^\d+\.\d+$/)) {
        return true;
      } else {
        return false;
      }
    }

    function createNewService() {
      if (self.input.service.name && self.input.service.price) {
        if (!checkNumber(self.input.service.price)) {
          self.validateService = 'Price must be a number.';
          self.isShowMessageErrorService = true;
          return;
        }
        if (parseFloat(self.input.service.price) < 0) {
          self.isShowMessageErrorService = true;
          self.validateService = 'Price value must be greater than zero (0).';
          return;
        }
        self.loadingCreateService = true;
        var params = {
          name: self.input.service.name,
          description: self.input.service.description ? self.input.service.description : "",
          price: parseFloat(self.input.service.price)
        }
        SalonServices.create(self.salonID, params).then(function (response) {
          if (!response.error && response.data) {
            var service = new SalonService(response.data);
            self.listService.push(service);
            resetInput();
            showCreateService();
          }
          self.validateService = null;
          self.loadingCreateService = false;
        }, function (error) {
          self.loadingCreateService = false;
        });
      } else {
        self.isShowMessageErrorService = true;
      }
    }

    function deleteService(index, serviceID) {
      if (self.listService[index]) {
        if (confirm('Are you sure delete this item?')) {
          SalonServices.deleteService(self.salonID, serviceID).then(function (response) {
            self.listService.splice(index, 1);
          }, function (error) {

          })
        }
      }
    }

    function editService() {
      if (self.input.service.name && self.input.service.price) {
        if (!checkNumber(self.input.service.price)) {
          self.validateService = 'Price must be a number.';
          self.isShowMessageErrorService = true;
          return;
        }
        if (parseFloat(self.input.service.price) < 0) {
          self.isShowMessageErrorService = true;
          self.validateService = 'Price value must be greater than zero (0).';
          return;
        }
        self.loadingCreateService = true;
        var params = {
          name: self.input.service.name,
          description: self.input.service.description ? self.input.service.description : "",
          price: parseFloat(self.input.service.price)
        }
        SalonServices.updateService(self.salonID, self.serviceID, params).then(function (response) {
          if (!response.error && response.data) {
            var service = new SalonService(response.data);
            self.listService[self.serviceIndex] = service;
            self.serviceIndex = null;
            self.isShowEditService = false;
            resetInput();
            showCreateService();
          }
          self.loadingCreateService = false;
          self.validateService = null;
        }, function (error) {
          self.loadingCreateService = false;
        });
      } else {
        self.isShowMessageErrorService = true;
      }
    }

    function updateServiceStatus(item, index) {
      if (self.listService[index]) {
        var status = item.getStatus() == 1 ? 2 : 1;
        SalonServices.updateServiceStatus(self.salonID, item.getId(), {
          status: status
        }).then(function (response) {
          if (!response.error) {
            self.listService[index].getAttributes().status = status;
          }
        })
      }
    }


    function cancelEditService() {
      self.serviceIndex = null;
      self.isShowEditService = false;
      self.isShowMessageErrorService = false;
      resetInput();
      showCreateService();
    }

    function showEditService(index, service) {
      if (self.listService[index]) {
        self.isShowCreateService = true;
        self.isShowEditService = true;
        self.input.service = {
          name: service.getName(),
          price: parseInt(service.getPrice()),
          description: service.getDescription()
        }
        self.serviceID = service.getId();
        self.serviceIndex = index;
      }
    }

    function showCreateService() {
      if (!self.salonID) {
        alert('Please create a salon first.');
      } else {
        if (self.isShowCreateService) {
          self.isShowCreateService = false;
        } else {
          self.isShowCreateService = true;
        }
      }
    }

    function showCreateStylist() {
      if (!self.salonID) {
        alert('Please create a salon first.');
      } else {
        if (self.isShowCreateStylist) {
          self.isShowCreateStylist = false;
        } else {
          self.isShowCreateStylist = true;
        }
      }
    }

    function resetInput() {
      self.input.service = {
        name: null,
        price: null,
        description: null
      }
    }

    function getListService() {
      SalonServices.getList(4, {
        'page[number]': 1
      }).then(function (response) {
        angular.forEach(response.data, function (value, key) {
          var service = new SalonService(value);
          self.listService.push(service);

        })
      });
    }

    function uploadFile(file) {
      if (file) {
        if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) == -1) {
          alert('File format is not valid!');
          return;
        }
        if (file.size > 10 * 1024 * 1024) {
          alert('File size is too large\nFile size limit 10M.');
          return;
        }
        self.uploading = true;
        Upload.upload({
          url: serverUrl + 'upload/photos',
          data: {
            image: file
          }
        }).progress(function (event) {

        }).success(function (response, status, headers, config) {
          self.uploading = false;
          self.input.photos.push(response.data.link)
        }).error(function (response, status, headers, config) {
          self.uploading = false;
        });
      }
    }

    function validateTime(begin, end) {
      if ((begin && end) && (begin != "0" && end != "0")) {
        return true;
      } else {
        return false;
      }
    }

    function createTimeWorks() {
      if (validateTime(self.input.mondayBeginTime, self.input.mondayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Monday",
          begin_time: self.input.mondayBeginTime,
          end_time: self.input.mondayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Monday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.tuesdayBeginTime, self.input.tuesdayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Tuesday",
          begin_time: self.input.tuesdayBeginTime,
          end_time: self.input.tuesdayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Tuesday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.wednesdayBeginTime, self.input.wednesdayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Wednesday",
          begin_time: self.input.wednesdayBeginTime,
          end_time: self.input.wednesdayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Wednesday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.thursdayBeginTime, self.input.thursdayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Thursday",
          begin_time: self.input.thursdayBeginTime,
          end_time: self.input.thursdayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Thursday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.fridayBeginTime, self.input.fridayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Friday",
          begin_time: self.input.fridayBeginTime,
          end_time: self.input.fridayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Friday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.saturdayBeginTime, self.input.saturdayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Saturday",
          begin_time: self.input.saturdayBeginTime,
          end_time: self.input.saturdayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Saturday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }

      if (validateTime(self.input.sundayBeginTime, self.input.sundayEndTime)) {
        self.input.listTimeWorks.push({
          weekday: "Sunday",
          begin_time: self.input.sundayBeginTime,
          end_time: self.input.sundayEndTime,
          status: 2,
        })
      } else {
        self.input.listTimeWorks.push({
          weekday: "Sunday",
          begin_time: "0.0",
          end_time: "0.0",
          status: 1,
        })
      }
    }

    function updateSalon() {
      if (self.input.description && self.input.salonName && (self.input.geo.latitude && self.input.geo.longitude) && (self.input.hairtypes.length > 0 && self.input.styles.length > 0) && self.input.photos.length > 0 && self.input.sortDescription && self.input.district > -1) {
        self.input.listTimeWorks = [];
        createTimeWorks();
        var params = {
          name: self.input.salonName,
          address: self.input.geo.address,
          average_price: self.input.averagePrice,
          info: self.input.description,
          city_id: self.input.city.getId(),
          status: 2,
          photos: self.input.photos,
          location_lat: self.input.geo.latitude,
          location_long: self.input.geo.longitude,
          timeworks: self.input.listTimeWorks,
          short_description: self.input.sortDescription
        }
        self.loadingCreate = true;
        getTimeZoneId(self.input.geo.latitude, self.input.geo.longitude, function (timeZone) {
          self.timeZoneId = timeZone;
          Salons.updateSalon(self.salonID, params).then(function (response) {
            if (!response.error && response.data) {
              self.salonID = response.data.attributes.id;
              HairTypes.addHairTypeToSalon(self.salonID, {
                hairtypeIds: self.input.hairtypes
              });
              SalonStyles.addStyleToSalon(self.salonID, {
                styleIds: self.input.styles
              });
              self.loadingCreate = false;
              reinitDatePicker();
              angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
            }
          }, function (error) {
            self.loadingCreate = false;
          });
        });
      } else {
        self.isShowMessageError = true;
      }
    }

    function initDatePicker(currentTimeSalon) {
      var currentDateActive = new Date(currentTimeSalon);
      var firstDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth(), 1);
      var lastDay = new Date(currentDateActive.getFullYear(), currentDateActive.getMonth() + 1, 0);

      self.currentDateTimeActive.startDate = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
      self.currentDateTimeActive.endDate = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();

      angular.element('#datepicker').datepicker({
        format: 'yyyy-mm-dd',
        startDate: new Date(currentTimeSalon),
        maxViewMode: 'days',
        beforeShowDay: function (date) {
          var dateFormat = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          var currentDate = (new Date(currentTimeSalon)).getFullYear() + '-' + ((new Date(currentTimeSalon)).getMonth() + 1) + '-' + (new Date(currentTimeSalon)).getDate();
          if (dateFormat == currentDate) {
            createStylistTimeWorks(currentDate);
            return {
              classes: 'highlight-today'
            };
          }
        }
      });
    }

    function checkValidateFormateLink(link) {
      if (isEmpty(link) && self.input.instagram_enabled) {
        return false;
      } else if (isEmpty(link) && !self.input.instagram_enabled) {
        return true;
      } else if (!isEmpty(link) && self.input.instagram_enabled) {
        var re_weburl = new RegExp(
          "^" +
          // protocol identifier
          "(?:(?:https?|ftp)://)" +
          // user:pass authentication
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host name
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          // TLD may end with dot
          "\\.?" +
          ")" +
          // port number
          "(?::\\d{2,5})?" +
          // resource path
          "(?:[/?#]\\S*)?" +
          "$", "i"
        );
        return re_weburl.test(link);
      }
      return true;
    }

    function createSalon() {
      allocateLocation().then(function () { }).finally(doCreateSalon);
    }

    function doCreateSalon() {
      if (self.input.description && self.input.salonName && (self.input.geo.latitude && self.input.geo.longitude) && (self.input.hairtypes.length > 0 && self.input.styles.length > 0) && self.input.photos.length > 0 && self.input.sortDescription && checkValidateFormateLink(self.input.instagram_url) && !self.isShowReferMessageError) {
        self.input.listTimeWorks = [];
        createTimeWorks();
        var params = {
          name: self.input.salonName,
          address: self.input.geo.address,
          address_detail: self.input.address_detail,
          average_price: self.input.averagePrice,
          info: self.input.description,
          short_description: self.input.sortDescription,
          city_id: self.input.city.getId(),
          district_id: self.input.district,
          status: 2,
          photos: self.input.photos,
          location_lat: self.input.geo.latitude,
          location_long: self.input.geo.longitude,
          timeworks: self.input.listTimeWorks,
          open_early: self.input.open.isEarly ? 2 : 1,
          open_late: self.input.open.isLate ? 2 : 1,
          instagram_enabled: self.input.instagram_enabled ? 1 : 0,
          type_booking_refer: self.input.type_booking_refer,
          booking_refer_url: self.input.booking_refer_url
        };
        if (self.input.instagram_enabled) {
          params.instagram_url = self.input.instagram_url
        }
        self.isInvalidInstagram = false;
        self.loadingCreate = true;
        getTimeZoneId(self.input.geo.latitude, self.input.geo.longitude, function (timeZone) {
          self.timeZoneId = timeZone;
          initDatePicker(getCurrentTimeSalon(self.timeZoneId));
          Salons.create(params).then(function (response) {
            if (!response.error && response.data) {
              self.salonID = response.data.attributes.id;
              HairTypes.addHairTypeToSalon(self.salonID, {
                hairtypeIds: self.input.hairtypes
              });
              SalonStyles.addStyleToSalon(self.salonID, {
                styleIds: self.input.styles
              });
              self.loadingCreate = false;
              reinitDatePicker();
              angular.element('#datepicker').datepicker('setDatesDisabled', getDateDisable(self.input.listTimeWorks));
            }
          }, function (error) {
            self.loadingCreate = false;

            if (error.errors[0].detail === "validation.distinct") {
              alert("Please fill correctly Hours Of Operation");
            } else {
              alert(error.errors[0].detail);
            }

          });
        })
      } else if (!checkValidateFormateLink(self.input.instagram_url)) {
        self.isInvalidInstagram = true;
        alert('Instagram url is invalid or empty!');
      } else {
        self.isShowMessageError = true;
      }
    }
    $(document).on('hide.bs.modal', '#modal-address', function () {
      self.taxPercent = 0;
      self.modelAddressName = null;
    });
  }

})(angular);