(function(angular) {
  'use strict';

  angular.module('Swivel').controller('OwnerSettingController', OwnerSettingController);
  OwnerSettingController.$inject = ['Auth', 'User', 'Users', 'Setting', 'Settings', 'Salon', 'Salons', '$localStorage','$q'];

  function OwnerSettingController(Auth, User, Users, Setting, Settings, Salon, Salons, $localStorage, $q) {
    var self = this;

    self.listSalon = [];
    self.listSalonId = [];
    self.listPromoCode = [];
    self.salonCurrent = null;
    self.salonCurrentId = null;
    self.getSalonPromoCodeInfo = getSalonPromoCodeInfo;
    self.query = '';
    self.currentMeProfile = null;
    self.limitNumberQuality = 100;
    self.page = $localStorage.currentPage > 1 ? $localStorage.currentPage : 1;
    self.totalObject = 0;
    self.inputChangePassword = {
      password: null,
      password_confirmation: null,
      old_password: null
    };
    self.paramPromoCode = {
      quantity: 1,
      value: '',
      salon_id: null
    };
    self.page = 1;
    self.pageSize = 10;
    self.quality = 1;
    self.value = null;
    self.taxCurrent = 0;
    self.messageChangePassword = null;
    self.changePasswordLoading = false;
    self.changePassword = changePassword;
    self.getListSalon = getListSalon;
    self.convertToInt = convertToInt;
    self.generatePromoCode = generatePromoCode;
    self.pageSearchChange = pageSearchChange;
    self.searchPromoCode = searchPromoCode;
    self.eventGenerateClick = eventGenerateClick;
    self.eventClickDeletePromoCode = eventClickDeletePromoCode;
    self.getStatusName = getStatusName;
    self.getNumber = getNumber;
    self.getMe = getMe;

    self.input = {
      link_app_ios: null
    };
    self.inputChangePassword = {
      password: null,
      password_confirmation: null,
      old_password: null
    }

    // declare parameter model
    self.loadingPage = false;
    // declare parameter model .end

    getAllSettings();

    function getNumber(min, max, step) {
      step = step || 1;
      var input = [];
      for (var i = min; i <= max; i += step) {
          input.push(i);
      }
      return input;
    };
    function convertToInt(num) {
      var number = parseInt(num);
      return number;
    }
    //ge list city add choose .end

    //check null string 
    function checkNullString(text) {
      if(text === '' || text === undefined) {
        return true;
      } else {
        return false;
      }
    }
    //check null string .end
   function searchPromoCode(reload, keyword) {
      if(reload) {
        self.page = 1;
      }
      console.log(keyword);
      if(self.query && !/^\s*$/.test(self.query)) {
        self.loadingPage = true;
        Salons.searchPromoCode(self.salonCurrentId, keyword).then(function(response) {
          if (!response.error && response.data) {
            self.listPromoCode = [];
            self.totalObject = response.meta.totalObjects;
            angular.forEach(response.data, function(value){
              self.listPromoCode.push(value);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
      } else {
        getListPromoCode(self.salonCurrentId);
      }
    }

    function changePassword(changePasswordForm) {
      if (changePasswordForm.$valid) {
        self.changePasswordLoading = true;
        Users.changePassword(self.inputChangePassword).then(function(response) {
          alert("Change password successfully!");
          changePasswordForm.$setPristine();
          resetForm();
          self.changePasswordLoading = false;
        }, function(error) {
          self.changePasswordLoading = false;
          self.messageChangePassword = "The old password is incorrect."
        });
      } else {
        self.messageChangePassword = "Please fill the form!";
      }
    }
    function hideAllMessage() {
      self.messageChangePassword = "";
    }
    function resetForm() {
      self.inputChangePassword = {
        password: null,
        password_confirmation: null,
        old_password: null
      }
    }
    //change select filter 
    function ChangeFilterStatus() {
      self.loadingPage = true;
      Settings.changeStatusFilter(self.isEnableFilter).then(function(response) { 
        getFilterStatus();
        self.loadingPage = false;
      });
      self.loadingPage = false;
    }
    //change select filter .end
    //delete neighborhood
    function deleteNeightborHood(item,index) {
      if (self.listNeightborHood[index] !== null) {
        if (confirm('Are you sure delete this item?')) {
          Settings.deleteNeightborHood(item.id).then(function(response) {
            if (!response.error) {
              getListNeighborHood();
            }
          });
        }
      }
    }
    //delete neighborhood .end

    //enable edit
    function enabelEditUpdate(neighborHood, $index) {
      self.isShowEdit = true;
      self.idCurrentEdit = $index;
      self.currentEditNeighborHood = neighborHood;
      self.currentEditNeighborHoodName = neighborHood.relationships.neighborhood.name;
      self.isShowGroupButton = true;
      self.indexShowEdit = $index;
      self.isAddNewNeighborHood = false;
    }
    //enable edit .end

    //edit neighborhood
    function updateNeightborHood(itemNeighborHood) {

    }
    //edit neighborhood .end
    function getSalonPromoCodeInfo() {
      getListPromoCode(self.salonCurrentId);
    }
    //check status
    function isStatus(status, statusCheck) {
      return statusCheck == (status & statusCheck);
    }

    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function getStatusName(id) {
      var idStatus = null;
      if(id === 1) {
        idStatus = "Used";
      } else if(id == 2) {
        idStatus = "Unused";
      }
      return idStatus;
    }
    function eventClickDeletePromoCode(item) {
      if (confirm('Are you sure delete this item?')) {
        Salons.deletePromoCode(item.attributes.id).then(function(response) {
          if (!response.error) {
            alert("Delete promo code is succesfull!");
            getListPromoCode(self.salonCurrentId);
          }
        },function(error) {
          alert("Cannot delete promo code item!");
        });
      }
    }
    function getListPromoCode(id) {
      self.loadingPage = true;
      self.listPromoCode = [];
      var params = {
        'page[number]': self.page,
        'page[size]': self.pageSize,
      }
      Salons.getPromocode(id, params).then(function(response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function(value){
            self.listPromoCode.push(value);
          });
        }
        self.loadingPage = false;
      },function(error) {
        self.loadingPage = false;
      });
    }
    function eventGenerateClick() {
      generatePromoCode(function() {
        getListPromoCode(self.salonCurrentId);
      },400);
    }
    // generate promo code
    function generatePromoCode(cb) {
      if(!isEmpty(self.paramPromoCode.value) && parseFloat(self.paramPromoCode.value) >= 0) {
        self.loadingPage = true;
        var parames = {
          'quantity': self.paramPromoCode.quantity,
          'value': self.paramPromoCode.value,
          'salon_id': self.salonCurrentId
        };
        Settings.createPromocode(parames).then(function(response) {
          if (!response.error && response.data) {
            alert("Create promo code is succesfull!");
          }
          self.loadingPage = false;
        }, function(error) {
          alert("System has error when generating promo code!");
          self.loadingPage = false;
        });
      }
      if(cb !== null && typeof cb === 'function') {
        cb();
      }
    }
    // generate promo code .end
    //check me
    function getMe(callback) {
      Users.getUserProfile().then(function(response) {
        if (!response.error && response.data) {
          self.currentMeProfile = new User(response.data);
          self.listSalonId = self.currentMeProfile.getListSalonId();
          if(typeof callback == 'function') {
            callback();
          }
        }
      });
    }
    // check me .end
    function getIdFirstListDefault(list, cb) {
      self.salonCurrentId = list.getId();
      if(typeof cb == 'function') {
        cb();
      }
    }

    // init setting list promo code
    function initSettingListPromoCode(cb) {
      self.paramPromoCode.salon_id = self.salonCurrentId;
      if(typeof cb == 'function') {
        cb(self.salonCurrentId);
      }
    }
    // init setting list promo code .end

    // get list salon
    function getListSalon(cb) {
      self.listSalon = [];
      var count =0;
      for(var i = 0; i < self.listSalonId.length; i++) {
        Salons.getListById(self.listSalonId[i]).then(function(response) {
          count++;
          if (!response.error && response.data) {
            var salonItem = new Salon(response.data, response.included);
            self.listSalon.push(salonItem);
            if(count == self.listSalonId.length) {
              if(typeof cb == 'function') {
                cb(self.listSalon[0]);
              }
            }
          }
        }, function(error) {
          count++;
          alert("System has error when getting list salon!");
        });
      }
      // if(i == self.listSalonId.lenght) {
      //   //self.salonCurrent = self.listSalon[0];
      //     getSalonDefault()
      //   //self.salonCurrentId = self.salonCurrent.getId();
      // }
    }
    // get list salon .end
    //check status .end
    function getAllSettings() {
      self.loadingPage = true;
      getMe(function() {
        getListSalon(function(firstSalon) {
          getIdFirstListDefault(firstSalon, function(){
            initSettingListPromoCode(function(salonCurrentId){
              getListPromoCode(self.salonCurrentId);
            });
          });
        });
      });
      self.loadingPage = false;
    }
    function getFilterStatus() {
      Settings.getFilterStatus().then(function(response) {
        switch(response.data.attributes.neighborhoods_filter) {
          case true : case '1' : case 1:
            self.isEnableFilter = true;
            $localStorage.isEnableFilter = true;
            break;
          case false : case '0' : case 0 : case null:
            self.isEnableFilter = false;
            $localStorage.isEnableFilter = false;
            break;
        }
      });
    }
    function pageSearchChange() {
      if(self.query && !/^\s*$/.test(self.query)) {
        searchPromoCode();
      } else {
        getListPromoCode(self.salonCurrentId);
      }
    }
  }


})(angular);
