(function(angular) {
  'use strict';

  angular.module('Swivel').service('Users', Users);

  Users.$inject = ['$q', '$http', 'serverUrl', 'Auth', '$state'];
  function Users($q, $http, serverUrl, Auth, $state) {
    var Users = function() {
      var self = this;
      self.SignIn = SignIn;
      self.SignOut = SignOut;
      self.revokeUser = revokeUser;
      self.getUserProfile = getUserProfile;
      self.getList = getList;
      self.search = search;
      self.forgotPassword = forgotPassword;
      self.changePassword = changePassword;
      self.resetPassword = resetPassword;
      self.getUserCreditCard = getUserCreditCard;
      self.updateUserCreditCard = updateUserCreditCard;
      self.exportExcel = exportExcel;
      self.getBookings = getBookings;

      function SignIn(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'auth/login',
          method: 'POST',
          data: params
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function SignOut() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'auth/logout',
          method: 'POST',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function revokeUser(status) {
        if (status == 401 || status == 403) {
          Auth.revokeUser();
          $state.go('signIn');
        }
      }

      function getUserProfile(id) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + (id ? 'users/' + id : 'me'),
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function resetPassword(datas) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'password/forgot/reset',
          method: 'PATCH',
          headers: Auth.getHeaders(),
          data: datas
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getList(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users',
          method: 'GET',
          headers: Auth.getHeaders(),
          params: params
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function search(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'search/users',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function forgotPassword(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'password/forgot/request',
          method: 'POST',
          data: params
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function changePassword(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + '/password/change',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getUserCreditCard(idUser) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + idUser + '/cards',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateUserCreditCard(idUser, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + idUser + '/cards',
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function exportExcel() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'csv/users',
          method: 'POST',
          headers: Auth.getHeaders(),
          responseType: 'blob',
        }
        $http(requestParams).success(function (response) {
          var file = new File([response], 'Users.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
          saveAs(file);
          deferred.resolve(response);
        })
        .error(function(error, status) {
          revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getBookings(userId, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'users/' + userId + '/bookings',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

    }

    return new (Users);
  }
})(angular);
