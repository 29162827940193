(function(angular) {
  'use strict';

  angular.module('Swivel').factory('Setting', Setting);
  Setting.$inject = ['BaseObject'];

  function Setting(BaseObject) {
    var Setting = function(object, included) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getAppName = getAppName;
      self.getAddress = getAddress;
      self.getPhone = getPhone;
      self.getAdminEmail = getAdminEmail;
      self.getLinkApp = getLinkApp;
      self.getTimeEarly = getTimeEarly;
      self.getTimeLate = getTimeLate;

      function getAttributes() {
        return self.get('attributes');
      }

      function getAppName() {
        return self.getAttributes().app_name;
      }

      function getAddress() {
        return self.getAttributes().address;
      }

      function getPhone() {
        return self.getAttributes().phone;
      }

      function getAdminEmail() {
        return self.getAttributes().admin_email;
      }

      function getLinkApp() {
        return self.getAttributes().link_app_ios;
      }

      function getTimeEarly() {
        return self.getAttributes().time_open_early;
      }

      function getTimeLate() {
        return self.getAttributes().time_open_late;
      }
    }

    return (Setting);
  }


})(angular);
