(function(angular) {
  'use strict';

  angular.module('Swivel').controller('ListUserController', ListUserController);
  ListUserController.$inject = ['Users', 'User'];

  function ListUserController(Users, User) {
    var self = this;
    self.listUsers = [];
    self.loadingPage = false;
    self.page = 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.query = '';
    self.pageSearchChange = pageSearchChange;
    self.searchUser = searchUser;
    self.exportExcel = exportExcel;
    self.loadingDownloadUsers = false;

    init();

    function init() {
      var params = {
        'page[number]': self.page,
        'page[size]': self.pageSize
      }
      self.loadingPage = true;
      self.listUsers = [];
      Users.getList(params).then(function(response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function(value, key) {
            var user = new User(value, response.included);
            self.listUsers.push(user);
          })
        }
        self.loadingPage = false;
      }, function(error) {
        self.loadingPage = false;
      });
    }

    function exportExcel() {
      self.loadingDownloadUsers = true;
      Users.exportExcel().then(function(response) {
        self.loadingDownloadUsers = false;
      }, function(error) {
        console.log(error);
        self.loadingDownloadUsers = false;
      });
    }

    function searchUser(reload) {
      if(reload) {
        self.page = 1;
      }
      if(self.query && !/^\s*$/.test(self.query)) {
        var params = {
          'page[number]': self.page,
          'page[size]': self.pageSize,
          'q': self.query
        }
        self.loadingPage = true;
        Users.search(params).then(function(response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listUsers = [];
            angular.forEach(response.data, function(value, key) {
              var user = new User(value, response.included);
              self.listUsers.push(user);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
      } else {
        init();
      }
    }

    function pageSearchChange() {
      if(self.query && !/^\s*$/.test(self.query)) {
        searchUser();
      } else {
        init();
      }
    }
  }


})(angular);
