(function(angular) {
  'use strict';

  angular.module('Swivel', [
    'ui.router',
    'ngCookies',
    'ui.bootstrap',
    'ui.sortable',
    'angular-ladda',
    'ngStorage',
    'ngFileUpload',
    'angular-carousel',
    'filters',
    'ladda',
    'ui.select',
  ]).run(Application).config(Config)

  Application.$inject = ['$rootScope', 'Auth', '$location', '$timeout', '$localStorage', '$state'];
  function Application($rootScope, Auth, $location, $timeout, $localStorage, $state) {
    $localStorage.currentPage = 1;
    $rootScope.isAdmin = isAdmin;
    $rootScope.isOwner = isOwner;
    $state.setReturn = setReturn;
    $state.goReturn = goReturn;

    delete $localStorage.searchKeyword;

    function isAdmin() {
      return Auth.isAdmin();
    }
    function isOwner() {
      return Auth.isOwner();
    }

    function setReturn(params) {
      $state.return = $state.current;
      $state.return.params = params;
    }

    function goReturn() {
      if ($state.return) {
        $state.go($state.return.name, $state.return.params);
        delete $state.return;
        return true;
      }
    }

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){
      if (Auth.revokeUserTokenExpire()) {
        $timeout(function() {
          $location.path('/signIn');
        },200)
      }
    });
  }

  Config.$inject = ['laddaProvider'];
  function Config(laddaProvider) {
    laddaProvider.setOption({
      style: 'zoom-out'
    });
  }

})(angular);
