(function(angular) {
  'use strict';

  angular.module('Swivel').controller('BookingController', BookingController);

  BookingController.$inject = ['Bookings', 'SalonLocation', 'SalonCity', 'Salon', 'HairType', 'HairTypes', '$timeout', 'SalonStyles', 'SalonStyle', 'Upload', 'serverUrl', '$localStorage', 'Salons', '$state', '$scope', 'SalonService', 'SalonServices', 'Settings','Setting','$filter', 'SalonStylists', 'SalonStylist', '$http', '$rootScope', '$location'];
  function BookingController(Bookings, SalonLocation, SalonCity, Salon, HairType, HairTypes, $timeout, SalonStyles, SalonStyle, Upload, serverUrl, $localStorage, Salons, $state, $scope, SalonService, SalonServices, Settings, Setting, $filter, SalonStylists, SalonStylist, $http, $rootScope, $location) {
    var self = this;
    self.listSalon = [];
    self.listStylist = [];
    self.listService = [];
    self.salonCurrentTime = [];
    self.input = {};
    self.bookingConfirm = {};
    // self.input.listService = [];
    self.errors = {};
    self.listServiceAdd = [];
    self.isShowMessageError = false;
    self.loadingPage = false;
    self.reg_mail =  /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
    self.reg_phone = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    self.getSalonInfor = getSalonInfor;
    self.getListSalonStylist = getListSalonStylist;
    self.getListSalonService = getListSalonService;
    self.createBooking = createBooking;
    self.checkDateSelectBooking = checkDateSelectBooking;
    self.getSalonCurrentTime = getSalonCurrentTime;
    self.formatTime = formatTime;
    self.isEmpty = isEmpty;
    self.checkValidateEmail = checkValidateEmail;
    self.checkValidatePhone = checkValidatePhone;
    self.saveBooking = saveBooking;
    self.cancelBooking = cancelBooking;
    init();

    function init() {
      getListSalon();
      datePicker();
      self.bookingConfirm = JSON.parse(localStorage.getItem('bookingInfor'));
    //   self.input.date = moment().format('YYYY-MM-DD');
    }

    function checkValidateEmail(email) {
      if(self.reg_mail.test(email)) {
        return true;
      } else {
        return false;
      }
    }

    function checkValidatePhone(phone) {
      if(self.reg_phone.test(phone)) {
        return true;
      } else {
        return false;
      }
    }

    // get all salons
    function getListSalon() {
      self.listSalon = [];
      var params = {
        'page[number]': self.page,
        'page[size]': 200
      };

      Salons.getList(params).then(function(response) {
        if (!response.error && response.data) {
          Salons.drainList(response, params, []).then(function(allSalons) {
            self.input.salon = new Salon(allSalons[0]);
            getSalonInfor();

            angular.forEach(allSalons, function(value, key) {
              var salon = new Salon(value);
              self.listSalon.push(salon);
            });
            self.loadingPage = true;
          })
        }
      }, function(error) {
        });
    }

    function getSalonInfor() {

        // allow all day to pick
        // angular.element('.datetimepicker-cus').datepicker('setDatesDisabled', getDateDisable(self.input.salon.getTimeworks()));
        getListSalonStylist(self.input.salon.getId());
        getListSalonService(self.input.salon.getId());
        self.listServiceAdd = [];
        self.input.date = null;
    }

    function getSalonCurrentTime() {

      if (self.input.stylist) {
        var dayCurrent = getDay(self.input.date);
        var listTimeCurrent = self.input.salon.getTimeworksFilterByDate(dayCurrent);

        // allow all time to pick
        // self.salonCurrentTime = self.input.stylist.getAvailableTimeworks(self.input.date, listTimeCurrent);
        self.salonCurrentTime = [
          '00:00', '00:30', '01:00', '01:30',
          '02:00', '02:30', '03:00', '03:30',
          '04:00', '04:30', '05:00', '05:30',
          '06:00', '06:30', '07:00', '07:30',
          '08:00', '08:30', '09:00', '09:30',
          '10:00', '10:30', '11:00', '11:30',
          '12:00', '12:30', '13:00', '13:30',
          '14:00', '14:30', '15:00', '15:30',
          '16:00', '16:30', '17:00', '17:30',
          '18:00', '18:30', '19:00', '19:30',
          '20:00', '20:30', '21:00', '21:30',
          '22:00', '22:30', '23:00', '23:30',
        ];
        self.input.time = self.salonCurrentTime[0];
      } else {
        self.salonCurrentTime = [];
      }
    }

    // get disable date
    function getDateDisable(timeworks) {

      var disableDay = [];
      var listDateDisable = [];
      angular.forEach(timeworks, function(value, key) {
        if (value.status == 1) {
          switch (value.weekday) {
            case 'Monday':
              disableDay.push(1);
            break;
            case 'Tuesday':
              disableDay.push(2);
            break;
            case 'Wednesday':
              disableDay.push(3);
            break;
            case 'Thursday':
              disableDay.push(4);
            break;
            case 'Friday':
              disableDay.push(5);
            break;
            case 'Saturday':
              disableDay.push(6);
            break;
            case 'Sunday':
              disableDay.push(0);
            break;
          }
        }
      })

      if (disableDay.length > 0) {

        var startDate = formatDateAllBrowser(new Date());
        var year = startDate.getFullYear();
        for (var i=1; i<=12; i++) {
          var month = i == 12 ? i-1 : i;
          var firstDay = new Date(year, month, 1);
          var lastDay = new Date(year, month, 0);
          for (var j=firstDay.getDate();j<=lastDay.getDate();j++) {
            var dateDisable = formatDateAllBrowser(year+'-'+i+'-'+j);
            if (disableDay.indexOf(dateDisable.getDay()) != -1) {
              listDateDisable.push(dateDisable.getFullYear()+'-'+(dateDisable.getMonth()+1)+'-'+j);
            }
          }
        }
      }

      return listDateDisable;
    }

    function getDay(timeWorkAt) {
      if(!isEmpty(timeWorkAt)) {
        var check = moment(timeWorkAt, 'YYYY/MM/DD');
        var day = check.format('dddd');
        return day;
      }
    }

    function getListSalonStylist(idSalon) {
      self.listStylist = [];
      // var time = null;
      // if(isformatedTime) {
      //   time = timeWorkAt;
      // } else {
      //   time = getDate(timeWorkAt);
      // }
      SalonStylists.getList(idSalon, {status: 2}).then(function(response) {
        if (!response.error && response.data.length) {
          var i = 0;
          angular.forEach(response.data, function(value, key) {
            var params = {
              'page[number]': self.page,
              'page[size]': 200,
              // 'work_at': '>=' + time
            }
            SalonStylists.getStylistTimeworks(idSalon, value.attributes.id, params).then(function(responseData) {
              if(!responseData.error) {
                var salonStylist = new SalonStylist(value, responseData);
                self.listStylist.push(salonStylist);
                if (key == 0) {
                    self.input.stylist = salonStylist;
                }
              }

              if(i++ == response.data.length - 1 && typeof cb == 'function') {
                cb();
              }

            });
          });
        }
      });
    }

    // get all service
    function getListSalonService(salonId) {
      self.listService = [];
      var params = {
        'page[number]': self.page,
        'page[size]': 200,
        'status': 2
      }

      SalonServices.getList(salonId, params).then(function(response) {
        if (!response.error && response.data) {
          angular.forEach(response.data, function(value, key) {
            var salonService = new SalonService(value);
            self.listService.push(salonService);
          });
        }
      }, function(error) {
        });
    }

    function datePicker() {
      // var dateObject = new Date();

      $timeout(function() {
        $('.datetimepicker-cus').datepicker({
          format: 'yyyy-mm-dd',
          autoclose: true,
          // allow all day to pick
          // startDate: dateObject
        })
      }, 200);
    }


    function checkDateSelectBooking() {

      var datepicker =  angular.element('.datetimepicker-cus')[0];
      if(isEmpty(datepicker.val)) {
        angular.element(datepicker).val(self.dateCurrent);
      }
    }

    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }

    function formatTime(time) {
      var hourType = 'AM';
      var hourCal = parseInt(time.substring(0, 2));
      var minuteString = time.substring(3, 5);
      var minuteCal = parseInt(minuteString);
      if (hourCal> 11) {
        hourType = 'PM';
      }
      var hour = hourCal < 10 ? '0'+String(hourCal) : (hourCal < 13 ?  String(hourCal) : '0' + parseInt(hourCal - 12));
      hour = hour.slice(-2);
      var min = minuteCal < 10 ? '0'+String(minuteCal) : minuteCal;
      var workTime = hour+':'+min+' '+hourType;
      return workTime;
    }

    function formatDateAllBrowser(date) {
      if (typeof date == 'string') {
        if (date.indexOf('T') != -1) {
          var splitDate = date.replace('+0000', '').replace('+00:00', '').split('T');
          var parseDate = splitDate[0].replace(/-/g, "/")+' '+splitDate[1];
        } else {
          var parseDate = date.replace(/-/g, "/");
        }
        var newDate = moment(new Date(parseDate));
      } else {
        var newDate = moment(date);
      }
      return new Date(newDate.year()+'/'+(newDate.month()+1)+'/'+newDate.date()+' '+ (parseInt(newDate.hour())) +':'+newDate.minute()+':'+newDate.second())
    }

    // function create booking
    function createBooking() {

      var isEmail = checkValidateEmail(self.input.userEmail);
      var isPhone = checkValidatePhone(self.input.userPhone);

      if (!isEmail || !isPhone || !self.input.userName || !self.input.userEmail || !self.input.userPhone || !self.input.salon || !self.listServiceAdd.length || !self.input.date || !self.input.time) {

        self.isShowMessageError = true;
      } else {

        var stringListService = (self.listService || [])
          .filter(function (value) { return self.listServiceAdd.indexOf(value.getId()) >= 0; })
          .map(function (value) { return value.getName(); })
          .join(', ');

        var bookingInfor = {
          username: self.input.userName,
          email: self.input.userEmail,
          phone: self.input.userPhone,
          salon_id: self.input.salon.getId(),
          stylist_id: self.input.stylist.getId(),
          serviceIds: self.listServiceAdd,
          work_at: self.input.date + ' ' +self.input.time + ':00' ,
          promocode: self.input.promocode,
          date: self.input.date,
          time: self.input.time,
          salonName: self.input.salon.getName(),
          stylistName: self.input.stylist.getName(),
          serviceNames: stringListService,
          userCard: [],
        };

        var params = {
          username: self.input.userName,
          email: self.input.userEmail,
          phone: self.input.userPhone,
          stylist_id: self.input.stylist.getId(),
          serviceIds: self.listServiceAdd,
          work_at: self.input.date + ' ' +self.input.time + ':00' ,
          promocode: self.input.promocode,
        }

        Bookings.createBookingTry(self.input.salon.getId(), params).then(function(response) {
          if (!response.error && response.data) {

             bookingInfor.confirm = response.data;
             bookingInfor.params = params;

             var users = response.included.filter(function(element){
                return element.type === 'User';
             })

             Bookings.getUserCard(users[0].id).then(function(response) {

               bookingInfor.userCard = response.data;
               localStorage.setItem('bookingInfor', JSON.stringify(bookingInfor));
               $location.path('/confirm-booking');

             }, function(error) {
               localStorage.setItem('bookingInfor', JSON.stringify(bookingInfor));
               $location.path('/confirm-booking');
             });
          }
          //self.loadingPage = false;
        }, function(error) {
          alert(error.errors[0].detail);
        });
      }
    }

    function saveBooking() {

      var message = 'Empty Credit Card\nRemember to add Credit Card before you checkout from Admin portal.\nDo you want to continue?';
      var cf = true;
      if (!self.bookingConfirm.userCard.id) {
        cf = confirm(message);
      }

      if (cf) {

        self.loadingPage = false;
        Bookings.createBooking(self.bookingConfirm.salon_id , self.bookingConfirm.params).then(function(response) {
          if (!response.error && response.data) {
            localStorage.removeItem('bookingInfor');
            alert('Congratulations!\nYou have just created an appointment for Jihan');
            $location.path('edit-booking/' + response.data.id);
          }
        }, function(error) {
            alert('There\'s error on create booking. Please check your list booking.');
            $state.go('dashboard.bookings', {salonID: self.bookingConfirm.salon_id});
        });
      }
    }

    function cancelBooking(){

      localStorage.removeItem('bookingInfor');
      $location.path('/');
    }
  }

})(angular);
