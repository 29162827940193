(function(angular) {
  'use strict';

  angular.module('Swivel').service('Salons', Salons);

  Salons.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users', 'Upload'];
  function Salons($q, $http, serverUrl, Auth, Users, Upload) {
    var Salons = function() {
      var self = this;
      self.create = create;
      self.getList = getList;
      self.getListSalonOwner = getListSalonOwner;
      self.updateSalon = updateSalon;
      self.deleteSalon = deleteSalon;
      self.updateSalonStatus = updateSalonStatus;
      self.getSalonInfo = getSalonInfo;
      self.search = search;
      self.searchPromoCode = searchPromoCode;
      self.deletePromoCode = deletePromoCode;
      self.getBookings = getBookings;
      self.getPromocode = getPromocode;
      self.getListById = getListById;
      self.createStripAccount = createStripAccount;
      self.createStripImage = createStripImage;
      self.createBankInfo = createBankInfo;
      self.getStripeAccountInfo = getStripeAccountInfo;
      self.getImageUploadInfo = getImageUploadInfo;
      self.updateBankAccount = updateBankAccount;
      self.deleteBankAccount = deleteBankAccount;
      self.updateSalonValue = updateSalonValue;
      self.exportExcel = exportExcel;
      self.drainList = drainList;




      function create(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deletePromoCode(promoCodeId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'promoCodes/'+promoCodeId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deleteSalon(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateSalon(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateSalonStatus(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID+'/status',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getPromocode(id, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'promoCodes',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getList(params) {
        var deferred = $q.defer();
        var requestParams = null;
        if(params !== null) {
          requestParams = {
            url: serverUrl + 'salons',
            method: 'GET',
            params: params,
            headers: Auth.getHeaders()
          }
        } else {
           requestParams = {
            url: serverUrl + 'salons',
            method: 'GET'
          }
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function drainList(response, params, combinedSalons, dontSort) {
        if (!params['page[number]']) {
          params['page[number]'] = 1;
        }

        if (!response.error && response.data) {
          var totalObjects = response.meta.totalObjects;
    
          var currentSalons = response.data;
    
          combinedSalons = combinedSalons.concat(currentSalons);
          
          if (combinedSalons.length == totalObjects) {
            if (dontSort) {
              return $q.resolve(combinedSalons);
            } else {
              return $q.resolve(combinedSalons.sort(((a, b) => a.attributes.name > b.attributes.name ? 1 : -1)));
            }
          } else {  
            params['page[number]']++;
    
            return getList(params).then(function(response) {
              return drainList(response, params, combinedSalons, dontSort)
            })
          } 
        } else {
          return $q.reject()
        }
      }

      function getListSalonOwner(id, params) {
        var deferred = $q.defer();
        var requestParams = null;
        requestParams = {
          url: serverUrl + 'salons?salonOwners.user_id=' + id,
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getStripeAccountInfo(salonID) {
        var deferred = $q.defer();
        var requestParams = null;
        requestParams = {
          url: serverUrl + 'salons/' + salonID +'/stripe',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getImageUploadInfo(salonStripID) {
        var deferred = $q.defer();
        var requestParams = null;
        requestParams = {
          url: serverUrl + 'salonStripes/' + salonStripID +'/identityDocument',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getListById(id) {
        var deferred = $q.defer();
        var requestParams = null;
        requestParams = {
          url: serverUrl + 'salons/' + id,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getSalonInfo(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID,
          method: 'GET'
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function search(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'search/salons',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function searchPromoCode(id, query) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + '/promoCodes?code=' + query,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getBookings(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/bookings',
          method: 'GET',
          params: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function createStripAccount(salonID, datas) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stripe',
          method: 'POST',
          data: datas,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateSalonValue(salonID, datas) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stripeFee',
          method: 'POST',
          data: datas,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }




      function createStripImage(salonStripeID, datas) {
        var deferred = $q.defer();
        Upload.upload({
          url: serverUrl + 'salonStripes/' + salonStripeID + '/identityDocument',
          method: 'POST',
          data: datas,
          headers: Auth.getHeaders()
        }).progress(function(response) {

        })
        .success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.resolve(error);
        });
        return deferred.promise;
      }
      function createBankInfo(salonID, datas) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonStripes/' + salonID + '/banks',
          method: 'POST',
          data: datas,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function updateBankAccount(salonStripeId, bankId, data) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonStripes/' + salonStripeId + '/banks/' + bankId,
          method: 'PATCH',
          headers: Auth.getHeaders(),
          data: data
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deleteBankAccount(salonStripeId, bankId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salonStripes/' + salonStripeId + '/banks/' + bankId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function exportExcel() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'csv/salons',
          method: 'POST',
          headers: Auth.getHeaders(),
          responseType: 'blob',
        }
        $http(requestParams).success(function (response) {
          var file = new File([response], 'Salons.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
          saveAs(file);
          deferred.resolve(response);
        })
        .error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (Salons);
  }


})(angular);
