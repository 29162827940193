(function(angular) {
  'use strict';

  angular.module('Swivel').factory('SalonService', SalonService);

  SalonService.$inject = ['BaseObject'];
  function SalonService(BaseObject) {
    var SalonService = function(object) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getName = getName;
      self.getPrice = getPrice;
      self.getDescription = getDescription;
      self.getId = getId;
      self.getCreatedAt = getCreatedAt;
      self.getStatus = getStatus;
      self.getDuration = getDuration;

      function getAttributes() {
        return self.get('attributes');
      }

      function getName() {
        return self.getAttributes().name;
      }

      function getPrice() {
        return self.getAttributes().price;
      }

      function getDescription() {
        return self.getAttributes().description;
      }

      function getId() {
        return self.getAttributes().id;
      }

      function getCreatedAt() {
        return self.getAttributes().createdAt;
      }

      function getStatus() {
        return self.getAttributes().status;
      }

      function getDuration() {
        return self.getAttributes().duration;
      }

    }

    return (SalonService);
  }
})(angular);
