(function(angular) {
  'use strict';

  angular.module('Swivel').service('HairTypes', HairTypes);

  HairTypes.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];
  function HairTypes($q, $http, serverUrl, Auth, Users) {
    var HairTypes = function() {
      var self = this;
      self.getList = getList;
      self.create = create;
      self.addHairTypeToSalon = addHairTypeToSalon;
      self.updateHairType = updateHairType;
      self.deleteHairType = deleteHairType;

      function getList(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'hairtypes',
					method: 'GET',
					params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function create(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'hairtypes',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function addHairTypeToSalon(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/hairtypes',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateHairType(hairTypeId, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'hairtypes/'+hairTypeId,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteHairType(hairTypeId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'hairtypes/'+hairTypeId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

    }

    return new (HairTypes);
  }


})(angular);
