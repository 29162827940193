(function(angular) {
  'use strict';

  angular.module('Swivel').directive('iCheck', iCheck);

  iCheck.$inject = ['$timeout', '$parse'];
  function iCheck($timeout, $parse) {
    return {
      require: 'ngModel',
      scope: {
        modelServiceId: '='
      },
      link: function ($scope, element, $attrs, ngModel) {
        return $timeout(function () {
           var value = $attrs.value;
           var $element = $(element);

           if ($element.attr('type') === 'checkbox') {
             $element.iCheck({
               checkboxClass: 'icheckbox_minimal-blue',
               radioClass: 'iradio_minimal-blue'
             });
           } else {
             $element.iCheck({
              checkboxClass: 'icheckbox_flat-green',
              radioClass: 'iradio_flat-green'
             });
           }
          $scope.$watch('modelServiceId', function (newValue) {
            $element.iCheck('update');
           });
          $scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            $element.iCheck('update');
          });

          $element.on('ifChanged', function (event) {
            if ($element.attr('type') === 'checkbox' && $attrs.ngModel) {
              $scope.$apply(function() {
                ngModel.$setViewValue(event.target.checked);
              });
            }
            if ($element.attr('type') === 'radio' && $attrs.ngModel) {
              $scope.$apply(function () {
                ngModel.$setViewValue(value);
              });
            }
          });
        });
      }
    }
  }

})(angular);
