(function(angular) {
  'use strict';

  angular.module('Swivel').service('SalonStyles', SalonStyles);

  SalonStyles.$inject = ['$q', '$http', 'serverUrl', 'Users', 'Auth'];
  function SalonStyles($q, $http, serverUrl, Users, Auth) {
    var SalonStyles = function() {
      var self = this;
      self.getList = getList;
      self.create = create;
      self.addStyleToSalon = addStyleToSalon;
      self.updateStyle = updateStyle;
      self.deleteStyle = deleteStyle;

      function getList(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'styles',
          method: 'GET',
          params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function create(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'styles',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function addStyleToSalon(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/styles',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateStyle(styleID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'styles/'+styleID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteStyle(styleID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'styles/'+styleID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (SalonStyles);
  }


})(angular);
