(function(angular) {
  'use strict';

  angular.module('Swivel').service('SalonServices', SalonServices);

  SalonServices.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];
  function SalonServices($q, $http, serverUrl, Auth, Users) {
    var SalonServices = function() {
      var self = this;
      self.create = create;
      self.deleteService = deleteService;
      self.getList = getList;
      self.getListService = getListService;
      self.updateService = updateService;
      self.updateServiceStatus = updateServiceStatus;
      self.arrange = arrange;

      function create(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getListService(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function arrange(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/services/arrangement',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getList(salonID, params) {
        params = angular.copy(params);
        if (!params.sort) {
          params.sort = 'order';
        }
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services',
          method: 'GET',
          params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteService(salonID, serviceID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services/' + serviceID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateService(salonID, serviceID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services/' + serviceID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateServiceStatus(salonID, serviceID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID +'/services/' + serviceID+'/status',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

    }

    return new (SalonServices);
  }


})(angular);
