(function(angular) {
  'use strict';

  angular.module('Swivel').controller('UpdatePasswordController', UpdatePasswordController);
  UpdatePasswordController.$inject = ['Setting', 'Settings', 'Users'];

  function UpdatePasswordController(Setting, Settings, Users) {
    var self = this;
    self.input = {
      link_app_ios: null
    }
    self.inputChangePassword = {
      password: null,
      password_confirmation: null,
      old_password: null
    }
    self.messageChangePassword = null;
    self.setingLoading = false;
    self.changePasswordLoading = false;
    self.saveAllSettings = saveAllSettings;
    self.changePassword = changePassword;
    self.hideAllMessage = hideAllMessage;

    getAllSettings();

    function getAllSettings() {
      Settings.get().then(function(response) {
        if (!response.error && response.data) {
          var settings = new Setting(response.data);
          self.input.link_app_ios = settings.getLinkApp();
        }
      });
    }

    function saveAllSettings() {
      self.setingLoading = true;
      Settings.save(self.input).then(function(response) {
        if (!response.error) {

        }
        self.setingLoading = false;
      }, function(error) {
        self.setingLoading = false;
      });
    }

    function changePassword(changePasswordForm) {
      if (changePasswordForm.$valid) {
        self.changePasswordLoading = true;
        Users.changePassword(self.inputChangePassword).then(function(response) {
          alert("Change password successfully!");
          changePasswordForm.$setPristine();
          resetForm();
          self.changePasswordLoading = false;
        }, function(error) {
          self.changePasswordLoading = false;
          self.messageChangePassword = "The old password is incorrect."
        });
      } else {
        self.messageChangePassword = "Please fill the form!";
      }
    }

    function hideAllMessage() {
      self.messageChangePassword = "";
    }

    function resetForm() {
      self.inputChangePassword = {
        password: null,
        password_confirmation: null,
        old_password: null
      }
    }
  }


})(angular);