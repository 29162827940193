(function(angular) {
  'use strict';

  angular.module('Swivel').controller('DashboardController', DashboardController);

  DashboardController.$inject = ['Auth', '$state', 'Users', '$timeout', 'User', '$localStorage', 'Settings', '$rootScope'];
  function DashboardController(Auth, $state, Users, $timeout, User, $localStorage, Settings, $rootScope) {
    var self = this;
    self.listSalon = [];
    self.signOut = signOut;
    self.userInfo = null;
    self.goToList = goToList;
    self.isAuthenticated = isAuthenticated;
    self.isAdmin = isAdmin;
    self.getSalonId = getSalonId;
    
    getUserProfile();
    getStatusSetting();
    function getUserProfile() {
      Users.getUserProfile().then(function(response) {
        if (!response.error && response.data) {
          self.userInfo = new User(response.data);
          var role = response.data.attributes.roles[0];
          Auth.setUser(self.userInfo);
          Auth.setRole(role);
          self.listSalon = self.userInfo.getListSalonId();
          var numOfSalon = self.listSalon.length;
          if(numOfSalon > 0) {
            $rootScope.salonIdCurrently = self.listSalon[numOfSalon - 1];
          }
        }
      });
    }
    function getStatusSetting() {
      Settings.getFilterStatus().then(function(response) {
        switch(response.data.attributes.neighborhoods_filter) {
          case true : case '1' : case 1:
            $localStorage.isEnableFilter = true;
            break;
          case false : case '0' : case 0 : case null:
             $localStorage.isEnableFilter = false;
            break;
        }
      });
    }
    function signOut() {
      Users.SignOut().then(function(response) {
        Auth.revokeUser();
        $timeout(function() {
          $state.go('signIn');
        })
      });
    }
    function getSalonId() {
      var listSalon = response.data.attributes.salons;
      var numOfSalon = listSalon.length;
      if(numOfSalon > 0) {
        $rootScope.salonIdCurrently = listSalon[numOfSalon - 1];
          $state.go('dashboard.bookings', { 'salonID': $rootScope.salonIdCurrently});
      }
    }
    function goToList() {
      $localStorage.currentPage = 1;
      delete $localStorage.searchKeyword;
      $state.go('dashboard.salon', {}, {reload: true, notify: true});
    }
    function isAuthenticated() {
      return Auth.isAuthenticated();
    }

    function isAdmin() {
      return Auth.isAdmin();
    }
  }

})(angular);
