(function(angular) {
  'use strict';

  angular.module('Swivel').controller('SalonController', SalonController);

  SalonController.$inject = ['Auth','Users', 'User', 'Salon', 'Salons', '$timeout', '$localStorage', '$state'];
  function SalonController(Auth, Users, User, Salon, Salons, $timeout, $localStorage, $state) {
    var self = this;
    self.listSalon = [];
    self.loadingPage = false;
    self.deleteSalon = deleteSalon;
    self.updateSalonStatus = updateSalonStatus;
    self.isStatus = isStatus;
    self.page = $localStorage.currentPage > 1 ? $localStorage.currentPage : 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.pageSizeSalonOwner = 200;
    self.query = '';
    self.pageSearchChange = pageSearchChange;
    self.searchSalon = searchSalon;
    self.goToDetail = goToDetail;
    self.exportExcel = exportExcel;
    self.loadingDownloadSalons = false;

    init();

    function init() {

      if (angular.isDefined($localStorage.searchKeyword) && $localStorage.searchKeyword != "") {
        self.query = $localStorage.searchKeyword;
        searchSalon(false, $localStorage.searchKeyword);
      } else {
        var pageSize = self.pageSize;
        var params = {
          'page[number]': self.page,
          'page[size]': Auth.isAdmin() ? self.pageSize : self.pageSizeSalonOwner
        };
        self.loadingPage = true;
        self.listSalon = [];
        if(Auth.isAdmin()) {
          getListSalonByAdmin(params);
        } else if(Auth.isOwner()) {
          Users.getUserProfile().then(function(response) {
            var user = new User(response.data, response.included);
            var userId = user.getId();
            getListSalonByOwner(userId, params);
          });
        }
      }
    }

    function exportExcel() {
      self.loadingDownloadSalons = true;
      Salons.exportExcel().then(function(response) {
        self.loadingDownloadSalons = false;
      }, function(error) {
        console.log(error);
        self.loadingDownloadSalons = false;
      });
    }

    function getListSalonByAdmin(params) {
        Salons.getList(params).then(function(response) {
          if (!response.error && response.data) {
            Salons.drainList(response, params, [], true).then(function(allSalons) {
              self.totalObject = response.meta.totalObjects;
              angular.forEach(allSalons, function(value, key) {
                var salon = new Salon(value, response.included);
                self.listSalon.push(salon);
              })
              self.loadingPage = false;
            }, function(error) {
              self.loadingPage = false;
            })
          }
        }, function(error) {
          self.loadingPage = false;
        });
    }
    function getListSalonByOwner(id, params) {
      Salons.getListSalonOwner(id, params).then(function(response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function(value, key) {
            var salon = new Salon(value, response.included);
            self.listSalon.push(salon);
          })
        }
        self.loadingPage = false;
      }, function(error) {
        self.loadingPage = false;
      });
    }
    function isStatus(status, statusCheck) {
      return statusCheck == (status & statusCheck);
    }

    function deleteSalon(item, index) {
      if (self.listSalon[index]) {
        if (confirm('Are you sure delete this item?')) {
          Salons.deleteSalon(item.getId()).then(function(response) {
            if (!response.error) {
              pageSearchChange()
            }
          })
        }
      }
    }

    function updateSalonStatus(item, index, type) {
      if (self.listSalon[index]) {
        var status = null;
        if (type == 'status') {
          if (isStatus(item.getStatus(), 1)) {
            status = {status: 2}
          } else if (isStatus(item.getStatus(), 2)) {
            status = {status: 1}
          }
        } else {
          if (isStatus(item.getStatus(), 4)) {
            status = {
              status: 0,
              status_override: 4
            }
          } else {
            status = {status: 4};
          }
        }
        Salons.updateSalonStatus(item.getId(), status).then(function(response) {
          if (!response.error) {
            self.listSalon[index].getAttributes().status = response.data.attributes.status;
          }
        })
      }
    }

    function searchSalon(reload, keyword) {
      if(reload) {
        self.page = 1;
      }
      if(self.query && !/^\s*$/.test(self.query)) {
        var params = {
          'page[number]': self.page,
          'page[size]': self.pageSize,
          'q': angular.isDefined(keyword) ? '%' + keyword + '%': '%' + self.query +'%'
        }
        self.loadingPage = true;
        Salons.search(params).then(function(response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listSalon = [];
            angular.forEach(response.data, function(value, key) {
              var salon = new Salon(value, response.included);
              self.listSalon.push(salon);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
      } else {
        init();
      }
    }

    function pageSearchChange() {
      if(self.query && !/^\s*$/.test(self.query)) {
        searchSalon();
      } else {
        init();
      }
    }

    function goToDetail(id) {
      $localStorage.currentPage = self.page;
      $localStorage.searchKeyword = self.query;
      $state.go('dashboard.edit-salon', {salonID: id});
    }
  }

})(angular);
