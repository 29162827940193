(function (angular) {
  'use strict';

  angular.module('Swivel').controller('ReviewController', ReviewController);

  ReviewController.$inject = ['Auth', 'Review', 'Reviews', 'Salon', 'Salons', 'User', 'Users', 'HairType', 'HairTypes', 'SalonStylist', 'SalonStylists', 'SalonService', 'SalonServices', '$timeout', 'Upload', 'serverUrl'];

  function ReviewController(Auth, Review, Reviews, Salon, Salons, User, Users, HairType, HairTypes, SalonStylist, SalonStylists, SalonService, SalonServices, $timeout, Upload, serverUrl) {
    var self = this;
    self.listReview = [];
    self.loadingPage = false;
    self.selectReview = null;
    self.setSelectReview = setSelectReview;
    self.uploadFile = uploadFile;
    self.deletePhoto = deletePhoto;
    self.isShowMessageError = false;
    self.uploading = false;
    self.updating = false;
    self.hairRating = hairRating;
    self.salonRating = salonRating;
    self.resetSelectReview = resetSelectReview;
    self.updateReview = updateReview;
    self.referenceSelectReviewIndex = -1;
    self.deleteReview = deleteReview;
    self.updateReviewStatus = updateReviewStatus;
    self.page = 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.searchReview = searchReview;
    self.query = '';
    self.pageSearchChange = pageSearchChange;
    self.sort = {
      type: '',
      direction: ''
    }; // sort empty meaning not sorting
    self.sorting = sorting;
    self.sortConst = {
      userName: 'user.name',
      salonName: 'salon.name',
      salonRating: 'salon.overall_rating',
      date: 'id'
    };
    self.checkSort = checkSort;

    //add new review
    self.CreateSucces = "Create reivew successful!";
    self.ErrorMessageCreate = "Review has existed or system has problem when saving!";
    self.isShowMessageCreateError = false;
    self.loadingCreateReview = false;
    self.isOpenFormAddNewReview = false;
    self.pageSizeSalonOwner = 200;
    self.validateReview = null;
    self.addHairRating = addHairRating;
    self.addSalonRating = addSalonRating;
    self.createNewReview = createNewReview;
    self.OpenFormAddNewReview = OpenFormAddNewReview;
    self.HiddenFormAddNewReview = HiddenFormAddNewReview;
    self.resetFormAddReview = resetFormAddReview;
    self.uploadFileFromAddReview = uploadFileFromAddReview;
    self.deletePhotoFromAddReview = deletePhotoFromAddReview;
    self.loadDataBySalon = loadDataBySalon;
    self.OpenServiceReview = OpenServiceReview;
    self.isOpenServiceReview = false;
    self.OpenStylistReview = OpenStylistReview;
    self.isOpenStylistReview = false;
    self.searchBox = searchBox;


    self.queryUserName = '';
    self.listSalon = [];
    self.listUsers = [];
    self.listStylist = [];
    self.listService = [];
    self.listHairType = [];
    self.salonSelected = null;
    self.userSelected = null;
    self.hairTypeSelected = null;
    self.stylistSelected = null;
    self.input = {
      full_name: '',
      comment: '',
      hair_rating: '',
      salon_rating: '',
      hair_type_id: '',
      stylist_id: '',
      user_id: '',
      other_service: '',
      other_stylist: '',
      wait_time: '',
      photos: [],
      stylists: [],
      services: [],
    };

    init();

    function init() {
      var sortParam = self.sort.direction + self.sort.type;
      var params = {
        'page[number]': self.page,
        'page[size]': self.pageSize,
        'sort': sortParam
      };
      self.loadingPage = true;
      Reviews.getList(params).then(function (response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          self.listReview = [];
          angular.forEach(response.data, function (value, key) {
            var review = new Review(value, response.included);
            self.listReview.push(review);
          });
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function searchBox(search) {
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      self.loadingPage = true;
      self.listUsers = [];
      if (search.length > 3) {
        params = {
          'username': search
        };
        Users.getList(params).then(function (response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            angular.forEach(response.data, function (value, key) {
              var user = new User(value, response.included);
              self.listUsers.push(user);
            });
          }
          self.loadingPage = false;
        }, function (error) {
          self.loadingPage = false;
        });
      } else if ((search.length == 0)) {
        getListUser();
      }
    }

    function checkNumber(number) {
      number = String(number);
      if (number.match(/^\d+$/)) {
        return true;
      } else if (number.match(/^\d+\.\d+$/)) {
        return true;
      } else {
        return false;
      }
    }

    function getListSalonByAdmin() {
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      Salons.getList(params).then(function (response) {
        if (!response.error && response.data) {
          Salons.drainList(response, params, []).then(function(allSalons) {
            self.totalObject = response.meta.totalObjects;
            angular.forEach(allSalons, function (value, key) {
              var salon = new Salon(value, response.included);
              self.listSalon.push(salon);
            });
            self.loadingPage = false;
          }, function(error) {
            self.loadingPage = false;
          })
            
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function loadDataBySalon(salonID) {
      loadStylistBySalon(salonID);
      loadServiceBySalon(salonID);
    }

    function loadStylistBySalon(salonID) {
      self.listStylist = [];
      self.input.stylists = [];
      self.stylistSelected = null;
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      SalonStylists.getList(salonID, params).then(function (response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function (value, key) {
            var stylist = new SalonStylist(value, response.included);
            self.listStylist.push(stylist);
          });
          $timeout(function () {
            angular.element(".nano-stylist").nanoScroller();
          });
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function loadServiceBySalon(salonID) {
      self.listService = [];
      self.input.services = [];
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      SalonServices.getList(salonID, params).then(function (response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function (value, key) {
            var service = new SalonService(value, response.included);
            self.listService.push(service);
          });
          $timeout(function () {
            angular.element(".nano-service").nanoScroller();
          });
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function getListHairType() {
      var params = {
        'page[number]': 1,
        'page[size]': 200
      };
      HairTypes.getList(params).then(function (response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function (value, key) {
            var hairType = new HairType(value, response.included);
            self.listHairType.push(hairType);
          });
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function getListUser() {
      var params = {
        'page[number]': 1,
        'page[size]': 200,
        //'all'       : true
      };
      self.loadingPage = true;
      self.listUsers = [];
      Users.getList(params).then(function (response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          angular.forEach(response.data, function (value, key) {
            var user = new User(value, response.included);
            self.listUsers.push(user);
          });
        }
        self.loadingPage = false;
      }, function (error) {
        self.loadingPage = false;
      });
    }

    function createNewReview() {
      if (self.input && (self.input.full_name || self.userSelected) && self.salonSelected && self.input.wait_time && self.input.salon_rating && self.input.hair_rating) {

        if (!checkNumber(self.input.wait_time)) {
          self.validateReview = 'Wait time must be a number.';
          self.isShowMessageCreateError = true;
          return;
        }
        if (parseInt(self.input.wait_time) < 0) {
          self.isShowMessageCreateError = true;
          self.validateReview = 'Wait time value must be greater than zero (0).';
          return;
        }

        self.loadingCreateReview = true;
        var params = {
          full_name: self.input.full_name,
          comment: self.input.comment,
          salon_rating: self.input.salon_rating,
          hair_rating: self.input.hair_rating,
          wait_time: self.input.wait_time,
          user_id: self.userSelected ? self.userSelected.data.id : null,
          hair_type_id: self.hairTypeSelected ? self.hairTypeSelected.data.id : null,
          stylistIds: self.input.stylists ? self.input.stylists : [],
          serviceIds: self.input.services ? self.input.services : [],
          photos: self.input.photos ? self.input.photos : [],
          other_service: self.input.other_service ? self.input.other_service : '',
          other_stylist: self.input.other_stylist ? self.input.other_stylist : '',
        };

        Reviews.create(params, self.salonSelected.data.id).then(function (response) {
          if (!response.error && response.data) {
            self.salonID = response.data.attributes.id;
            self.loadingCreateReview = false;
            self.isOpenFormAddNewReview = false;
            alert(self.CreateSucces);
            resetFormAddReview();
            init();
          }
        }, function (error) {
          self.loadingCreateReview = false;
          alert(self.ErrorMessageCreate);
        });
      } else {
        self.isShowMessageCreateError = true;
      }
    }

    function OpenFormAddNewReview() {
      getListSalonByAdmin();
      getListUser();
      getListHairType();
      self.isOpenFormAddNewReview = true;
    }

    function HiddenFormAddNewReview() {
      self.isOpenFormAddNewReview = false;
      resetFormAddReview();
    }

    function OpenServiceReview() {
      if (self.isOpenServiceReview) {
        self.isOpenServiceReview = false;
        self.input.other_service = '';
      } else {
        self.isOpenServiceReview = true;
      }
    }

    function OpenStylistReview() {
      if (self.isOpenStylistReview) {
        self.isOpenStylistReview = false;
        self.input.other_stylist = '';
      } else {
        self.isOpenStylistReview = true;
      }
    }

    function resetFormAddReview() {
      self.isShowMessageCreateError = false;
      self.isOpenServiceReview = false;
      self.isOpenStylistReview = false;
      self.loadingCreateReview = false;
      self.validateReview = null;
      self.salonSelected = null;
      self.userSelected = null;
      self.hairTypeSelected = null;
      self.stylistSelected = null;
      self.listUsers = [];
      self.listSalon = [];
      self.listHairType = [];
      self.listStylist = [];
      self.listService = [];
      self.input.full_name = '';
      self.input.comment = '';
      self.input.salon_rating = '';
      self.input.hair_rating = '';
      self.input.wait_time = '';
      self.input.photos = [];
      self.input.stylists = [];
      self.input.services = [];
      self.input.other_stylist = '';
      self.input.other_service = '';
    }

    function addHairRating(rate) {
      self.input.hair_rating = rate;
    }

    function addSalonRating(rate) {
      self.input.salon_rating = rate;
    }

    function uploadFileFromAddReview(file) {
      if (file) {
        if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) == -1) {
          alert('File format is not valid!');
          return;
        }
        if (file.size > 10 * 1024 * 1024) {
          alert('File size is too large\nMax size allow: 10MB');
          return;
        }
        self.uploading = true;
        self.loadingCreateReview = true;
        Upload.upload({
          url: serverUrl + 'upload/photos',
          data: {
            image: file
          }
        }).progress(function (event) {

        }).success(function (response, status, headers, config) {
          self.uploading = false;
          self.input.photos.push(response.data.link);
          self.loadingCreateReview = false;
        }).error(function (response, status, headers, config) {
          self.uploading = false;
          self.loadingCreateReview = false;
        });
      }
    }

    function deletePhotoFromAddReview(index) {
      self.input.photos.splice(index, 1);
    }

    function uploadFile(file) {
      if (file) {
        if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) == -1) {
          alert('File format is not valid!');
          return;
        }
        if (file.size > 10 * 1024 * 1024) {
          alert('File size is too large\nMax size allow: 10MB');
          return;
        }
        self.uploading = true;
        Upload.upload({
          url: serverUrl + 'upload/photos',
          data: {
            image: file
          }
        }).progress(function (event) {

        }).success(function (response, status, headers, config) {
          self.uploading = false;
          self.selectReview.photos.push(response.data.link)
        }).error(function (response, status, headers, config) {
          self.uploading = false;
        });
      }
    }

    function deletePhoto(index) {
      self.selectReview.photos.splice(index, 1);
    }

    function setSelectReview(index) {
      self.referenceSelectReviewIndex = index;
      var review = self.listReview[index];
      var editReview = {
        "id": review.getId(),
        "salon_id": review.getSalonId(),
        "user_id": review.getUserId(),
        "stylist_id": review.getStylistId(),
        "salon_rating": review.getSalonRating(),
        "hair_rating": review.getHairRating(),
        "wait_time": review.getWaitTime(),
        "comment": review.getComment(),
        "created_at": review.getCreatedAt(),
        "photos": review.getPhotos() != null ? review.getPhotos().concat() : [],
        "user_name": review.getUserName()
      };
      self.selectReview = editReview;
      self.isOpenFormAddNewReview = false;
      resetFormAddReview();
    }

    function resetSelectReview() {
      self.selectReview = null;
      self.referenceSelectReviewIndex = -1;
    }

    function hairRating(rate) {
      self.selectReview.hair_rating = rate;
    }

    function salonRating(rate) {
      self.selectReview.salon_rating = rate;
    }

    function updateReview() {
      if (self.selectReview.comment != '') {
        var params = {
          "salon_rating": self.selectReview.salon_rating,
          "hair_rating": self.selectReview.hair_rating,
          "comment": self.selectReview.comment,
          "photos": self.selectReview.photos
        };
        self.updating = true;
        Reviews.updateReview(self.selectReview.salon_id, self.selectReview.id, params).then(function (response) {
          if (!response.error && response.data) {
            self.listReview[self.referenceSelectReviewIndex].data.attributes = response.data.attributes;
            self.updating = false;
            resetSelectReview();
          }
        }, function (error) {
          self.updating = false;
        });
      } else {
        self.isShowMessageError = true;
      }
    }

    function deleteReview(index) {
      if (confirm('Are you sure delete this item?')) {
        var review = self.listReview[index];
        Reviews.deleteReview(review.getSalonId(), review.getId()).then(function (response) {
          if (!response.error) {
            pageSearchChange();
          }
        });
      }
    }

    function updateReviewStatus(review) {
      if (review) {
        var params = {
          "status": review.getStatus() == 1 ? 2 : 1
        };
        Reviews.updateReviewStatus(review.getSalonId(), review.getId(), params).then(function (response) {
          if (!response.error) {
            review.getAttributes().status = params.status;
          }
        });
      }
    }

    function searchReview(reload) {
      if (reload) {
        self.page = 1;
      }
      if (self.query && !/^\s*$/.test(self.query)) {
        var params = {
          'page[number]': self.page,
          'page[size]': self.pageSize,
          'sort': self.sort,
          'q': self.query
        };
        self.loadingPage = true;
        Reviews.searchReview(params).then(function (response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listReview = [];
            angular.forEach(response.data, function (value, key) {
              var review = new Review(value, response.included);
              self.listReview.push(review);
            });
          }
          self.loadingPage = false;
        }, function (error) {
          self.loadingPage = false;
        });
      } else {
        init();
      }
    }

    function pageSearchChange() {
      if (self.query && !/^\s*$/.test(self.query)) {
        searchReview();
      } else {
        init();
      }
    }

    function sorting(typeSort) {
      if (typeSort) {
        if (typeSort != self.sort.type) {
          self.sort.type = typeSort;
          self.sort.direction = '';
        }
        if (self.sort.direction == '') {
          self.sort.direction = '-';
        } else if (self.sort.direction == '-') {
          self.sort.direction = '+';
        } else {
          self.sort.direction = '';
          self.sort.type = '';
        }
        init();
      }
    }

    function checkSort(type) {
      if (type) {
        return type == self.sort.type && self.sort.direction != '' ? self.sort.direction == '-' ? 'fa-sort-desc' : 'fa-sort-asc' : 'fa-sort';
      } else {
        return 'fa-sort';
      }
    }
  }

})(angular);