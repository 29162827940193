(function(angular) {
  'use strict';

  angular.module('Swivel').controller('ChangePasswordController', ChangePasswordController);

  ChangePasswordController.$inject = ['$state', 'Auth', 'Users', '$stateParams'];
  function ChangePasswordController($state, Auth, Users, $stateParams) {
    var self = this;
    self.changePasswordSuccess = false;
    self.errorPassword = false;
    self.errorNewPassword = false;
    self.input = {
      password: null,
      new_password: null
    }
    self.changePassword = changePassword;
    function isEmpty(text) {
      if(text === null || text === undefined || text === '') {
        return true;
      } else
        return false;
    }
    function checkValidLimitString(text, numberLimit) {
        var numberLengthOfString = parseInt(text.length);
        var numberLimitChar = parseInt(numberLimit);
        var validNumberChar = numberLengthOfString >= numberLimitChar ? true: false;
        return validNumberChar;
    }
    function changePassword(loginForm) {
        if (loginForm.$valid && self.input.password == self.input.new_password && checkValidLimitString(self.input.password, 6) && checkValidLimitString(self.input.new_password, 6)) {
            self.loading = true;
            var token = $stateParams.code;
            self.errorPassword = false;
            self.errorNewPassword = false;
            var params = {
                new_password: self.input.new_password,
                token: token
            };
            Users.resetPassword(params).then(function(response) {
            if (!response.error) {
                self.input.password = null;
                self.input.new_password = null;
                self.changePasswordSuccess = true;
                self.messageError = "Change password successfull!";
            } 
            self.loading = false;
            }, function(error) {
            self.loading = false;
            self.messageError = 'Cannot change password, please try agian!';
            });
        } else if(isEmpty(self.input.password) ) {
            self.messageError = 'Please fill the form!';
            self.errorPassword = true;
            self.errorNewPassword = false;
        } else if(!checkValidLimitString(self.input.password, 6)) {
            self.messageError = 'The password must be at least 6 characters!';
            self.errorPassword = true;
            self.errorNewPassword = false;
        } else if(isEmpty(self.input.new_password) ) {
            self.messageError = 'Please fill the form!';
            self.errorPassword = false;
            self.errorNewPassword = true;
        } else if(!checkValidLimitString(self.input.new_password, 6)) {
            self.messageError = 'The password must be at least 6 characters!';
            self.errorPassword = false;
            self.errorNewPassword = true;
        } else if(self.input.password != self.input.new_password) {
            self.messageError = 'Confirm password is incorrect!';
            self.errorPassword = false;
            self.errorNewPassword = true;
        } else {
            self.errorPassword = false;
            self.errorNewPassword = false;
        }
    }   
  }

})(angular);
