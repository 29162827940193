(function(angular) {
  'use strict';

  angular.module('Swivel').service('SalonStylists', SalonStylists);

  SalonStylists.$inject = ['$q', '$http', 'serverUrl', 'Auth', 'Users'];
  function SalonStylists($q, $http, serverUrl, Auth, Users) {
    var SalonStylists = function() {
      var self = this;
      self.create = create;
      self.addTimeworkForStylist = addTimeworkForStylist;
      self.deleteStylist = deleteStylist;
      self.updateStylist = updateStylist;
      self.deleteTimeworkForStylist = deleteTimeworkForStylist;
      self.deleteTimeworkForStylistById = deleteTimeworkForStylistById;
      self.updateStylistStatus = updateStylistStatus;
      self.getList = getList;
      self.getStylistTimeworks = getStylistTimeworks;
      self.getTimeworkForStylist = getTimeworkForStylist;
      self.getNextStylistTimework = getNextStylistTimework;
      self.getStylist = getStylist;
      self.arrange = arrange;

      function create(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getTimeworkForStylist(salonID, stylistID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID+'/timeworks',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function addTimeworkForStylist(salonID, stylistID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID+'/timeworks',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteStylist(salonID, stylistID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteTimeworkForStylist(salonID, stylistID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID+'/timeworks',
          method: 'DELETE',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function deleteTimeworkForStylistById(salonID, stylistID, timeworkId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID+'/timeworks/' + timeworkId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function updateStylist(salonID, stylistID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateStylistStatus(salonID, stylistID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/'+stylistID+'/status',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function arrange(salonID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/' + salonID + '/stylists/arrangement',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getList(salonID, params) {
        params = angular.copy(params);
        if (!params.sort) {
          params.sort = 'order';
        }
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID+'/stylists',
          method: 'GET',
          params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getStylist(salonID) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID+'/stylists?sort=name',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getStylistTimeworks(salonID, stylistID, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'salons/'+salonID+'/stylists/'+stylistID+'/timeworks',
          method: 'GET',
          params: params,
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function getNextStylistTimework(next) {
        var deferred = $q.defer();
        var requestParams = {
          url: next,
          method: 'GET'
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (SalonStylists);
  }


})(angular);
