(function(angular) {
	'use strict';

	angular.module('Swivel').factory('Booking', Booking);

	Booking.$inject = ['BaseObject'];
	function Booking(BaseObject) {
		var Booking = function(object, included) {
			var self = angular.extend(this, new BaseObject(object));
			// const STATUS_INIT     = 1;
			// const STATUS_REVIEWED = 4;
			// const STATUS_SUCCESS  = 8;
			// const STATUS_FAIL     = 16;
			// const STATUS_ERROR    = 32;
			// const STATUS_PENDING  = 64;
			// const STATUS_CHARGED  = 128;
			var STATUS = {
				1: 'init',
				4: 'reviewed',
				8: 'success',
				16: 'fail',
				32: 'error',
				64: 'in progress',
				128: 'done',
			};
			var STATUS_COLOR = {
				1: 'color-info',
				4: 'color-primary',
				8: 'color-success',
				16: 'color-warning',
				32: 'color-danger',
				64: 'color-info',
				128: 'color-success',
			};
			self.getAttributes = getAttributes;
			self.getUser = getUser;
			self.getFullName = getFullName;
			self.getPhoneNumber = getPhoneNumber;
			self.getCreatedAt = getCreatedAt;
			self.getPromoCode = getPromoCode;
			self.getId = getId;
			self.getType = getType;
			self.getStatus = getStatus;
			self.getRelationships = getRelationships;
			self.getStyles = getStyles;
			self.getStylesName = getStylesName;
			self.getWorkAt = getWorkAt;
			self.getServicesName = getServicesName;
			self.getServices = getServices;
			self.getStatusName = getStatusName;
			self.getAmount = getAmount;
			self.getClassNameStatus = getClassNameStatus;
			self.getDoneBookingStatus = getDoneBookingStatus;
			self.getCustomerStripId= getCustomerStripId;
			self.nameUserBooking = nameUserBooking;
			self.getUserId = getUserId;
			self.getEmail = getEmail;
			self.getStylistId = getStylistId;
			self.getSalonId = getSalonId;
			self.getSalonName = getSalonName;
			self.getPromocodeValue = getPromocodeValue;
     	// self.getDateCurrent = getDateCurrent;
     	// self.getTimeCurrent = getTimeCurrent;
     	self.getTax = getTax;
     	self.getDateWorkAt = getDateWorkAt;
     	self.getNotes = getNotes;
     	self.getPhotos = getPhotos;
     	self.getTotoPrice = getTotoPrice;
     	self.getTexture = getTexture;
     	self.getLenght = getLenght;
     	self.getListService = getListService;
     	// self.getServiceId = getServiceId;
     	// self.getPromoCode = getPromoCode;
     	self.getTip = getTip;
     	self.listStyleId = [];
     	self.listServiceId = [];
		 self.included = included;
		 
		var tempAttributes = self.getAttributes();
		
		if (tempAttributes.photos && tempAttributes.photos.length) {
			// ensure photos are secure
			tempAttributes.photos = tempAttributes.photos.map(function(p) {
				return p.startsWith('https') ? p : p.replace('http', 'https');
			})

			self.set('attributes', tempAttributes);
		}

     	getListId();
     	function isEmpty(text) {
     		if(text === null || text === undefined || text === '') {
     			return true;
     		} else
     		return false;
     	}
     	function getPromoCode() {
     		var promocode = isEmpty(self.getAttributes().promo_code) ? '' : self.getAttributes().promo_code;
     		return promocode;
     	}
     	function getPromocodeValue() {
     		var promocodeValue = isEmpty(self.getAttributes().promo_price) ? 0 : self.getAttributes().promo_price;
     		return promocodeValue;
     	}
     	function getListId() {
     		if (self.getRelationships()['stylist']) {
     			angular.forEach(self.getRelationships()['stylist'], function(value, key) {
     				self.listStyleId.push(value);
     			})
     		}

     		if (self.getRelationships()['services']) {
     			angular.forEach(self.getRelationships()['services'], function(value, key) {
     				self.listServiceId.push(value.id);
     			})
     		}
     	}

     	function getRelationships() {
     		return self.get('relationships');
     	}

     	function getAttributes() {
     		return self.get('attributes');
     	}

     	function getCreatedAt() {
     		return self.getAttributes().createdAt;
     	}
     	function getWorkAt() {
     		return self.getAttributes().work_at;
     	}
     	function getStatus() {
     		return self.getAttributes().status;
     	}
     	function getStatusName(statusID) {
				if (!statusID) {
					statusID = self.getStatus();
				}

				var status = '';
				for (var s in STATUS) {
					if (statusID & s) {
					  status = STATUS[s];
					}
				}
				return status;
     	}

     	function getClassNameStatus(statusID) {
				if (!statusID) {
					statusID = self.getStatus();
				}

				var color = '';
				for (var s in STATUS_COLOR) {
					if (statusID & s) {
					  color = STATUS_COLOR[s];
					}
				}
				return color;
     	}

     	function getDoneBookingStatus(statusID){
				if (!statusID) {
					statusID = self.getStatus();
				}
				if (statusID & 128) {
					return true;
				}
				if (statusID & 8) {
					return true;
				}
     		switch(statusID) {
     			case '8':
     			return true;
     			case '73':
     			return true;
     			case '136':
     			return true;
     		}
     		return false;
     	}

     	function getId() {
     		return self.getAttributes().id;
     	}
     	function getType() {
     		return self.type;
     	}
     	function getAmount() {
     		var value = parseFloat(self.getAttributes().amount).toFixed(2);
     		return value;
     	}
     	function getCustomerStripId() {
     		return self.getAttributes().stripe_charge_id;
     	}
     	function getEmail() {
     		if (included) {
     			var email = null;
     			angular.forEach(included, function(value, key) {
     				if (value.type == 'User' && self.getRelationships()['user'].id == value.attributes.id) {
     					email = value.attributes.email;
     				}
     			});
     			return email;
     		}
     	}
     	function getStylistId() {
     		if (included) {
     			var stylistId = null;
     			angular.forEach(included, function(value, key) {
     				if (value.type == 'Stylist' && self.getRelationships()['stylist'].id == value.attributes.id) {
     					stylistId = value.attributes.id;
     				}
     			});
     			return stylistId;
     		}
     	}

     	function getSalonId() {
     		return self.getRelationships()['salon'].id;
     	}

      function getSalonName() {
        if (self.included) {
          var salon = self.included.filter(function(item) {
            return item.type === 'Salon' && item.id === self.getSalonId();
          });
          return salon ? salon[0].attributes.name : '';
        }
      }
   //   	self.getDateCurrent = getDateCurrent;
   //   				function getUser() {
			// 	if (included) {
			// 		var name = null;
			// 		angular.forEach(included, function(value, key) {
			// 				if (value.type == 'User' && self.getRelationships()['user'].id == value.attributes.id) {
			// 					name = value.attributes.username;
			// 				}
			// 		});
			// 		return name;
			// 	}
			// }
   //   	self.getTimeCurrent = getTimeCurrent;
   //   				function getUser() {
			// 	if (included) {
			// 		var name = null;
			// 		angular.forEach(included, function(value, key) {
			// 				if (value.type == 'User' && self.getRelationships()['user'].id == value.attributes.id) {
			// 					name = value.attributes.username;
			// 				}
			// 		});
			// 		return name;
			// 	}
			// }
			function getTax() {
				return self.getAttributes().tax;
			}
			function getDateWorkAt() {
				return self.getAttributes().work_at;
			}
			function getNotes() {
				return self.getAttributes().notes;
			}
			function getPhotos() {
				return self.get('attributes').photos;
			}
			function getTexture() {
				return self.get('attributes').texture;
			}
			function getLenght() {
				return self.get('attributes').length;
			}
			function getTip() {
				return self.get('attributes').tip;
			}

			function getPhoneNumber() {
				return self.get('attributes').phone;
			}

			function getUserId() {
				return self.getRelationships()['user'].id;
			}
			function getTotoPrice() {
				return self.get('attributes').amount;
			}
			function getUser() {
				if (included) {
					var name = null;
					angular.forEach(included, function(value, key) {
						if (value.type == 'User' && self.getRelationships()['user'].id == value.attributes.id) {
							name = value.attributes.username;
						}
					});
					return name;
				}
			}
			function getFullName() {
				return self.get('attributes').fullname;
			}
			function getStyles() {
				if (included) {
					var styles = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Stylist' && self.listStyleId.indexOf(value.attributes.id) != -1) {
							styles.push(value.attributes);
						}
					})
					return styles;
				}
			}

			function getStylesName() {
				if (included) {
					var styles = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Stylist' && self.listStyleId.indexOf(value.attributes.id) != -1) {
							styles.push(value.attributes.name);
						}
					})
					return styles;
				}
			}
			function getListService() {
				console.log(included);
				var listService = [];
				if(included) {
					angular.forEach(included, function(value, key) {
						if (value.type == 'BookingService') {
							listService.push(value.attributes);
						}
					});
				}
				return listService;
			}
			function getServices() {
				var totalPrice = parseFloat(self.getTotoPrice());
				if (included) {
					var listService = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'BookingService') {
							listService.push(value.attributes);
						}
					});
					var services = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Service' && self.listServiceId.indexOf(value.attributes.id) != -1) {
							angular.forEach(listService, function(serviceBooking){
								if(serviceBooking.service_id === value.attributes.id) {
									if(parseFloat(serviceBooking.price) === 0) {
										if(totalPrice !== null && parseFloat(totalPrice) > 0) {
											value.attributes.price = value.attributes.price;
										} else {
											value.attributes.price = value.attributes.price;
										}
									} else {
										value.attributes.price = serviceBooking.price;
									}
									services.push(value.attributes);
									return;
								}
							});
						}
					})
					return services;
				}
			}

			function getServicesName() {
				if (included) {
					var services = [];
					angular.forEach(included, function(value, key) {
						if (value.type == 'Service' && self.listServiceId.indexOf(value.attributes.id) != -1) {
							services.push(value.attributes.name);
						}
					})
					return services;
				}
			}

			function nameUserBooking() {
				if (included) {
					var nameOfUserBooking = null;
					angular.forEach(included, function(value, key) {
						if (value.type === 'User' && self.getRelationships()['user'].id === value.attributes.id) {
							nameOfUserBooking = value.attributes.username;
						}
					});
					return nameOfUserBooking;
				}
			}
		}
		return (Booking);
	}
})(angular);
