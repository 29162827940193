(function(angular) {
  'use strict';

  angular.module('Swivel').controller('AuthController', AuthController);

  AuthController.$inject = ['$state', 'Auth', 'Users', '$rootScope'];
  function AuthController($state, Auth, Users, $rootScope) {
    var self = this;
    self.input = {
      email: null,
      password: null
    }
    self.emailForgotPassword = null;
    self.loadingForgotPassword = false;
    self.messageErrorForgotPassword = null;
    self.loading = false;
    self.messageError = null;
    self.signIn = signIn;
    self.forgotPassword = forgotPassword;
    self.hideError = hideError;
    self.salonIdCurrently = null;

    function signIn(loginForm) {
      if (loginForm.$valid) {
        self.loading = true;
        Users.SignIn(self.input).then(function(response) {
          if (!response.error && response.data) {
            Auth.setUser(self.input);
            Auth.setTokenObject(response.data.attributes);
            Auth.setAccessToken(response.data.attributes.accessToken);
            Users.getUserProfile().then(function(response) {
              Auth.setRole(response.data.attributes.roles[0]);
              if(Auth.isAdmin()) {
                $state.go('dashboard.salon');
              } 
              else if(Auth.isOwner()) {
                var listSalon = response.data.attributes.salons;
                var numOfSalon = listSalon.length;
                if(numOfSalon > 0) {
                  $rootScope.salonIdCurrently = listSalon[numOfSalon - 1];
                   $state.go('dashboard.bookings', { 'salonID': $rootScope.salonIdCurrently});
                }
              } 
              else {
                 $state.go('dashboard.setting');
              }
            });
          } else {
            self.messageError = 'Sign in error, please try agian!';
          }
          self.loading = false;
        }, function(error) {
          self.loading = false;
          self.messageError = 'Sign in error, please try agian!';
        });
      } else {
        self.messageError = 'Please fill the form!';
      }
    }

    function forgotPassword(forgotPasswordForm) {
      if (forgotPasswordForm.$valid) {
        self.loadingForgotPassword = true;
        Users.forgotPassword({email: self.emailForgotPassword}).then(function(response) {
            $state.go('signIn');
            self.loadingForgotPassword = false;
        }, function(error) {
          self.loadingForgotPassword = false;
          self.messageErrorForgotPassword = "Email not found!";
        });
      } else {
        self.messageErrorForgotPassword = 'Please fill the form!';
      }
    }

    function hideError() {
      self.messageError = null;
      self.messageErrorForgotPassword = null;
    }
  }

})(angular);
