(function(angular) {
  'use strict';

  angular.module('Swivel').service('Settings', Settings);
  Settings.$inject = ['$q', '$http', 'serverUrl', 'Users', 'Auth'];

  function Settings($q, $http, serverUrl, Users, Auth) {
    var Settings = function() {
      var self = this;
      self.get = get;
      self.save = save;
      self.getListCity = getListCity;
      self.getListNeighborHood = getListNeighborHood;
      self.addNeighborHood = addNeighborHood;
      self.updateDistrict = updateDistrict;
      self.getFilterStatus = getFilterStatus;
      self.updateNeightborHood = updateNeightborHood;
      self.deleteNeightborHood = deleteNeightborHood;
      self.deleteDistrict = deleteDistrict;
      self.changeStatusFilter = changeStatusFilter;
      self.getPromoCodeByName = getPromoCodeByName;
      self.getDistrict = getDistrict;
      self.createDistrict = createDistrict;
      self.updateTimeOpen = updateTimeOpen;
      self.createPromocode = createPromocode;
      self.getTaxPercent = getTaxPercent;
      self.updateTaxPercent = updateTaxPercent;
      self.addCity = addCity;
      self.updateCityStatus = updateCityStatus;
      self.updateStatusCity = updateStatusCity;
      self.listCity = [];
      self.deleteState = deleteState;

      function updateStatusCity(id, data) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities/' + id,
          method: 'PATCH',
          headers: Auth.getHeaders(),
          data: data
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;       
      }
      function  getPromoCodeByName(promoCodeName) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'promoCodes/codes/' + promoCodeName,
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function updateDistrict(districtId, params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'districts/'+ districtId,
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deleteDistrict(districtId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'districts/' + districtId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function createDistrict(cityId, params) {
       var deferred = $q.defer();
          var requestParams = {
            url: serverUrl + 'districts',
            method: 'POST',
            data: params,
            headers: Auth.getHeaders()
          }
          $http(requestParams).success(function(response) {
            deferred.resolve(response);
          }).error(function(error, status) {
            deferred.reject(error);
          });
          return deferred.promise;
        }
        function getDistrict(cityId, params) {
          var deferred = $q.defer();
          var requestParams = {
            url: serverUrl + 'cities/' + cityId + '/districts',
            method: 'GET',
            params: params,
            headers: Auth.getHeaders()
          }
          $http(requestParams).success(function(response) {
            deferred.resolve(response);
          }).error(function(error, status) {
            deferred.reject(error);
          });
          return deferred.promise;
        }
      function updateCityStatus(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities',
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deleteState(stateId) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities/' + stateId,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function addCity(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getTaxPercent() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function updateTaxPercent(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function createPromocode(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'promoCodes',
          method: 'POST',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function addNeighborHood($idCity, $citySeletedIdAdd) {
         var deferred = $q.defer();
         var dataUpdate = {
            'city_id': $idCity,
            'neighborhood_id': $citySeletedIdAdd
         };
        var requestParams = {
          url: serverUrl + 'neighborhoods',
          method: 'POST',
          data: dataUpdate,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function changeStatusFilter($neighborhoods_filter) {
         var deferred = $q.defer();
         var neighborhoods_filter = {
          'neighborhoods_filter': $neighborhoods_filter
         };
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'PATCH',
          data: JSON.stringify(neighborhoods_filter),
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function getFilterStatus() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function deleteNeightborHood(idCity) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'neighborhoods/'+idCity,
          method: 'DELETE',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateNeightborHood(data) {
        var deferred = $q.defer();
        var name = {
          "name" : data.relationships.neighborhood.name
        }
        var requestParams = {
          url: serverUrl + 'cities/'+ data.relationships.neighborhood.id,
          method: 'PATCH',
          data: JSON.stringify(name),
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }


      //get list neighborhood service
      function getListNeighborHood(id) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'neighborhoods?city_id=' + id,
          method: 'GET'
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      // get list city
      function getListCity(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'cities',
          method: 'GET',
          params: params
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
      function get() {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'GET',
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function save(params) {
        var deferred = $q.defer();
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'PATCH',
          data: params,
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }

      function updateTimeOpen(data) {
        var deferred = $q.defer();
        var data = {
          "time_open_early" : data.timeEarly,
          "time_open_late": data.timeLate
        }
        var requestParams = {
          url: serverUrl + 'settings',
          method: 'PATCH',
          data: JSON.stringify(data),
          headers: Auth.getHeaders()
        }
        $http(requestParams).success(function(response) {
          deferred.resolve(response);
        }).error(function(error, status) {
          Users.revokeUser(status);
          deferred.reject(error);
        });
        return deferred.promise;
      }
    }

    return new (Settings);
  }


})(angular);
