(function(angular) {
	'use strict';

	angular.module('Swivel').factory('SalonOwner', SalonOwner);

	SalonOwner.$inject = ['BaseObject'];
	function SalonOwner(BaseObject) {
		var SalonOwner = function(object, included) {
			var self = angular.extend(this, new BaseObject(object));
			self.getAttributes = getAttributes;
			self.getName = getName;
			self.getSalonName = getSalonName;
			self.getCreatedAt = getCreatedAt;
			self.getSalonOwnerId = getSalonOwnerId;
			self.getSalonId = getSalonId;
			self.getId = getId;
			self.getSalonOwnerId = getSalonOwnerId;
			self.getEmail = getEmail;
			self.included = included;
			self.getRelationships = getRelationships;
			self.listStyleId = [];
			self.listHairTypeId = [];
			self.listServiceId = [];
			self.getCityId = null;

			function getSalonOwnerId() {
				return self.getAttributes().id;
			}
			function getAttributes() {
				return self.get('attributes');
			}

			function getCreatedAt() {
				return self.getAttributes().createdAt;
			}

			function getId() {
				return self.getRelationships()['user'].id;
			}
			function getSalonOwnerId() {
				return self.getAttributes().id;
			}

			function getSalonName() {
				if (self.included) {
					var salonName = null;
					angular.forEach(self.included, function(value, key) {
							if (value.type == 'Salon' && self.getRelationships()['salon'].id === value.attributes.id) {
								salonName = value.attributes.name;
							}
					});
					return salonName;
				}
			}
			function getEmail() {
				if (self.included) {
					var email = null;
					angular.forEach(self.included, function(value, key) {
							if (value.type == 'User' && self.getRelationships()['user'].id === value.attributes.id) {
								email = value.attributes.email;
							}
					});
					return email;
				}
			}
			function getSalonId() {
				if (self.included) {
					var salonId = null;
					angular.forEach(self.included, function(value, key) {
							if (value.type == 'Salon' && self.getRelationships()['salon'].id === value.attributes.id) {
								salonId = value.attributes.id;
							}
					});
					return salonId;
				}				
			}
			function getName() {
				if (self.included) {
					var ownerName = null;
					angular.forEach(self.included, function(value, key) {
							if (value.type == 'User' && self.getRelationships()['user'].id === value.attributes.id) {
								ownerName = value.attributes.username;
							}
					});
					return ownerName;
				}
			}
			function getEmail() {
				if (self.included) {
					var ownerEmail = null;
					angular.forEach(self.included, function(value, key) {
							if (value.type == 'User' && self.getRelationships()['user'].id === value.attributes.id) {
								ownerEmail = value.attributes.email;
							}
					});
					return ownerEmail;
				}
			}

			function getRelationships() {
				return self.get('relationships');
			}
		}

		return (SalonOwner);
	}
})(angular);
