(function(angular) {
  'use strict';

  angular.module('Swivel').controller('EditStripeAccountController', EditStripeAccountController);
  EditStripeAccountController.$inject = ['Auth','SalonOwner', 'SalonOwners','Salon', 'Salons', 'Stripe','Upload', '$scope', 'serverUrl','$localStorage', '$state', '$stateParams','$timeout', '$q'];

  function EditStripeAccountController(Auth, SalonOwner, SalonOwners, Salon, Salons, Stripe, Upload, $scope, serverUrl, $localStorage, $state, $stateParams,$timeout, $q) {
    var self = this;
    self.stripeForm = {
      first_name: '',
      type: 'individual',
      last_name: '',
      email: '',
      country: 'US',
      gender: 'female',
      phone_number: '',
      date_of_birth: '',
      city: '',
      state: '',
      postal_code: '',
      street: '',
      ssn_last_4: ''
    };
    self.bankAccountForm = {
      account_number: '',
      country: 'US',
      currency: 'USD',
      routing_number: '',
      account_holder_name: '',
      account_holder_type: 'individual'
    };

    self.salonValue = {
      new_customers_charge_fee: '',
      repeat_customers_charge_fee: ''
    };

    self.listCurrency = [];
    self.listBankAccount = [];
    self.input = {
      photos: []
    };
    self.isCreate = true;
    self.nameImageUploaded = null;
    self.fileUpload = null;
    self.isShowUploadForm = false;
    self.loadingPage = true;
    self.isShowUpdateBankAccount = false;
    self.reg_mail =  /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
    self.reg_number = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
    self.reg_phone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
self.salonId = null;
self.isSubmitForm = false;
self.isSubmitAccountBankForm = false;
self.stripeAccount = null;
self.isCreateStripe = true;
self.updateSalonValue = updateSalonValue;
// self.updateStripeForm = updateStripeForm;
self.checkValidateEmail = checkValidateEmail;
self.checkValidateNumber = checkValidateNumber;
// self.updateBankAccount = updateBankAccount;
// self.deleteBankAccount = deleteBankAccount;
self.updateBankAccountStatus =updateBankAccountStatus;
self.uploadFile = uploadFile;
self.isEmpty = isEmpty;
$scope.forms = {};

init();
function init() {
  self.salonId  = parseInt($stateParams.salonID);
  getStripeInfo(self.salonId, function(){
    datePicker();
  });

}

function updateSalonValue(){

  if (self.salonValue.new_customers_charge_fee === undefined || self.salonValue.repeat_customers_charge_fee === undefined ||
    self.salonValue.new_customers_charge_fee <= 0  || self.salonValue.new_customers_charge_fee > 100 ||
    self.salonValue.repeat_customers_charge_fee <= 0  || self.salonValue.repeat_customers_charge_fee > 100) {

    alert("The value of percentage is invalid.")

} else {
  self.loadingPage = true;
  Salons.updateSalonValue(self.salonId, self.salonValue).then(function(response) {
    self.loadingPage = false;
    if(!response.error && response.data) {



    }
  },
  function(error) {
    self.loadingPage = false;
    if(!isEmpty(error)) {
      var messageError = error.errors.length > 0 ? error.errors[0].detail: error.errors.detail;
      alert(messageError);
    } else {
      alert("Cannot update Salon fee!");
    }
  });

}

}

function resetBankAccount() {
  self.bankAccountForm = {
    account_number: '',
    country: 'US',
    currency: 'USD',
    routing_number: '',
    account_holder_name: '',
    account_holder_type: 'individual'
  };
}
// function deleteBankAccount(bankId) {
//   var r = confirm("Are you sure you want to delete account!");
//   if (r == true) {
//     self.loadingPage = true;
//     var salonStripeId = self.stripeAccount.getId();
//     Salons.deleteBankAccount(salonStripeId, bankId).then(function(response) {
//       if(!response.error && response.data) {
//         self.loadingPage = false;
//         self.stripeAccount = new Stripe(response.data, response.included);
//         getBankAccount(self.stripeAccount);
//       }
//     },
//     function(error) {
//       self.loadingPage = false;
//       if(!isEmpty(error)) {
//         var messageError = error.errors.length > 0 ? error.errors[0].detail: error.errors.detail;
//         alert(messageError);
//       } else {
//         alert("Cannot delete Bank Acount!");
//       }
//     });
//   } else {

//   }
// }
function updateBankAccountStatus(item, $index) {

  self.loadingUpdateBankAccount = true;
  self.indexLoading = $index;
  var status = item.default_for_currency == true ?  false: true;
  var data = {
    default_for_currency: status
  };
  var salonStripeId = self.stripeAccount.getId();
  Salons.updateBankAccount(salonStripeId, item.id, data).then(function(response) {
    self.loadingUpdateBankAccount = false;
    self.stripeAccount = new Stripe(response.data, response.included);
    getBankAccount(self.stripeAccount);
  }, function(error) {
   self.loadingUpdateBankAccount = false;
 });
}
    //check validate format email
    function checkValidateEmail(email) {
      if(self.reg_mail.test(email)) {
        return true;
      } else {
        return false;
      }
    }
    // check validate format email .end

    //check validate format number
    function checkValidateNumber(number) {
      if(self.reg_number.test(number)) {
        return true;
      } else {
        return false;
      }
    }
    // check validate format number .end

    function datePicker() {
      var dateObject = new Date();
      $timeout(function() {
        $('#dobDatepicker').datepicker({
          dateFormat: 'mm-dd-yy',
          endDate: dateObject,
          autoclose: true,
        });
      }, 100);
    }
    function getImageInfo(salonStripeId, cb) {
      Salons.getImageUploadInfo(salonStripeId).then(function(response) {
        if(!response.error && response.data) {
         self.isShowUploadForm = true;
         if(!isEmpty(cb) && typeof(cb) == 'function') {
           cb();
         }
       }
     });
    }
    function visibleForm(elementFormSeting, _attribute) {
      $timeout(function() {
        elementFormSeting = _attribute;
        console.log(elementFormSeting);
      }, 100);
    }

    self.salonValue = {
      new_customers_charge_fee: '',
      repeat_customers_charge_fee: ''
    };

    function getStripeInfo(salonId, cb) {
      Salons.getStripeAccountInfo(salonId).then(function(response) {
        if(!response.error && response.data) {
          self.isCreateStripe = false;

          self.salonValue.new_customers_charge_fee = response.data.attributes.new_customers_charge_fee;
          self.salonValue.repeat_customers_charge_fee = response.data.attributes.repeat_customers_charge_fee;

          var stripe = new Stripe(response.data, response.included);
          self.stripeAccount = stripe;
          // self.stripeForm.first_name = stripe.getFirstName();
          // self.stripeForm.last_name = stripe.getLastName();
          // self.stripeForm.email = stripe.getEmail();
          // self.stripeForm.country = stripe.getCountry();
          // self.stripeForm.gender = stripe.getGender();
          // self.stripeForm.phone_number = stripe.getPhone();
          // self.stripeForm.date_of_birth = stripe.getDateOfBirth().month + '/' + stripe.getDateOfBirth().day + '/' + stripe.getDateOfBirth().year;
          // self.stripeForm.city = stripe.getCity();
          // self.stripeForm.state = stripe.getState();
          // self.stripeForm.postal_code = stripe.getPostalNumber();
          // self.stripeForm.street = stripe.getStreet();
          // self.stripeForm.ssn_last_4 = '';
          self.listCurrency = stripe.getCurrency();
          self.loadingPage = false;
          getBankAccount(self.stripeAccount);
         // console.log(self.stripeForm);
         if(!isEmpty(cb) && typeof(cb) == 'function') {
          cb();
        }
      } else {
        if(!isEmpty(cb) && typeof(cb) == 'function') {
          cb();
        }
      }
    }, function(error) {
      self.loadingPage = false;
      if(!isEmpty(cb) && typeof(cb) == 'function') {
        cb();
      }
    });
}

      // function updateStripeForm(stripeForm) {
      //   //console.log(self.isSubmitForm);
      //   self.isSubmitForm = true;
      //   var deferred = $q.defer();
      //   if(stripeForm.$valid) {
      //     self.loadingPage = true;
      //     var stripeInfor = {
      //       email: self.stripeForm.email,
      //       country: self.stripeForm.country,
      //       legal_entity: {
      //         type: self.stripeForm.type,
      //         first_name: self.stripeForm.first_name,
      //         dob: {
      //           "day": parseInt(getDate(self.stripeForm.date_of_birth)),
      //           "month": parseInt(getMonth(self.stripeForm.date_of_birth)),
      //           "year": parseInt(getYear(self.stripeForm.date_of_birth))
      //         },
      //         last_name: self.stripeForm.last_name,
      //         phone_number: self.stripeForm.phone_number,
      //         address: {
      //           city: self.stripeForm.city,
      //           state: self.stripeForm.state,
      //           postal_code: self.stripeForm.postal_code,
      //           line1: self.stripeForm.street
      //         }
      //       }
      //     };
      //     if(self.isCreateStripe) {
      //       stripeInfor.legal_entity.ssn_last_4 =  self.stripeForm.ssn_last_4;
      //     }
      //     //charlie
      //     Salons.createStripAccount(self.salonId, stripeInfor).then(function(response) {
      //       if(!response.error && response.data) {
      //         self.uploading = false;
      //         self.loadingPage = false;
      //         self.isCreateStripe = false;
      //         self.isSubmitForm = false;
      //         var stripe = new Stripe(response.data, response.included);
      //         self.stripeAccount = stripe;
      //         getBankAccount(self.stripeAccount);
      //         self.listCurrency = stripe.getCurrency();
      //         deferred.resolve(true);
      //       }
      //     },
      //     function(error) {
      //       self.uploading = false;
      //       self.loadingPage = false;
      //       if(!isEmpty(error)) {
      //         var messageError = error.errors.length > 0 ? error.errors[0].detail: error.errors.detail;
      //         alert(messageError);
      //       } else {
      //         alert("Cannot create Stripe Acount!");
      //       }
      //       deferred.reject(error);
      //     });
      //     return deferred.promise;
      //   }
      // }
      // function updateBankAccount(bankAccountForm) {
      //   self.isSubmitAccountBankForm = true;
      //   var hasUpdateStripe = $scope.forms.updateStripeForm && $scope.forms.updateStripeForm.$valid;
      //   var doUpdate = function () {
      //     self.loadingPage = true;
      //     var stripeId = self.stripeAccount.getId();
      //     Salons.createBankInfo(stripeId, self.bankAccountForm).then(function(response) {
      //       self.loadingPage = false;
      //       self.isSubmitAccountBankForm = false;
      //       self.isSubmitForm = false;
      //       if(!response.error && response.data) {
      //         resetBankAccount();
      //         var stripe = new Stripe(response.data, response.included);
      //         self.stripeAccount = stripe;
      //         getBankAccount(self.stripeAccount);
      //         self.listCurrency = stripe.getCurrency();
      //       }
      //     },
      //     function(error) {
      //       self.loadingPage = false;
      //       self.isSubmitAccountBankForm = false;
      //       self.isSubmitForm = false;
      //       if(!isEmpty(error)) {
      //         var messageError = error.errors.length > 0 ? error.errors[0].detail: error.errors.detail;
      //         alert(messageError);
      //       } else {
      //         alert("Cannot create Bank Acount!");
      //       }
      //     });
      //   }
      //   if ($scope.forms.updateStripeForm) {
      //     self.isSubmitForm = true;
      //   }
      //   if(hasUpdateStripe && bankAccountForm.$valid) {
      //     updateStripeForm($scope.forms.updateStripeForm).then(function (ok) {
      //       doUpdate()
      //     })
      //   } else if(bankAccountForm.$valid) {
      //     doUpdate()
      //   }
      // }
      // function getDate(date) {
      //   if(!isEmpty(date)) {
      //     var arrayDate = date.split('/');
      //     return arrayDate[1];
      //   } else {
      //     return null;
      //   }
      // }
      // function getMonth(date) {
      //   if(!isEmpty(date)) {
      //     var arrayDate = date.split('/');
      //     return arrayDate[0];
      //   } else {
      //     return null;
      //   }
      // }
      // function getYear(date) {
      //   if(!isEmpty(date)) {
      //     var arrayDate = date.split('/');
      //     return arrayDate[2];
      //   } else {
      //     return null;
      //   }
      // }
      function uploadFile(file) {
        if (file) {
          if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) == -1) {
            alert('File format is not valid!');
            return;
          } else if (file.size > 10*1024*1024) {
            alert('File size is too large\nFile size limit 10M.');
            return;
          } else {
            self.uploading = true;
            self.fileUpload = file;
            self.loadingPage = true;
            var data = {
              file: file
            }
            Salons.createStripImage(self.stripeAccount.getId(), data).then(function(response) {
              if(!response.error && response.data) {
                self.uploading = false;
                self.loadingPage = false;
                self.nameImageUploaded = file.name;
              } else {
                self.uploading = false;
                self.loadingPage = false;
                alert("Can not upload photo!");
              }
            },
            function(error) {
              self.uploading = false;
              self.loadingPage = false;
            });
          }
        }
      }
      function getBankAccount(stripeAccount) {
        self.listBankAccount = stripeAccount.getListBankAcount();
      }
      function isEmpty(text) {
        if(text === null || text === undefined || text === '') {
          return true;
        } else
        return false;
      }
    }
  })(angular);