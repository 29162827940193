(function (angular) {
	'use strict';

	angular.module('Swivel').factory('Review', Review);

	Review.$inject = ['BaseObject'];

	function Review(BaseObject) {
		var Review = function (object, included) {
			var self = angular.extend(this, new BaseObject(object));

			self.getId = getId;
			self.getAttributes = getAttributes;
			self.getUserId = getUserId;
			self.getStylistId = getStylistId;
			self.getSalonId = getSalonId;
			self.getSalonRating = getSalonRating;
			self.getHairRating = getHairRating;
			self.getWaitTime = getWaitTime;
			self.getCreatedAt = getCreatedAt;
			self.getPhotos = getPhotos;
			self.getStatus = getStatus;
			self.getFirstPhoto = getFirstPhoto;
			self.getComment = getComment;
			self.getUserName = getUserName;
			self.getSalonName = getSalonName;
			self.getIncludeItem = getIncludeItem;
			self.getRelationships = getRelationships;
			self.getOverallRating = getOverallRating;
			self.getInclude = getInclude;
			self.isHasReports = isHasReports;
			self.getFullName = getFullName;

			var tempAttributes = self.getAttributes();
			
			if (tempAttributes.photos && tempAttributes.photos.length) {
				// ensure photos are secure
				tempAttributes.photos = tempAttributes.photos.map(function(p) {
					return p.startsWith('https') ? p : p.replace('http', 'https');
				})

				self.set('attributes', tempAttributes);
			}

			function getAttributes() {
				return self.get('attributes');
			}

			function getRelationships() {
				return self.get('relationships');
			}

			function getId() {
				return self.getAttributes().id;
			}

			function getSalonId() {
				var salon = getRelationships()['salon'];
				if (salon) {
					return salon.id;
				}
			}

			function getUserId() {
				var user = getRelationships()['user'];
				if (user) {
					return user.id;
				}
			}

			function getStylistId() {
				return self.getAttributes().stylist_id;
			}

			function getSalonRating() {
				return self.getAttributes().salon_rating;
			}

			function getHairRating() {
				return self.getAttributes().hair_rating;
			}

			function getWaitTime() {
				return self.getAttributes().wait_time;
			}

			function getComment() {
				return self.getAttributes().comment;
			}

			function getCreatedAt() {
				return self.getAttributes().createdAt;
			}

			function getPhotos() {
				return self.getAttributes().photos;
			}

			function getStatus() {
				return self.getAttributes().status;
			}

			function getFirstPhoto() {
				var url = "";
				if (getPhotos() && getPhotos().length > 0) {
					url = self.getPhotos()[0];
				}
				return url;
			}

			function getSalonName() {
				var salon = getIncludeItem("Salon", self.getSalonId());
				return salon.name;
			}

			function getUserName() {
				var user = self.getIncludeItem("User", self.getUserId());
				return user.username;
			}

			function getFullName() {
				return self.getAttributes().full_name;
			}

			function getOverallRating() {
				var rating = Math.round((Number(self.getHairRating()) + Number(self.getSalonRating())) / 2);
				return rating;
			}

			function getIncludeItem(type, idItem) {
				var obj = {};
				if (included && type && idItem) {
					angular.forEach(included, function (value, key) {
						if (value.type == type && value.id == idItem) {
							obj = value.attributes;
						}
					});
				}
				return obj;
			}

			function getInclude() {
				return included;
			}

			function isHasReports() {
				return self.getAttributes().hasReports;
			}
		};

		return (Review);
	}
})(angular);