(function(angular) {
  'use strict';

  angular.module('Swivel').controller('ListSalonOwnerController', ListSalonOwnerController);
  ListSalonOwnerController.$inject = ['SalonOwner', 'SalonOwners','Salon', 'Salons','$localStorage', '$state'];

  function ListSalonOwnerController(SalonOwner, SalonOwners, Salon, Salons, $localStorage, $state) {
    var self = this;
    self.listSalonOwners = [];
    self.listSalon = [];
    self.loadingPage = false;
    self.page = 1;
    self.totalObject = 0;
    self.pageSize = 10;
    self.pageSizeListSalon = 150;
    self.query = '';
    self.pageSearchChange = pageSearchChange;
    self.messageError = null;
    self.OpenFormAddNewOwner = OpenFormAddNewOwner;
    self.CreateAndInviteSalonOwnerFormAddNewOwner = CreateAndInviteSalonOwnerFormAddNewOwner;
    self.HiddenFormAddNewOwner = HiddenFormAddNewOwner;
    self.searchSalonOwner = searchSalonOwner;
    self.checkSalonOwnerExisted = checkSalonOwnerExisted;
    self.getListSalon = getListSalon;
    self.hideError = hideError;
    self.goToDetail = goToDetail;
    self.deleteSalonOwner = deleteSalonOwner;
    self.isDisableInputUserName = false;
    self.input = {
      username : '',
      email : '',
      salon_id : -1
    };
    self.isOpenFormAddNewOwner = false;
    self.ErrorMessageCreateOwner = "Owner has existed or system has probleme when saving!";
    self.CreateOwnerSucces = "Create salon owner successful!";
    self.reg_mail =  /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

    init();

    function init() {
      getListSalonOwner();
    }
    //check validate format email 
    function checkValidateEmail(email) {
      if(self.reg_mail.test(email)) {
        return true;
      } else {
        return false;
      }
    }
    // check validate format email .end

    // check salon owner has existed
    function checkSalonOwnerExisted() {
      if((self.input.email !== '' && self.input.email !== undefined) && checkValidateEmail(self.input.email)) {
        SalonOwners.checkOwnerExist(self.input.email).then(function(response) {
        if (!response.error && response.data) {
          if(response.data.length > 0) {
            var salonOwnerExist = response.data[0];
            self.input.username = salonOwnerExist.attributes.username;
            self.isDisableInputUserName = true;
          } else {
            self.isDisableInputUserName = false;
          }
        }
      }, function(error) {
      });  
        //console.log(self.input.email);
      }
    }
    // check salon owner has existed .end

    // get list salon owner 
    function getListSalonOwner() {
      var params = {
        'page[number]': self.page,
        'page[size]': self.pageSize
      }
      self.loadingPage = true;
      self.listSalonOwners = [];
      SalonOwners.getList(params).then(function(response) {
        if (!response.error && response.data) {
          self.totalObject = response.meta.totalObjects;
          // self.listSalonOwner = response.data;
          angular.forEach(response.data, function(value, key) {
            var salonOwner = new SalonOwner(value, response.included);
            self.listSalonOwners.push(salonOwner);
          }); 
          // angular.forEach(response.data, function(value, key) {
          //   var salonOwner = new SalonOwner(value);
          //   self.listSalonOwner.push(salonOwner);
          // })
         // console.log(self.listSalonOwners);
        }
        self.loadingPage = false;
      }, function(error) {
        self.loadingPage = false;
      });     
    }
  // get list salon owner .end 
 
    function getListSalon() {
      self.listSalon = [];
      var params = {
        'page[number]': self.page,
        'page[size]': 200
      };
      Salons.getList(params).then(function(response) {
        if (!response.error && response.data) {
          Salons.drainList(response, params, []).then(function(allSalons) {
            angular.forEach(allSalons, function(value, key) {
              var salon = new Salon(value);
              self.listSalon.push(salon);
            });
          })
        }
      }); 
    }
    function searchSalonOwner(reload) {
      if(reload) {
        self.page = 1;
      }
      if(self.query && !/^\s*$/.test(self.query)) {
        var params = {
          'user.username': '%' + self.query +'%',
          'page[number]': self.page,
          'page[size]': self.pageSize
        }
        self.loadingPage = true;
        SalonOwners.search(params).then(function(response) {
          if (!response.error && response.data) {
            self.totalObject = response.meta.totalObjects;
            self.listSalonOwners = [];
            angular.forEach(response.data, function(value, key) {
              var salonOwner = new SalonOwner(value, response.included);
              self.listSalonOwners.push(salonOwner);
            });
          }
          self.loadingPage = false;
        }, function(error) {
          self.loadingPage = false;
        });
      } else {
        init();
      }
    }

    function OpenFormAddNewOwner() {
      getListSalon();
      self.isOpenFormAddNewOwner = true;
    }
    function refreshForm() {
      self.input.username = self.input.email = null;
      self.input.salon_id = -1;
    }
    function CreateAndInviteSalonOwnerFormAddNewOwner() {
      self.isOpenFormAddNewOwner = false;
      if(self.input != null) {
        self.loadingPage = true;
        SalonOwners.create(self.input).then(function(response) {
          if (!response.error && response.data) {
            alert(self.CreateOwnerSucces);
            getListSalonOwner();
            refreshForm();
          }
          self.loadingPage = false;
          refreshForm();
        }, function(error) {
          self.loadingPage = false;
          self.isOpenFormAddNewOwner = true;
          alert(error.errors[0].detail);
        });
      }
    }
    function HiddenFormAddNewOwner() {
      self.isOpenFormAddNewOwner = false;
    }
    function hideError() {
      self.messageError = null;
    } 

    // page search change
    function pageSearchChange() {
      if(self.query && !/^\s*$/.test(self.query)) {
        searchSalonOwner();
      } else {
        init();
      }
    }
    // page search change

    // delete salon  owner 
    function deleteSalonOwner(item, index) {
      if (self.listSalonOwners[index]) {
        if (confirm('Are you sure delete this item?')) {
          SalonOwners.deleteSalonOwner(item.getSalonOwnerId()).then(function(response) {
            if (!response.error) {
              pageSearchChange()
            }
          })
        }
      }
    }
    // delete salon owner .end
    function goToDetail(id) {
      $localStorage.currentPage = self.page;
      $localStorage.searchKeyword = self.query;
      $state.go('dashboard.edit-salon-owners', {ownerId: id});
    }
  }


})(angular);
