(function(angular) {
  'use strict';

  angular.module('Swivel').factory('SalonCity', SalonCity);

  SalonCity.$inject = ['BaseObject'];
  function SalonCity(BaseObject) {
    var SalonCity = function(object) {
      var self = angular.extend(this, new BaseObject(object));
      self.getAttributes = getAttributes;
      self.getName = getName;
      self.getId = getId;
      self.getStatus = getStatus;

      function getAttributes() {
        return self.get('attributes');
      }

      function getName() {
        return self.getAttributes().name;
      }

      function getId() {
        return self.getAttributes().id;
      }

      function getStatus() {
        return self.getAttributes().status;
      }
    }

    return (SalonCity)
  }

})(angular);
