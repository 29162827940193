(function(angular) {
  'use strict';

  angular.module('Swivel').controller('MainController', MainController);

  MainController.$inject = ['$state', 'Auth'];
  function MainController($state, Auth) {
    var self = this;
    self.setBodyClass = setBodyClass;

    function setBodyClass() {
      switch ($state.current.name) {
        case 'signIn':
        case 'forgotPassword':
          return 'login-page';
        case 'dashboard':
        case 'dashboard.salon':
        case 'dashboard.create-salon':
        case 'dashboard.edit-salon':
        case 'dashboard.review':
        case 'dashboard.user':
        case 'dashboard.user-bookings':
        case 'dashboard.setting':
        case 'dashboard.owners':
        case 'dashboard.bookings':
        case 'dashboard.edit-salon-owners':
        case 'dashboard.edit-booking':
        case 'dashboard.owner-setting':
        case 'dashboard.past-booking':
        case 'dashboard.payment':
        case 'dashboard.create-booking':
        case 'dashboard.confirm-booking':
        case 'dashboard.update-password':
        case 'dashboard.edit-stripe-account':
          return 'skin-blue sidebar-mini';
        default:
          return '';
      }
    }
  }
})(angular);
