(function(angular) {
  'use strict';

  angular.module('Swivel').config(ConfigRouter);

  ConfigRouter.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];
  function ConfigRouter($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode(false).hashPrefix('!');
    $urlRouterProvider.otherwise('/signIn');
    //$urlRouterProvider.otherwise('/setting');

    $stateProvider
      .state('signIn', {
        url: '/signIn',
        templateUrl: 'templates/signIn.html',
        controller: 'AuthController',
        controllerAs: 'AuthCtrl',
        resolve: {
          isAuthenticated: function(Auth, $location) {
            if (Auth.isAuthenticated() && Auth.isAdmin()) {
              $location.path('/salon');
            }
          }
        }
      })
      .state('request-password', {
        url: '/request-password?code',
        templateUrl: 'templates/changePassword.html',
        controller: 'ChangePasswordController',
        controllerAs: 'ChangePasswordCtrl'
      })
      .state('forgotPassword', {
        url: '/forgotPassword',
        templateUrl: 'templates/forgotpassword.html',
        controller: 'AuthController',
        controllerAs: 'AuthCtrl',
        resolve: {
          isAuthenticated: function(Auth, $location) {
            if (Auth.isAuthenticated() && Auth.isAdmin()) {
              $location.path('/salon');
            }
          }
        }
      })
      .state('dashboard', {
        url: '/',
        abstract: true,
        templateUrl: 'templates/dashboard.html',
        controller: 'DashboardController',
        controllerAs: 'DashboardCtrl',
        resolve: {
          isAuthenticated: function(Auth, $location) {
            if (!Auth.isAuthenticated()) {
              $location.path('/signIn');
            }
            if (Auth.revokeUserTokenExpire()) {
              $location.path('/signIn');
            }
          }
        }
      })
      .state('dashboard.update-password', {
        url: 'update-password',
        views: {
          'dashboard': {
            templateUrl: 'templates/updatePassword.html',
            controller: 'UpdatePasswordController',
            controllerAs: 'UpdatePasswordCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.salon', {
        url: 'salon',
        views: {
          'dashboard': {
            templateUrl: 'templates/listSalon.html',
            controller: 'SalonController',
            controllerAs: 'SalonCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.edit-booking', {
        url: 'edit-booking/:bookingID',
        views: {
          'dashboard': {
            templateUrl: 'templates/editBooking.html',
            controller: 'EditBookingController',
            controllerAs: 'EditBookingCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.past-booking', {
        url: 'past-booking',
        views: {
          'dashboard': {
            templateUrl: 'templates/listPastBooking.html',
            controller: 'ListPastBookingController',
            controllerAs: 'ListPastBookingCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.edit-stripe-account', {
        url: 'salon/:salonID/edit-stripe-account',
        views: {
          'dashboard': {
            templateUrl: 'templates/editStripeAccount.html',
            controller: 'EditStripeAccountController',
            controllerAs: 'EditStripeAccountCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.bookings', {
        url: 'salon/:salonID/bookings',
        views: {
          'dashboard': {
            templateUrl: 'templates/listBooking.html',
            controller: 'ListBookingController',
            controllerAs: 'ListBookingCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.payment', {
        url: 'payment/:bookingID',
        views: {
          'dashboard': {
            templateUrl: 'templates/payment.html',
            controller: 'PaymentController',
            controllerAs: 'PaymentCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.create-salon', {
        url: 'create-salon',
        views: {
          'dashboard': {
            templateUrl: 'templates/createSalon.html',
            controller: 'CreateSalonController',
            controllerAs: 'CreateSalonCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.edit-salon', {
        url: 'edit-salon/:salonID',
        views: {
          'dashboard': {
            templateUrl: 'templates/editSalon.html',
            controller: 'EditSalonController',
            controllerAs: 'EditSalonCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.review', {
        url: 'review',
        views: {
          'dashboard': {
            templateUrl: 'templates/listReview.html',
            controller: 'ReviewController',
            controllerAs: 'ReviewCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.user', {
        url: 'user',
        views: {
          'dashboard': {
            templateUrl: 'templates/listUser.html',
            controller: 'ListUserController',
            controllerAs: 'ListUserCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.owners', {
        url: 'owners',
        views: {
          'dashboard': {
            templateUrl: 'templates/listSalonOwner.html',
            controller: 'ListSalonOwnerController',
            controllerAs: 'ListSalonOwnerCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.edit-salon-owners', {
        url: 'edit-salon-owners/:ownerId',
        views: {
          'dashboard': {
            templateUrl: 'templates/editSalonOwner.html',
            controller: 'EditSalonOwnerController',
            controllerAs: 'EditSalonOwnerCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.setting', {
        url: 'setting',
        views: {
          'dashboard': {
            templateUrl: 'templates/settings.html',
            controller: 'SettingController',
            controllerAs: 'SettingCtrl',
            resolve: {
            isAdmin: function(Auth, $location) {
              if (!Auth.isAdmin()) {
                $location.path('/signIn');
              }
              if (Auth.revokeUserTokenExpire()) {
                $location.path('/signIn');
              }
            }
          }
          }
        }
      })
      .state('dashboard.owner-setting', {
        url: 'owner-setting',
        views: {
          'dashboard': {
            templateUrl: 'templates/owner-settings.html',
            controller: 'OwnerSettingController',
            controllerAs: 'OwnerSettingCtrl',
            resolve: {
            isOwner: function(Auth, $location) {
              if (!Auth.isOwner()) {
                $location.path('/signIn');
              }
              if (Auth.revokeUserTokenExpire()) {
                $location.path('/signIn');
              }
            }
          }
          }
        }
      })
      .state('dashboard.create-booking', {
        url: 'create-booking',
        views: {
          'dashboard': {
            templateUrl: 'templates/create-booking.html',
            controller: 'BookingController',
            controllerAs: 'BookingCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.user-bookings', {
        url: 'user/:userId/bookings',
        views: {
          'dashboard': {
            templateUrl: 'templates/listUserBooking.html',
            controller: 'ListUserBookingController',
            controllerAs: 'ListUserBookingCtrl',
            resolve: {
              isAuthenticated: function(Auth, $location) {
                if (!Auth.isAuthenticated()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      })
      .state('dashboard.confirm-booking', {
        url: 'confirm-booking',
        views: {
          'dashboard': {
            templateUrl: 'templates/confirmBooking.html',
            controller: 'BookingController',
            controllerAs: 'BookingCtrl',
            resolve: {
              isAdmin: function(Auth, $location) {
                if (!Auth.isAdmin()) {
                  $location.path('/signIn');
                }
                if (Auth.revokeUserTokenExpire()) {
                  $location.path('/signIn');
                }
              }
            }
          }
        }
      });
  }
})(angular);
