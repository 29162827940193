(function(angular) {
  'use strict';

  angular.module('Swivel').controller('EditSalonOwnerController', EditSalonOwnerController);
  EditSalonOwnerController.$inject = ['SalonOwner', 'SalonOwners','Salon', 'Salons','$localStorage', '$state', '$stateParams'];

  function EditSalonOwnerController(SalonOwner, SalonOwners, Salon, Salons, $localStorage, $state, $stateParams) {
    var self = this;
    self.listSalon = [];
    self.goToSalonOwnerPage = goToSalonOwnerPage;
    self.getSalonOwner = getSalonOwner;
    self.validateSalonName = validateSalonName;
    self.isShowMessageError = false;
    self.updateSalonOwner = updateSalonOwner;
    self.salonOwnerCurrentId = null;
    self.salonOwnerEmail = null;
    self.page = 1;
    self.pageSize = 150;
    self.owner = {
      username : '',
      salon_id : -1
    };
    self.ErrorMessageCreateOwner = "Owner has existed or system has probleme when saving!";
    self.CreateOwnerSucces = "Update salon owner successful!";

    init();

    function init() {
      getSalonOwner();
      getListSalon();
    }

    //update salon owner 
    function updateSalonOwner() {
      self.loadingPage = true;
      if(self.salonOwnerCurrentId != null) {
        if(self.owner.username !== "" && self.owner.username !== undefined) {
          self.isShowMessageError = false;
          SalonOwners.updateSalonOwner(self.salonOwnerCurrentId, self.owner).then(function(response) {
            if (!response.error && response.data) {
              goToSalonOwnerPage();
            }
          },function(error) {
            alert(self.ErrorMessageCreateOwner);
            self.isShowMessageError = false;
          });  
        }  else {
          self.isShowMessageError = true;
        } 
      }  
    }
    //update salon owner .end 

    // validate model salon name 
    function validateSalonName() {
      if(self.username === '' || self.username === undefined) {
        self.isShowMessageError = true;
      }
    }
    // validate model salon name .end
    // get list salon owner 
    function getSalonOwner() {
      self.salonOwnerCurrentId = $stateParams.ownerId;
      self.loadingPage = true;
      SalonOwners.getSalonOwner(self.salonOwnerCurrentId ).then(function(response) {
        if (!response.error && response.data) {
          var salonOwner = new SalonOwner(response.data, response.included);
          self.owner.username = salonOwner.getName();
          self.owner.salon_id = salonOwner.getSalonId();
          self.salonOwnerEmail = salonOwner.getEmail();
          console.log(self.owner.salon_id);
        }
        self.loadingPage = false;
      });    
    }
  // get list salon owner .end 
 
    function getListSalon() {
      self.listSalon = [];
      var params = {
        'page[number]': self.page,
        'page[size]': 200
      };

      Salons.getList(params).then(function(response) {
        if (!response.error && response.data) {

          Salons.drainList(response, params, []).then(function(allSalons) {
            angular.forEach(allSalons, function(value, key) {
              var salon = new Salon(value);
              self.listSalon.push(salon);
            });
          }, function(error) {
            alert('Cannot delete salon owner!');
          })

        }
      }, function(error) {
          alert('Cannot delete salon owner!');
        }); 
    }
    function goToSalonOwnerPage() {
      $localStorage.currentPage = self.page;
      $localStorage.searchKeyword = self.query;
      $state.go('dashboard.owners');
    }
  }


})(angular);
